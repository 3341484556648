import PropTypes from "prop-types";
import * as React from "react";
import {InlineEditorControls} from "./InlineEditorControls";
import {FullSizeEditorControls} from "./FullSizeEditorControls"

export class EditorControls extends React.Component{

    static contextTypes = {
        editor: PropTypes.any,
    };

    static defaultProps = {
        type: "inline",
        showAlways: false,
    }

    static propTypes = {
        type: PropTypes.string,
        showAlways: PropTypes.bool,
    }

    constructor(props, context) {
        super(props, context);
        this.state = {
            changed:false,
            error:null,
            valid:true
        };

        this.setValid = this.setValid.bind(this);
        this.setChanged = this.setChanged.bind(this);
        this.setError = this.setError.bind(this);
    }

    componentDidMount(){
        this.context.editor.registerControls(this);
    }

    componentWillUnmount(){
        this.context.editor.unRegisterControls(this);
    }

    setValid(valid){
        this.setState({valid});
    }
    setChanged(changed){
        this.setState({changed});
    }
    setError(error){
        this.setState({error});
    }

    render(){
        let Component = this.props.type === "full" ? FullSizeEditorControls : InlineEditorControls
        return <Component showAlways={this.props.showAlways} save={this.context.editor.save} revert={this.context.editor.revert} changed={this.state.changed} error={this.state.error} valid={this.state.valid} />
    }
}