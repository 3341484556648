import React from "react";

export const UnicodeButton =
    ({value, onClick, disabled}) =>
        <span
            role="form"
            aria-disabled={disabled}
            style={{width:22,height:19}}
            className={disabled?"cursor-not-allowed":"cursor-pointer"}
            onClick={onClick}>
            {value}
        </span>;