import * as React from "react"
import PropTypes from "prop-types"
import {LinkButton, StatusButtonField} from "../components"
import {
    Kontakt_wird_Newsletterempfang_zustimmen, Kontakt_wird_Zustimmung_zum_Newsletterempfang_widerrufen,
    Kursbuchung_Teilnahme_soll_bestaetigt_werden,
} from "../Daten/CheckinMessages.gen"
import {gql} from "apollo-boost/lib/index";

export class NewsletterSetter extends React.Component {
    static contextTypes = {
        client: PropTypes.any, // Apollo Client
    };

    static propTypes = {
        newsletter: PropTypes.bool.isRequired,
        kontaktId: PropTypes.string.isRequired,
        refetch: PropTypes.func,
    }

    constructor(props, context) {
        super(props, context)

        this.state = {
            newsletter: props.newsletter,
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.newsletter !== prevProps.newsletter) {
            this.setState({newsletter: this.props.newsletter})
        }
    }

    handleButtonClick() {
        const {newsletter} = this.state
        this.setState({newsletter: !newsletter})
        const {kontaktId, refetch} = this.props
        let message
        if (newsletter) {
            message = new Kontakt_wird_Zustimmung_zum_Newsletterempfang_widerrufen(kontaktId)
        } else {
            message = new Kontakt_wird_Newsletterempfang_zustimmen(kontaktId)
        }
        const mutation = gql(`mutation x($message: String!){${message.constructor.messageName}(message:$message)}`)
        this.context.client.mutate({mutation, variables: {message: JSON.stringify(message)}}).then(
            result => {
                if (result.data && result.data[message.constructor.messageName] === "ok") {
                    if (refetch) {
                        refetch();
                    }
                } else {
                    this.setState({newsletter: this.props.newsletter});
                }
            },
        ).catch(this.setState({newsletter: this.props.newsletter}))
    }

    render() {
        const {newsletter} = this.props

        return <StatusButtonField value={newsletter} onChange={() => this.handleButtonClick()}
                                  placeholder="Newsletter"/>
    }
}