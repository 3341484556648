import * as React from "react"
import PropTypes from "prop-types"
import {ModalContainer} from "../UI/ModalContainer"
import {Editor} from "../UI/Editors/Editor"
import {EditorField} from "../UI/Editors/EditorField"
import {CheckboxField} from "../UI/Editors/CheckboxField"
import {EditorControls} from "../UI/Editors/EditorControls"
import {Kursbuchung_soll_verschoben_werden} from "../Daten/CheckinMessages.gen"
import {KursterminSelectField} from "../UI/Editors/KursterminSelectField"
import {Link} from "react-router-dom"

export class BuchungVerschieben extends React.Component {
    static propTypes = {
        buchungId: PropTypes.string.isRequired,
        kursId: PropTypes.string.isRequired,
    }

    state = {
        showModal: false,
    }

    render() {
        const {buchungId, kursId} = this.props
        const {showModal} = this.state
        if (!showModal) {
            return <button className="btn btn-gruen w-64" name="verschieben"
                           onClick={() => this.setState({showModal: true})}>Buchung verschieben
            </button>
        }
        return <ModalContainer close={() => this.setState({showModal: false})} title="Buchung verschieben">
            <Editor resultHandler={result => {
                if (result.data["Kursbuchung_soll_verschoben_werden"]==="ok") {
                    this.setState({showModal: false})
                    alert("Buchung wurde erfolgreich verschoben")
                }
            }}
                save={values => new Kursbuchung_soll_verschoben_werden(buchungId, kursId, values.kurs.kursId, values.bestaetigungsmail, values.preisBerechnen)}
                bestaetigungsmail={false} preisBerechnen={false} kurs={{kursId: null, kursCode: null}}>
                <EditorField component={KursterminSelectField} valuekey="kurs"/>
                <EditorField component={CheckboxField} valuekey="bestaetigungsmail"
                             placeholder="Bestätigungsmail senden"/>
                <EditorField component={CheckboxField} valuekey="preisBerechnen" placeholder="Preis neu berechnen"/>
                <EditorControls showAlways={true} type="full"/>
            </Editor>
        </ModalContainer>
    }
}

export const BuchungVerschoben = (props) => {
    if (props.verschoben && props.verschoben.Id !== props.kursId) {
        return <span
            className="inline-block badge badge-rot text-xs"><Link
            className="no-underline text-white"
            to={"/kurstermine/" + props.verschoben.Id + "/buchungen"}>verschoben</Link></span>
    }
    return null
}