import * as React from "react"
import gql from "graphql-tag";
import PropTypes from "prop-types"
import {graphql} from "react-apollo/index"
import {
    Loading,
    TeilnehmerNeuZeile,
} from "./components"
import {RechnungField} from "./Rechnung/RechnungField"
import {
    Bestaetigungsemail_soll_versendet_werden,
    Buchung_soll_storniert_werden,
    Buchung_Verwaltungskontakt_Firma_soll_hinterlegt_werden,
    Kontakt_Email_hinterlegen,
    Kontakt_Name_soll_korrigiert_werden,
    Kontakt_Telefonnummer_hinterlegen,
    Kursbuchung_Bestellnummer_hinterlegen,
    Kursbuchung_Gutscheincode_soll_hinterlegt_werden,
    Kursbuchung_Preis_soll_geaendert_werden,
    Kursbuchung_Rechnungsanschrift_soll_geaendert_werden,
    Kursbuchung_Teilnehmer_soll_hinzugefuegt_werden,
    Kursbuchung_Ust_Id_hinterlegen,
    Teilnehmer_soll_storniert_werden,
} from "./Daten/CheckinMessages.gen"
import confirmation from "./UI/Confirm"
import {BemerkungListe} from "./Bemerkungen/BemerkungListe"
import {
    Kursbuchung_Bemerkung_soll_erstellt_werden, Teilnahme_Bemerkung_soll_erstellt_werden,
} from "./Daten/BemerkungenMessages.gen"
import {ModalContainer} from "./UI/ModalContainer"
import {Badge} from "./UI/Badge"
import {textValueFromPreis} from "./Infrastruktur/Helpers"
import {InlineEditor} from "./UI/Editors/InlineEditor"
import {EmailField, PreisBetragField, TelephoneField, TextField} from "./UI/Editors/TextField"
import {AnredeDropdown, Dropdown} from "./UI/Editors/Dropdown"
import {Editor} from "./UI/Editors/Editor"
import {EditorField} from "./UI/Editors/EditorField"
import {EditorControls} from "./UI/Editors/EditorControls"
import {CheckboxField} from "./UI/Editors/CheckboxField"
import {CountryField} from "./UI/Editors/CountryField"
import {WaehrungFieldWrapper} from "./Fields/WaehrungField"
import {SmallInvoiceLogin} from "./Rechnung/SmallInvoiceLogin"
import {BuchungVerschieben} from "./Buchung/BuchungVerschieben"
import {TeilnahmeBestätigen} from "./Teilnahme/TeilnahmeBestätigen"

class BuchungDetails extends React.Component {
    static contextTypes = {client: PropTypes.any};

    constructor(props, context) {
        super(props, context);

        this.state = {
            buchung: null,
            showBookerName: false,
            startBuchung: null,
            bestaetigungButtonDisabled: false,
            neuerTeilnehmer: false,
            teilnehmer: {vorname: "", nachname: "", email: "", anrede: "W", titel: ""},
        }

        this.handleStateClick = this.handleStateClick.bind(this)
        this.handleNeuerTeilnehmerChange = this.handleNeuerTeilnehmerChange.bind(this)
    }

    componentDidUpdate(prevProps) {
        if (this.props.data.buchungen && this.props.data.buchungen[0]
            && this.props.data.buchungen !== prevProps.data.buchungen) {
            const vorn = this.props.data.buchungen[0].bucherRechnungsAdresse.vorname
            this.setState({
                buchung: this.props.data.buchungen[0],
                showBookerName: vorn !== "" && vorn !== null && vorn !== undefined,
                startBuchung: this.props.data.buchungen[0],
            })
        }
    }

    handleNeuerTeilnehmerChange(event) {
        const target = event.target;
        let value = target.type === 'checkbox' ? target.checked : target.value;
        value = target.type === 'number' ? parseInt(value, 10) : value;
        let name = target.name;
        name = name.split('_')[2]

        this.setState({
            teilnehmer: {
                ...this.state.teilnehmer,
                [name]: value,
            },
        })
    }

    async saveNewTeilnehmer() {
        let message
        let neuBerechnen = true
        try {
            await confirmation("Preis neu berechnen?", {
                description: "Soll der Preis der Buchung mit dem neuen Teilnehmer automatisch neu berechnet werden?",
                confirmLabel: "Neu berechnen",
                abortLabel: "Nicht neu berechnen",
            })
        } catch (error) {
            neuBerechnen = false
        }
        message = new Kursbuchung_Teilnehmer_soll_hinzugefuegt_werden(this.state.buchung.Id, this.state.teilnehmer.anrede,
            this.state.teilnehmer.titel,
            this.state.teilnehmer.vorname, this.state.teilnehmer.nachname, this.state.teilnehmer.email, neuBerechnen)
        await this.send(message)
        this.props.data.refetch()
        this.setState({
            neuerTeilnehmer: false,
            teilnehmer: {vorname: "", nachname: "", email: "", anrede: "W"},
        })
    }

    async handleStateClick(event) {
        const target = event.target;
        const name = target.name;
        let message
        switch (name) {
            case "stornieren":
                try {
                    await confirmation("Wirklich stornieren?", {
                        description: "Soll die Buchung wirklich storniert werden?",
                        confirmLabel: "Stornieren",
                        abortLabel: "Nicht stornieren",
                    })
                } catch (error) {
                    // noinspection UnnecessaryReturnStatementJS
                    return;
                }
                message = new Buchung_soll_storniert_werden(this.state.buchung.Id)
                break;
            case "bestaetigungsemail":
                message = new Bestaetigungsemail_soll_versendet_werden(this.state.buchung.Id)
                this.setState({bestaetigungButtonDisabled: true})
                break;
            default:
                return
        }
        await this.send(message)
        this.props.data.refetch()
    }

    async onStorno(id) {
        let teilnehmer
        for (const tn of this.state.buchung.teilnehmer) {
            if (tn.Id === id) {
                teilnehmer = tn
                break
            }
        }
        try {
            await confirmation("Wirklich stornieren?", {
                description: "Soll der Teilnehmer " + teilnehmer.vorname + " " + teilnehmer.nachname + " wirklich storniert werden?",
                confirmLabel: "Stornieren",
                abortLabel: "Nicht stornieren",
            })
        } catch (error) {
            // noinspection UnnecessaryReturnStatementJS
            return;
        }
        let neuBerechnen = true
        try {
            await confirmation("Preis neu berechnen?", {
                description: "Soll der Preis der Buchung mit dem stornierten Teilnehmer automatisch neu berechnet werden?",
                confirmLabel: "Neu berechnen",
                abortLabel: "Nicht neu berechnen",
            })
        } catch (error) {
            neuBerechnen = false
        }
        const message = new Teilnehmer_soll_storniert_werden(id, this.state.buchung.Id, neuBerechnen)
        await this.send(message)
        this.props.data.refetch()
    }

    async send(message) {
        try {
            let buchung = this.state.buchung.Id
            const mutation = gql(`mutation mut($message: String!){${message.constructor.messageName}(message:$message)}`)
            let result = await this.context.client.mutate({
                mutation,
                variables: {message: JSON.stringify(message)},
                refetchQueries: [{query: query, variables: {buchung}}],
            })
            const res = result.data[message.constructor.messageName]
            if (res !== "ok") {
                alert("Beim Speichern ist ein Fehler aufgetreten:\n: " + res);
            }
            if (message.constructor.messageName === "Bestaetigungsemail_soll_versendet_werden") {
                if (res === "ok") {
                    alert("Die Bestätigungsmail wurde zum Versand geplant.")
                }
                this.setState({bestaetigungButtonDisabled: false})
            }
        } catch (error) {
            alert("Beim Speichern ist ein Fehler aufgetreten:\n: " + error);
        }
    }

    render() {
        const classes = ["bg-transparent", "border", "p-1", "my-1"]
        const editorClasses = classes.concat("w-64").reduce((prev, cur) => prev + " " + cur, "").trim()
        if (this.state.buchung) {
            const {vorname, nachname, abteilung, strasse, plz, ort, land} = this.state.buchung.bucherRechnungsAdresse
            const fullNameStr = vorname + " " + nachname
            const hasBestaetigteTeilnahmen = this.state.buchung.teilnehmer.reduce((acc, curr) => {
                if (!!curr.bestaetigt) {
                    return true
                }
                return acc
            }, false)
            return (
                <div className="buchungdetails" key={this.state.buchung.Id}>
                    <h1 className="heading">Buchungsdetails</h1>
                    <div className="buchungsdetails-status">
                            <span
                                className={"inline-block badge " + (!!this.state.buchung.storniert ? "badge-rot" : "badge-gruen")}>
                                {!!this.state.buchung.storniert ? "storniert" : "aktiv"}
                            </span>
                    </div>
                    <h2 className="heading">Teilnehmer</h2>
                    <p className="text-red">
                        Bitte nur Schreibfehler im Namen korrigieren! Bei Personenänderung &amp; Reservierungen
                        eine neue Teilnahme eintragen und die alte stornieren!
                    </p>
                    <table className="buchungsdetails-teilnehmer-details alternating-table w-full"
                           style={{"table-layout": "fixed"}}>
                        <thead className="text-left">
                        <tr>
                            <th>Name</th>
                            <th>Email</th>
                            <th>Bestätigt<br/>Storno</th>
                            <th>Bemerkungen</th>
                        </tr>
                        </thead>
                        <tbody>
                        {this.state.buchung.teilnehmer.map((teilnehmer, index) => <Teilnehmerzeile
                            teilnehmer={teilnehmer} buchung={this.state.buchung} kurs={this.props.match.params.id}
                            key={teilnehmer.Id} refetch={this.props.data.refetch}
                            onStorno={(id) => this.onStorno(id)} editorClasses={classes}
                            index={index}/>)}
                        {!!this.state.buchung.storniert || (this.state.neuerTeilnehmer ? <tr>
                                    <td colSpan={2}><TeilnehmerNeuZeile index={0} teilnehmer={this.state.teilnehmer}
                                                                        onChange={this.handleNeuerTeilnehmerChange}/></td>
                                    <td>
                                        <button onClick={() => this.saveNewTeilnehmer()}
                                                className="btn btn-gruen h-8">Speichern
                                        </button>
                                    </td>
                                </tr> :
                                <button className="mt-2 btn btn-gruen"
                                        onClick={() => this.setState({neuerTeilnehmer: true})}
                                        type="button">Teilnehmer hinzufügen
                                </button>
                        )}
                        </tbody>
                    </table>
                    <h2 className="heading">Bucher-/Rechnungsdaten</h2>
                    <table className="buchungdetails-details alternating-table w-auto">
                        <tbody>
                        <tr>
                            <Th>Firma</Th>
                            <TD>
                                <InlineEditor component={TextField} value={this.state.buchung.bucherFirma}
                                              refetch={this.props.data.refetch} className={editorClasses}
                                              validate={val => !this.state.buchung.storniert}
                                              save={val => new Buchung_Verwaltungskontakt_Firma_soll_hinterlegt_werden(this.state.buchung.Id, val)}/>
                            </TD>
                        </tr>
                        <tr>
                            <Th>Name</Th>
                            <TD>
                                <Editor
                                    save={val => new Kontakt_Name_soll_korrigiert_werden(this.state.buchung.bucher.Id, this.state.buchung.bucher.anrede.code, this.state.buchung.bucher.titel, val.nachname, val.vorname)}
                                    validate={val => !this.state.buchung.storniert}
                                    refetch={this.props.data.refetch} vorname={this.state.buchung.bucher.vorname}
                                    nachname={this.state.buchung.bucher.nachname}>
                                    <EditorField component={TextField} valuekey="vorname" placeholder="Vorname"
                                                 className={editorClasses}/>
                                    <EditorField component={TextField} valuekey="nachname" placeholder="Nachname"
                                                 className={editorClasses}/>
                                    <EditorControls/>
                                </Editor>
                            </TD>
                        </tr>
                        <tr>
                            <Th>Rechnungsadresse</Th>
                            <TD>
                                <Editor
                                    save={val => new Kursbuchung_Rechnungsanschrift_soll_geaendert_werden(this.state.buchung.Id, this.state.buchung.bucherFirma, val.bir ? this.state.buchung.bucher.vorname : null, val.bir ? this.state.buchung.bucher.nachname : null, val.abteilung, val.strasse, val.plz, val.ort, val.land, !!this.state.buchung.rechnungPerPost)}
                                    validate={val => !this.state.buchung.storniert}
                                    refetch={this.props.data.refetch}
                                    abteilung={abteilung} strasse={strasse} plz={plz} ort={ort} land={land.iso}
                                    bir={this.state.showBookerName}>
                                    <span>{this.state.buchung.bucherFirma}</span><br/>
                                    {this.state.showBookerName &&
                                    <span>{fullNameStr}</span>} <EditorField component={CheckboxField} valuekey="bir"
                                                                             placeholder="Buchernamen in Rechnungsadresse aufnehmen"/><br/>
                                    <EditorField component={TextField} valuekey="abteilung" placeholder="Abteilung"
                                                 className={editorClasses}/><br/>
                                    <EditorField component={TextField} valuekey="strasse" placeholder="Straße/Nr"
                                                 className={editorClasses}/><br/>
                                    <EditorField component={TextField} valuekey="plz" placeholder="PLZ"
                                                 className={classes.concat("w-16 mr-0").reduce((prev, cur) => prev + " " + cur, "").trim()}/>
                                    <EditorField component={TextField} valuekey="ort" placeholder="Ort"
                                                 className={classes.concat("w-48").reduce((prev, cur) => prev + " " + cur, "").trim()}/><br/>
                                    <EditorField component={CountryField} valuekey="land" placeholder="Land"
                                                 className={"w-64 my-1"}/>
                                    <EditorControls/>
                                </Editor>
                            </TD>
                        </tr>
                        <tr>
                            <Th>Rechnungsversand</Th>
                            <TD>
                                <InlineEditor component={Dropdown}
                                              value={(!!this.state.buchung.rechnungPerPost).toString()}
                                              validate={val => !this.state.buchung.storniert}
                                              save={val => new Kursbuchung_Rechnungsanschrift_soll_geaendert_werden(this.state.buchung.Id,
                                                  this.state.buchung.bucherFirma,
                                                  this.state.showBookerName ? this.state.buchung.bucher.vorname : null,
                                                  this.state.showBookerName ? this.state.buchung.bucher.nachname : null,
                                                  this.state.buchung.bucherRechnungsAdresse.abteilung,
                                                  this.state.buchung.bucherRechnungsAdresse.strasse,
                                                  this.state.buchung.bucherRechnungsAdresse.plz,
                                                  this.state.buchung.bucherRechnungsAdresse.ort,
                                                  this.state.buchung.bucherRechnungsAdresse.land.iso, val === "true")}
                                              refetch={this.props.data.refetch} className={editorClasses}
                                              options={[
                                                  {value: false.toString(), title: "per E-Mail"},
                                                  {value: true.toString(), title: "per Post"},
                                              ]}/>

                            </TD>
                        </tr>
                        <tr>
                            <Th>Bestellnummer</Th>
                            <TD>
                                <InlineEditor component={TextField} value={this.state.buchung.bestellNummer}
                                              validate={val => !this.state.buchung.storniert}
                                              save={val => new Kursbuchung_Bestellnummer_hinterlegen(this.state.buchung.Id, val)}
                                              refetch={this.props.data.refetch} className={editorClasses}/>
                            </TD>
                        </tr>
                        <tr>
                            <Th>USt-ID</Th>
                            <TD>
                                <InlineEditor component={TextField} value={this.state.buchung.ustId}
                                              validate={val => !this.state.buchung.storniert}
                                              save={val => new Kursbuchung_Ust_Id_hinterlegen(this.state.buchung.Id, val)}
                                              refetch={this.props.data.refetch} className={editorClasses}/>
                            </TD>
                        </tr>
                        <tr>
                            <Th>Telefon</Th>
                            <TD>
                                <InlineEditor component={TelephoneField} value={this.state.buchung.bucher.telefon}
                                              validate={val => !this.state.buchung.storniert}
                                              save={val => new Kontakt_Telefonnummer_hinterlegen(this.state.buchung.bucher.Id, val)}
                                              refetch={this.props.data.refetch} className={editorClasses}/>
                            </TD>
                        </tr>
                        <tr>
                            <Th>E-Mail-Adresse</Th>
                            <TD>
                                <InlineEditor component={EmailField} value={this.state.buchung.bucher.email}
                                              validate={val => !this.state.buchung.storniert}
                                              save={val => new Kontakt_Email_hinterlegen(this.state.buchung.bucher.Id, val)}
                                              refetch={this.props.data.refetch} className={editorClasses}/>
                            </TD>
                        </tr>
                        <tr>
                            <Th>Rabattcode</Th>
                            <TD>
                                <InlineEditor component={TextField} value={this.state.buchung.gutscheinCode || ""}
                                              validate={val => !this.state.buchung.storniert}
                                              save={val => new Kursbuchung_Gutscheincode_soll_hinterlegt_werden(this.state.buchung.Id, val)}
                                              refetch={this.props.data.refetch} className={editorClasses}/>
                            </TD>
                        </tr>
                        <tr>
                            <Th>Preis</Th>
                            <TD>
                                <Editor
                                    save={val => new Kursbuchung_Preis_soll_geaendert_werden(this.state.buchung.Id, {
                                        wert: val.wert,
                                        waehrung: val.waehrung,
                                    })} refetch={this.props.data.refetch}
                                    validate={val => !this.state.buchung.storniert && val.waehrung !== null && val.wert !== null && val.wert !== ""}
                                    wert={this.state.buchung.preis ? String(this.state.buchung.preis.wert).replace('.', '').replace(',', '.') : null}
                                    waehrung={this.state.buchung.preis ? this.state.buchung.preis.waehrung.code : null}>
                                    <EditorField component={PreisBetragField} valuekey="wert" placeholder="Preis"
                                                 className={classes.concat("w-32").reduce((prev, cur) => prev + " " + cur, "").trim()}/>
                                    <EditorField component={WaehrungFieldWrapper} valuekey="waehrung"
                                                 options={[{
                                                     title: "textValue",
                                                     value: this.state.buchung.preis ? this.state.buchung.preis.waehrung.name : "",
                                                 }]}
                                                 className={"mr-2 my-1 w-32 bg-transparent leading-normal border"}/>
                                    <EditorControls/>
                                </Editor>
                            </TD>
                        </tr>
                        <tr>
                            <Th>Kommentar</Th>
                            <TD>
                                <textarea disabled={true} value={this.state.buchung.kommentar}/>
                            </TD>
                        </tr>
                        <tr>
                            <Th>Buchungszeitpunkt</Th>
                            <td>{this.state.buchung.zeitpunkt.humanReadable}</td>
                        </tr>
                        <tr>
                            <Th>Rechnungsnummer</Th>
                            <td>
                                <SmallInvoiceLogin>
                                    <RechnungField rechnungNummer={this.state.buchung.rechnungNummer}
                                                   buchungId={this.state.buchung.Id}
                                                   buchung={this.state.buchung} kursId={this.props.match.params.id}
                                                   refetch={() => this.props.data.refetch()}/>
                                </SmallInvoiceLogin>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                    <ul className="buchung-status-actions list-reset flex justify-between">
                        <li className="h-8">
                            <ul className="list-reset flex">
                                {(!!this.state.buchung.storniert || hasBestaetigteTeilnahmen) ||
                                <li className="h-8 mr-3">
                                    <button className="btn btn-rot w-64" name="stornieren"
                                            onClick={this.handleStateClick}>Buchung stornieren
                                    </button>
                                </li>
                                }
                                {(!!this.state.buchung.storniert || hasBestaetigteTeilnahmen) ||
                                <li className="h-8">
                                    <BuchungVerschieben buchungId={this.state.buchung.Id}
                                                        kursId={this.props.match.params.id}/>
                                </li>
                                }
                            </ul>
                        </li>
                        {!!this.state.buchung.storniert ||
                        <li className="h-8">
                            <button className="btn btn-gruen w-64" name="bestaetigungsemail"
                                    disabled={this.state.bestaetigungButtonDisabled}
                                    onClick={this.handleStateClick}>Buchungsbestätigung erneut
                            </button>
                        </li>
                        }
                    </ul>
                    <BemerkungListe bemerkungen={this.state.buchung.bemerkungen} hasEditor={true}
                                    save={value => new Kursbuchung_Bemerkung_soll_erstellt_werden(this.state.buchung.Id, value)}
                                    refetch={this.props.data.refetch}/>
                </div>
            );
        } else {
            return <div>
                <Loading/>
            </div>
        }
    }
}

class TeilnehmerBemerkungen extends React.Component {
    constructor(props) {
        super(props);
        this.state = {active: false};
    }

    render() {
        if (this.state.active) {
            return <ModalContainer close={() => {
                this.setState({active: false})
            }} title={<h3>Bemerkungen</h3>}>
                <BemerkungListe bemerkungen={this.props.bemerkungen} hasEditor={true}
                                save={value => new Teilnahme_Bemerkung_soll_erstellt_werden(this.props.entity, value)}
                                refetch={this.props.refetch} hideTitle={true}/>
            </ModalContainer>;
        } else {
            return <button onClick={() => {
                this.setState({active: true})
            }}><Badge>{this.props.bemerkungenAnzahl}</Badge></button>;
        }
    }
}

const Teilnehmerzeile = (props) => {
    const classes = props.editorClasses
    const editorClasses = classes.concat("w-64").reduce((prev, cur) => prev + " " + cur, "").trim()
    const smallEditorClasses = classes.concat("w-32").reduce((prev, cur) => prev + " " + cur, "").trim()
    if (!!props.teilnehmer.storniert) {
        return <tr>

            <td className="max-w-1/4">{props.teilnehmer.anrede.text} {props.teilnehmer.titel} {props.teilnehmer.vorname} {props.teilnehmer.nachname}</td>
            <td className="max-w-1/4">{props.teilnehmer.email}</td>
            <td className="max-w-1/4"><span
                className="inline-block badge badge-rot text-xs">storniert</span></td>
            <td className="max-w-1/4"><TeilnehmerBemerkungen entity={props.teilnehmer.Id}
                                                             bemerkungen={props.teilnehmer.bemerkungen}
                                                             bemerkungenAnzahl={props.teilnehmer.bemerkungenAnzahl}
                                                             refetch={props.refetch}/>
            </td>
        </tr>
    }
    return <tr>
        <TD>
            <InlineEditor component={AnredeDropdown} value={props.teilnehmer.anrede.code}
                          save={val => new Kontakt_Name_soll_korrigiert_werden(props.teilnehmer.kontaktId, val, props.teilnehmer.titel, props.teilnehmer.nachname, props.teilnehmer.vorname)}
                          refetch={props.refetch} className={smallEditorClasses}/>
            <InlineEditor component={TextField} value={props.teilnehmer.titel} placeholder="Titel"
                          save={val => new Kontakt_Name_soll_korrigiert_werden(props.teilnehmer.kontaktId, props.teilnehmer.anrede.code, val, props.teilnehmer.nachname, props.teilnehmer.vorname)}
                          refetch={props.refetch} className={smallEditorClasses}/>
            <InlineEditor component={TextField} value={props.teilnehmer.vorname} placeholder="Vorname"
                          save={val => new Kontakt_Name_soll_korrigiert_werden(props.teilnehmer.kontaktId, props.teilnehmer.anrede.code, props.teilnehmer.titel, props.teilnehmer.nachname, val)}
                          refetch={props.refetch} className={smallEditorClasses}/>
            <InlineEditor component={TextField} value={props.teilnehmer.nachname} placeholder="Nachname"
                          save={val => new Kontakt_Name_soll_korrigiert_werden(props.teilnehmer.kontaktId, props.teilnehmer.anrede.code, props.teilnehmer.titel, val, props.teilnehmer.vorname)}
                          refetch={props.refetch} className={smallEditorClasses}/>
        </TD>
        <TD>
            <InlineEditor component={EmailField} value={props.teilnehmer.email}
                          save={val => new Kontakt_Email_hinterlegen(props.teilnehmer.kontaktId, val)}
                          refetch={props.refetch} className={editorClasses}/>
        </TD>

        <td className="max-w-1/4">
            <TeilnahmeBestätigen bestaetigt={!!props.teilnehmer.bestaetigt} teilnahmeId={props.teilnehmer.Id}
                                 buchungId={props.buchung.Id} kursterminId={props.kurs} refetch={props.refetch}/>
            <br/>
            {!!props.teilnehmer.bestaetigt ||
            <button onClick={() => props.onStorno(props.teilnehmer.Id)} className="btn btn-gruen h-8">Teilnehmer
                stornieren
            </button>}
        </td>
        <td className="max-w-1/4"><TeilnehmerBemerkungen entity={props.teilnehmer.Id}
                                                         bemerkungen={props.teilnehmer.bemerkungen}
                                                         bemerkungenAnzahl={props.teilnehmer.bemerkungenAnzahl}
                                                         refetch={props.refetch}/>
        </td>
    </tr>
}

const TD = (props) =>
    <td {...props}/>

const Th = (props) =>
    <th className="text-left">{props.children}</th>

const query = gql`query buchungen($buchung: String){
    buchungen(buchungId: $buchung){
        bucher{
            anrede{code,text},
            titel,
            vorname,
            nachname,
            email,
            telefon,
            Id
        },
        bucherFirma,
        storniert,
        zeitpunkt{humanReadable},
        bucherRechnungsAdresse{
            description,
            vorname,
            nachname
            abteilung,
            strasse,
            plz,
            ort,
            land{iso}
        },
        bestellNummer,
        ustId,
        gutscheinCode,
        rechnungPerPost,
        preis{
            waehrung: waehrungTyp{code,name},
            wert,
            description},
        rechnungNummer,
        kommentar,
        teilnehmer{
            anrede{text,code},
            titel,
            vorname,
            nachname,
            email,
            storniert,
            bestaetigt,
            kontaktId,
            bemerkungenAnzahl,
            bemerkungen{
                Id,
                text,
                autor{name},
                erstellt{humanReadable,isoString}},
            Id
        },
        bemerkungen{
            Id,
            text,
            autor{name},
            erstellt{isoString, humanReadable}
        },
        Id
    }
}`
export default graphql(query, {
    options: ownProps => {
        return {
            fetchPolicy: "network-only",
            variables: {
                buchung: ownProps.match.params.buchungId,
            },
            // pollInterval: 500,
        }
    },
})(BuchungDetails);