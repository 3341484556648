import * as React from "react"
import PropTypes from "prop-types"
import Modal from "./Modal"
import * as ReactDOM from "react-dom"

export class ModalSelect extends React.Component {
    static defaultProps = {
        'abortLabel': 'Cancel',
    }

    static propTypes = {
        'abortLabel': PropTypes.string,
        'message': PropTypes.string.isRequired,
        'options': PropTypes.arrayOf(PropTypes.shape({
            'label': PropTypes.string.isRequired,
            'value': PropTypes.any,
        })).isRequired,
    }

    constructor(props, context) {
        super(props, context)

        this.promise = new Promise((resolve, reject) => {
            this.abort = () => {
                reject()
            }
            this.confirm = (selected) => {
                resolve(selected)
            }
        })

        this.onKeyUp = this.onKeyUp.bind(this)
    }

    onKeyUp(event) {
        if (event.key === 'Escape') {
            this.abort()
            event.preventDefault()
        }
    }

    componentDidMount() {
        ReactDOM.findDOMNode(this.refs.abort).focus()
    }

    render() {
        return <Modal onKeyUp={this.onKeyUp}>
            <div className="modal-header p-8 pb-4 border-b border-grey-light">
                <h4 className="modal-title">{this.props.message}</h4>
            </div>
            <div className="modal-body p-8 pt-4 pb-4">
                <ul>
                    {this.props.options.map((option) => <li key={option.label} className="list-reset mb-2 mt-2">
                        <button role="confirm" // eslint-disable-line jsx-a11y/aria-role
                                type="button" className="btn btn-gruen"
                                onClick={() => this.confirm(option.value || option.label)}>{option.label}</button>
                    </li>)}
                </ul>
            </div>
            <div className="modal-footer border-t border-grey-light p-8 pr-4 pt-4 pb-4">
                <div className="text-right">
                    <button role="abort" type="button" className="btn btn-rot" // eslint-disable-line jsx-a11y/aria-role
                            ref="abort" onClick={this.abort}>{this.props.abortLabel}</button>
                </div>
            </div>
        </Modal>
    }
}

export default async (message, options = {}) => {
    let props = {...options, message: message}
    let wrapper = document.body.appendChild(document.createElement('div'))
    let component = ReactDOM.render(React.createElement(ModalSelect, props), wrapper)
    let cleanup = () => {
        if (ReactDOM.unmountComponentAtNode(wrapper)) {
            wrapper.remove()
        }
    }
    return component.promise.finally(cleanup)
}