import * as React from "react"
import PropTypes from "prop-types"
import {TrainerDisplay} from "./TrainerDisplay";
import {TrainerChooser} from "./TrainerChooser";

export class TrainerListField extends React.Component {

    static contextTypes = {client: PropTypes.any};

    static propTypes = {
        trainer: PropTypes.arrayOf(PropTypes.string).isRequired,
        addTrainer: PropTypes.func.isRequired,
        removeTrainer: PropTypes.func.isRequired
    }

    constructor(props, context) {
        super(props, context);

        this.state={
            editing: false,
            saving: false
        };
    }

    renderItem(trainer){
        return <div><TrainerDisplay Id={trainer} /> <button className="text-dst-logo-gruen-mitte hover:text-dst-logo-gruen-hinten underline" onClick={()=>this.removeTrainer(trainer)}>entfernen</button></div>;
    }

    async addToList(id){
        this.setState({editing:false,saving:true});
        this.props.addTrainer(id).then(()=>this.setState({editing:false,saving:false}));
    }

    async removeTrainer(id){
        this.setState({editing:false,saving:true});
        this.props.removeTrainer(id).then(()=>this.setState({editing:false,saving:false}));
    }

    renderEditor(){
        return <TrainerChooser onSelected={(value)=>this.addToList(value)} onBlur={()=>this.setState({editing:false})} except={this.props.trainer} />;
    }

    renderAddButton(){
        return <button className="text-dst-logo-gruen-mitte hover:text-dst-logo-gruen-hinten underline" onClick={()=>this.setState({editing:true})}>hinzufügen...</button>;
    }

    render(){
        return (<table>
            <thead>
            <tr><td>
                {this.state.saving ? "wird gespeichert..." : this.state.editing ? this.renderEditor() : this.renderAddButton()}
            </td></tr>
            </thead>
            <tbody>
            {this.props.trainer.map(trainer=><tr key={trainer}><td>{this.renderItem(trainer)}</td></tr>)}
            </tbody>
        </table>);
    }
}
