import {graphql} from "react-apollo/index"
import gql from "graphql-tag";
import * as React from "react"
import {Loading, Vier04} from "./components"
import PropTypes from "prop-types"
import {Route, Switch} from "react-router-dom";
import KursterminAnlegen from "./KursterminAnlegen"
import Navigation from "./Navigation"
import Kursterminliste from "./Kursterminliste"
import BuchungAnlegen from "./BuchungAnlegen"
import KursortAnlegen from "./KursortAnlegen"
import Kursortliste from "./Kursortliste"
import KursterminDetails from "./KursterminDetails"
import BuchungDetails from "./BuchungDetails"
import LogViewer from "./LogViewer"
import GraphQLTest from "./GraphQLTest"
import {TrainerDetails, Trainerliste} from "./Trainer/TrainerListe";
import {HistoryViewer} from "./Admin/HistoryViewer";
import {TestVersionCheck, VersionCheck} from "./VersionCheck"
import {AlertContainer} from "./UI/Alert"
import {PreisstaffelListe} from "./Preisstaffel/PreisstaffelListe"
import KontaktListe from "./KontaktListe"
import KontaktDetails from "./KontaktDetails"
import {Login} from "./UI/Login"
import {UpdateCheck} from "./UpdateCheck"
import RegionListe from "./RegionListe"
import {RegionAnlegen} from "./RegionAnlegen"

class Index extends React.Component {
    render() {
        if (this.props.data.loginStatus && !!this.props.data.loginStatus) {
            return (
                <div className="app-container">
                    <Navigation/>
                    <main className="ml-2 mr-2 pb-8">
                        <Switch>
                            <Route exact path="/" component={Kursterminliste}/>
                            <Route path="/kurstermine/neu" component={KursterminAnlegen}/>
                            <Route path="/kurstermine/:id/buchung/neu" component={BuchungAnlegen}/>
                            <Route path="/kurstermine/:id/buchung/:buchungId"
                                   component={BuchungDetails}/>
                            <Route path="/kurstermine/:id/:buchungen(buchungen)?/:teilnehmer(teilnehmer)?"
                                   component={KursterminDetails}/>
                            <Route path="/kurstermine" component={Kursterminliste}/>
                            <Route path="/trainer/:id" component={TrainerDetails}/>
                            <Route path="/trainer" component={Trainerliste}/>
                            <Route path="/kursorte/:id" component={KursortAnlegen}/>
                            <Route path="/kursorte" component={Kursortliste}/>
                            <Route path="/regionen/neu" component={RegionAnlegen}/>
                            <Route path="/regionen" component={RegionListe}/>
                            <Route path="/preisstaffeln" component={PreisstaffelListe}/>
                            <Route path="/log" component={LogViewer}/>
                            <Route path="/graphql" component={GraphQLTest}/>
                            <Route path="/admin" component={HistoryViewer}/>
                            <Route path="/kontakte/:id" component={KontaktDetails}/>
                            <Route path="/kontakte" component={KontaktListe}/>
                            <Route component={Vier04}/>
                        </Switch>
                    </main>
                    <AlertContainer>
                        <TestVersionCheck/>
                        <VersionCheck/>
                        <UpdateCheck/>
                    </AlertContainer>
                </div>
            );
        } else if ('loginStatus' in this.props.data) {
            return <Login callback={() => {
                this.props.data.refetch();
                this.forceUpdate()
            }}/>
        } else {
            return <Loading/>
        }
    }
}

export default graphql(gql`{loginStatus}`, {options: {fetchPolicy: "network-only", pollInterval: 500}})(Index);