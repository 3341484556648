import * as React from "react"
import {LinkButton, Loading} from "../components"
import PropTypes from "prop-types"
import {ModalContainer} from "../UI/ModalContainer"
import {InlineEditor} from "../UI/Editors/InlineEditor"
import {TextField} from "../UI/Editors/TextField"
import {Rechungsstellung_soll_eingetragen_werden} from "../Daten/CheckinMessages.gen"
import {Editor} from "../UI/Editors/Editor"
import {EditorField} from "../UI/Editors/EditorField"
import {EditorControls} from "../UI/Editors/EditorControls"
import {CountryField} from "../UI/Editors/CountryField"
import {MultilineTextField} from "../UI/Editors/MultilineTextField"
import {numberValueFromPreis, textValueFromFloat} from "../Infrastruktur/Helpers"
import gql from "graphql-tag"
import moment from "moment"
import {Fragment} from "react"
import SmallInvoiceKundeField from "../UI/Editors/SmallInvoiceKundeField"
import {SmallInvoiceKundenkontaktField} from "../UI/Editors/SmallInvoiceKundenkontaktField"
import {
    SmallInvoice_Kontakt_soll_angelegt_werden,
    SmallInvoice_Rechnung_soll_angelegt_werden,
} from "../Daten/SmallInvoiceMessages.gen"
import {Link} from "react-router-dom"

export class RechnungField extends React.Component {
    static contextTypes = {
        client: PropTypes.any, // Apollo Client
    };

    static propTypes = {
        rechnungNummer: PropTypes.string,
        buchungId: PropTypes.string.isRequired,
        buchung: PropTypes.object, //todo: properties definieren
        kursId: PropTypes.string.isRequired,
        refetch: PropTypes.func,
        // completionHandler: PropTypes.func.isRequired,
    }

    static classes = ["bg-transparent", "border", "p-1", "my-1"]
    static editorClasses = RechnungField.classes.concat("w-64").reduce((prev, cur) => prev + " " + cur, "").trim()
    static smallEditorClasses = RechnungField.classes.concat("w-32 mx-0").reduce((prev, cur) => prev + " " + cur, "").trim()
    static textAreaClasses = RechnungField.classes.concat("w-full h-32").reduce((prev, cur) => prev + " " + cur, "").trim()

    initialState = {
        inProcess: false,
        step: 0,
        kunde: 0,
        kundenadresse: 0,
        person: null,
        rechnungNummer: null,
        hidden: false,
    }
    state = this.initialState

    reset() {
        this.setState(this.initialState)
    }

    componentWillMount() {
        this.kursterminLaden()
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.buchungId !== prevProps.buchungId) {
            this.kursterminLaden()
        }
    }

    async kursterminLaden() {
        let query = gql`query smallInvoiceRechnungfieldKursterminQuery($Id: String!){kurstermine(Id: $Id){
            titel,
            titelEn,
            beginn{
                humanReadable,
                isoString},
            dauer,
            preis{
                wert,
                waehrung: waehrungTyp{
                    code,
                    name
                }
            },
            vat { satz, reverseCharge },
            smallInvoiceProduktId,
            typ{
                Id,
                name
            },
            region{
                Id,
                name,
                land {
                    iso
                }
            },
            sprache: spracheTyp {
                name,
                code
            },
            kursCode,
            Id
        }}`

        let result = await this.context.client.query({
            query,
            fetchPolicy: "network-only",
            variables: {Id: this.props.kursId},
        })
        if (result.data.kurstermine[0]) {
            this.setState({termin: result.data.kurstermine[0]})
        }
    }


    render() {
        if (this.props.rechnungNummer) {
            return this.props.rechnungNummer
        }
        if (this.state.hidden && this.state.rechnungNummer) {
            return this.state.rechnungNummer
        }
        if (this.state.termin && this.state.termin.smallInvoiceProduktId === null) {
            return <Link className="text-dst-logo-gruen-hinten hover:text-dst-logo-gruen-mitte no-underline"
                         to={"/kurstermine/" + this.props.kursId}>Bitte erst ein SmallInvoice-Produkt für den Kurs
                auswählen.</Link>
        }
        if (!this.props.buchung.preis) {
            return <Link className="text-dst-logo-gruen-hinten hover:text-dst-logo-gruen-mitte no-underline"
                         to={"/kurstermine/" + this.props.kursId + "/buchung/" + this.props.buchungId}>Bitte erst einen
                Preis für die Buchung festlegen.</Link>
        }
        if (!this.state.inProcess) {
            return <LinkButton className="no-underline" role="button" onClick={() => this.setState({inProcess: true})}>
                Rechnung erstellen
            </LinkButton>
        }
        if (!this.state.termin) {
            return <ModalContainer close={() => this.reset()} title="Rechnung anlegen">
                <Loading/>
            </ModalContainer>
        }
        switch (this.state.step) {
            case 0:
                return this.renderStep0()
            case 1:
                return this.renderStep1()
            case 2:
                return this.renderStep2()
            case 3:
                return this.renderStep3()
            default:
                this.reset()
                return null
        }
    }

    renderStep0() {
        let {buchung} = this.props
        let values = {
            firma: buchung.bucherFirma,
            abteilung: buchung.bucherRechnungsAdresse.abteilung,
            vorname: buchung.bucherRechnungsAdresse.vorname,
            nachname: buchung.bucherRechnungsAdresse.nachname,
            email: buchung.bucher.email,
            plz: buchung.bucherRechnungsAdresse.plz,
            ort: buchung.bucherRechnungsAdresse.ort,
            land: buchung.bucherRechnungsAdresse.land.iso,
            ustid: buchung.ustId,
        }
        let str = buchung.bucherRechnungsAdresse.strasse.split(/[\s]+/)
        let hausnr = ""
        if (str.length > 1) {
            hausnr = str[str.length - 1]
        }
        let strasse = str.slice(0, str.length - 1).reduce((prev, cur) => prev + " " + cur, "").trim()
        values = {...values, strasse, hausnr}
        return <ModalContainer close={() => this.reset()} title="Rechnung anlegen">
            <h3>Nur Rechnungsnummer eintragen</h3>
            <InlineEditor component={TextField} value={""} className={rf.editorClasses}
                          save={val => new Rechungsstellung_soll_eingetragen_werden(this.props.buchungId, val)}
                          refetch={() => this.reset() && this.props.refetch()}/>
            <h3>Neue Rechnung bei SmallInvoice anlegen</h3>
            <h4>Bestehenden Kunden auswählen</h4>
            <SmallInvoiceKundeField value={{kunde: null, kundeName: "", kundeAdresse: null}}
                                    onChange={val => this.setState({kunde: val.kunde, kundenadresse: val.kundeAdresse})}
                                    className={"asd"}/>
            <button className="btn btn-gruen mt-1" onClick={() => this.setState({step: 1})}>Kunden übernehmen</button>
            <h4>Neuen Kunden anlegen</h4>
            <Editor save={val => new SmallInvoice_Kontakt_soll_angelegt_werden(
                val.firma,
                val.abteilung !== "" ? val.abteilung : null,
                val.vorname !== "" ? val.vorname : null,
                val.nachname !== "" ? val.nachname : null,
                val.email,
                val.strasse,
                val.hausnr,
                val.plz,
                val.ort,
                val.land,
                val.ustid,
            )}
                    resultHandler={result => this.handleStep0Result(result)}
                    {...values}>
                <EditorField component={TextField} className={rf.editorClasses} valuekey="firma"
                             placeholder="Firmenname*"/>
                <EditorField component={TextField} className={rf.editorClasses} valuekey="abteilung"
                             placeholder="Abteilung*"/>
                <div className="flex justify-between w-64">
                    <EditorField component={TextField} className={rf.smallEditorClasses} valuekey="vorname"
                                 placeholder="Kontakt-Vorname*"/>
                    <EditorField component={TextField} className={rf.smallEditorClasses} valuekey="nachname"
                                 placeholder="Kontakt-Nachname*"/>
                </div>
                <div className="flex justify-between w-64">
                    <EditorField component={TextField}
                                 className={rf.classes.concat("w-48 mx-0").reduce((prev, cur) => prev + " " + cur, "").trim()}
                                 valuekey="strasse"
                                 placeholder="Straße"/>
                    <EditorField component={TextField}
                                 className={rf.classes.concat("w-16 mx-0").reduce((prev, cur) => prev + " " + cur, "").trim()}
                                 valuekey="hausnr"
                                 placeholder="Nr."/>
                </div>
                <EditorField component={TextField} className={rf.editorClasses} valuekey="email"
                             placeholder="E-Mail-Adresse"/>
                <div className="flex justify-between w-64">
                    <EditorField component={TextField}
                                 className={rf.classes.concat("w-16 mx-0").reduce((prev, cur) => prev + " " + cur, "").trim()}
                                 valuekey="plz"
                                 placeholder="PLZ"/>
                    <EditorField component={TextField}
                                 className={rf.classes.concat("w-48 mx-0").reduce((prev, cur) => prev + " " + cur, "").trim()}
                                 valuekey="ort"
                                 placeholder="Ort"/>
                </div>
                <EditorField component={CountryField} valuekey="land" className={rf.editorClasses}/>
                <EditorField component={TextField} className={rf.editorClasses} valuekey="ustid"
                             placeholder="USt/VAT-ID"/>
                <EditorControls showAlways={true} type="full"/>
            </Editor>
            <dl className="flex justify-between">
                <dt className="mr-2">*</dt>
                <dd>
                    Die Felder Firmenname, Abteilung, Kontakt-Vorname und Kontakt-Nachname sind optional.
                    Ist kein Firmenname angegeben, muss ein Kontakt-Vor- und -Nachname angegeben werden.
                    Wird ein Firmenname ohne Kontakt angegeben, wird kein Kontakt angelegt, ansonsten wird ein
                    Kontakt angelegt, der mit auf der Rechnung aufgedruckt wird.
                </dd>
            </dl>
        </ModalContainer>
    }

    handleStep0Result(result) {
        if (result.data.SmallInvoice_Kontakt_soll_angelegt_werden) {
            let str = result.data.SmallInvoice_Kontakt_soll_angelegt_werden.split(":")
            if (str[0] === "ok") {
                this.setState({
                    step: 2,
                    kunde: parseInt(str[1], 10),
                    kundenadresse: parseInt(str[2], 10),
                    person: str.length > 3 ? parseInt(str[3], 10) : null,
                })
                return true
            } else {
                alert(str[1])
                return false
            }
        }
        return false
    }


    renderStep1() {
        return <ModalContainer close={() => this.reset()} title="Rechnung anlegen">
            <h3>Kontakt auswählen</h3>
            <SmallInvoiceKundenkontaktField value={{kontakt: null, kontaktName: ""}} kunde={this.state.kunde}
                                            className={"asd"}/>
            <button className="btn btn-gruen" onClick={() => this.setState({step: 2})}>Kontakt übernehmen</button>
            <button className="btn btn-rot" onClick={() => this.setState({step: 2})}>Ohne Kontakt weiter</button>
        </ModalContainer>
    }

    renderStep2() {
        let {buchung} = this.props
        let {termin} = this.state
        const infoline = termin.vat.reverseCharge ? "Steuerschuldnerschaft des Leistungsempfängers.\n\n" : (
            termin.vat.satz === 0 && termin.region.land.iso === "CHE" ? "Öffentliche Kurse sind in der Schweiz von der Mehrwertsteuer ausgenommen.\n\n" : ""
        )
        const defaultTexts = {
            beforetext: "Sehr geehrte Damen und Herren,\n\nvielen Dank für Ihre Anmeldung" + (buchung.bestellNummer != null ? " mit der Bestellnummer " + this.props.buchung.bestellNummer : "") + ", die wir hiermit gerne bestätigen. Wir bitten Sie um die Begleichung der Kursgebühren.",
            aftertext: infoline + "Bitte überweisen Sie den Rechnungsbetrag auf das angegebene Konto.\n\nMit freundlichen Grüssen\nDasScrumTeam\n\n-\n\nWir helfen Ihnen, Scrum zu beherrschen, damit Ihre Produkte schnell und erfolgreich am Markt sind.",
        }
        moment.locale('de')
        let beginn = moment(termin.beginn.isoString).format("L")
        let ende = moment(termin.beginn.isoString).add(termin.dauer - 1, "days").format("L")
        let tns = []
        let teilnehmer = ""
        buchung.teilnehmer.filter(tn => !tn.storniert).forEach(tn => tns.push((tn.titel ? tn.titel + " " : "") + tn.vorname + " " + tn.nachname))
        if (tns.length === 1) {
            teilnehmer = tns[0]
        } else if (tns.length === 2) {
            teilnehmer = tns[0] + " und " + tns[1]
        } else {
            teilnehmer = tns.reduce((prev, cur) => prev + ", " + cur, "").substr(1).trim()
        }
        const produktbeschreibung = termin.titel + " Training am " + beginn.substr(0, 6) +
            " bis " + ende + "\n\nTeilnehmer: " + teilnehmer
        const produkttext = termin.kursCode + "\n" + produktbeschreibung
        let gesamtpreis = numberValueFromPreis(buchung.preis)
        let einzelpreis = gesamtpreis / tns.length
        let ust = gesamtpreis * termin.vat.satz
        let totalpreis = gesamtpreis + ust
        let waehrung = buchung.preis ? buchung.preis.waehrung.code : ""
        return <ModalContainer close={() => this.reset()} title="Rechnung anlegen">
            <h3>Rechnungsbeschriftung</h3>
            <Editor save={values => new SmallInvoice_Rechnung_soll_angelegt_werden(this.state.kunde,
                this.state.kundenadresse, this.state.person, values.beforetext, values.aftertext, termin.smallInvoiceProduktId,
                termin.kursCode, produktbeschreibung, this.props.buchungId)}
                    resultHandler={result => this.handleStep2Result(result)} {...defaultTexts}>
                <EditorField className={rf.textAreaClasses} component={MultilineTextField} valuekey="beforetext"
                             placeholder="Text vor den Positionen"/>
                <table className="w-full">
                    <tr className="border-b border-grey">
                        <th>Produkt</th>
                        <th>MWSt.</th>
                        <th className="text-right">Menge</th>
                        <th className="text-center">Preis / Einheit</th>
                        <th className="text-right">Total</th>
                    </tr>
                    <tr>
                        <td>{produkttext.split('\n').map((item, key) => {
                            return <Fragment key={key}>{item}<br/></Fragment>
                        })}</td>
                        <td>{termin.vat.satz * 100} %</td>
                        <td className="text-right">{tns.length}</td>
                        <td className="text-center lg:w-48">{textValueFromFloat(einzelpreis)} / Teilnehmer</td>
                        <td className="text-right lg:w-32">{textValueFromFloat(gesamtpreis)}</td>
                    </tr>
                    <tr className="border-t border-grey">
                        <td colSpan={3} className="bg-transparent"/>
                        <td className="border-b border-grey">Total exkl. MWSt.</td>
                        <td className="border-b border-grey text-right">{waehrung} {textValueFromFloat(gesamtpreis)}</td>
                    </tr>
                    <tr>
                        <td colSpan={3} className="bg-transparent"/>
                        <td>MWSt. {termin.vat.satz * 100}%</td>
                        <td className="text-right">{waehrung} {textValueFromFloat(ust)}</td>
                    </tr>
                    <tr>
                        <td colSpan={3} className="bg-transparent"/>
                        <td className="border-b-2 border-grey font-bold">Total</td>
                        <td className="border-b-2 border-grey text-right">{waehrung} {textValueFromFloat(totalpreis)}</td>
                    </tr>
                </table>
                <EditorField className={rf.textAreaClasses} component={MultilineTextField} valuekey="aftertext"
                             placeholder="Text nach den Positionen"/>
                <EditorControls showAlways={true} type="full"/>
            </Editor>
        </ModalContainer>
    }

    handleStep2Result(result) {
        if (result.data.SmallInvoice_Rechnung_soll_angelegt_werden) {
            let str = result.data.SmallInvoice_Rechnung_soll_angelegt_werden.split(":")
            if (str[0] === "ok") {
                this.setState({step: 3, rechnungNummer: str[1]})
                return true
            } else {
                alert(str[1])
                return false
            }
        }
        return false
    }

    renderStep3() {
        return <ModalContainer close={() => this.reset()} title="Rechnung anlegen">
            <h3>Erfolg</h3>
            <p>Die Rechnung <strong>{this.state.rechnungNummer}</strong> wurde erfolgreich angelegt.</p>
            <button className="btn btn-gruen" onClick={() => this.setState({hidden: true})}>Fenster schließen</button>
        </ModalContainer>
    }
}

const rf = RechnungField