import * as React from "react"
import PropTypes from "prop-types"
import {Textarea} from "../components"
import {gql} from "apollo-boost";

export class BemerkungEditor extends React.Component {
    static contextTypes = {
        client: PropTypes.any, // Apollo Client
    };

    static propTypes = {
        startValue: PropTypes.string.isRequired,
        save: PropTypes.func.isRequired,
        refetch: PropTypes.func,
    }

    constructor(props, context) {
        super(props, context)

        this.state = {
            value: props.startValue,
            saveHandler: props.saveHandler,
        }
    }

    async save() {
        try {
            const refetch = this.props.refetch;
            const message = this.props.save(this.state.value);
            const mutation = gql(`mutation test($message: String!){${message.constructor.messageName}(message:$message)}`)
            let result = await this.context.client.mutate({mutation, variables: {message: JSON.stringify(message)}})
            if (result.data && result.data[message.constructor.messageName] === "ok") {
                this.setState({changed: false});
                if (refetch) {
                    refetch();
                }
            } else {
                alert("Beim Speichern des Kommentars ist ein Fehler aufgetreten.")
            }
            this.setState({value: ""})
        } catch (error) {
            alert("Beim Speichern des Kommentars ist ein Fehler aufgetreten:\n" + error)
        }
    }

    render() {
        return <div>
            <h3 className="heading">Neue Bemerkung</h3>
            <Textarea value={this.state.value} onChange={(event) => this.setState({value: event.target.value})}
                      placeholder="Neue Bemerkung"/>
            <button className="btn btn-gruen w-64" onClick={() => this.save()}>Bemerkung hinzufügen</button>
        </div>
    }
}