import PropTypes from "prop-types";
import * as React from "react";

export class CheckboxField extends React.Component {

    static propTypes = {
        value: PropTypes.bool,
        placeholder: PropTypes.string,
        enabled: PropTypes.bool,
        save: PropTypes.func,
        revert: PropTypes.func,
        onChange: PropTypes.func,
        className: PropTypes.string,
        highlight: PropTypes.string,
        tabIndex: PropTypes.number,
        type: PropTypes.string,
    };

    constructor(props, context) {
        super(props, context)

        this.r = Math.random().toString(36).substring(7);
    }


    keyup(e) {
        if (e.key === "Enter") {
            this.props.save();
            e.target.blur();
            e.preventDefault();
        }
        if (e.key === "Escape") {
            this.props.revert();
            e.preventDefault();
        }
    }

    static highlight(reason) {
        if (reason === "updated") {
            return "bg-dst-logo-gruen-vorne";
        }
        if (reason === "error") {
            return "bg-red-lighter";
        }
        return "";
    }

    render() {
        let mr = "mr-2 "
        if (this.props.className.includes("mx-") || this.props.className.includes("mr-")) {
            mr = ""
        }
        return <span className={mr + this.props.className + " " + (CheckboxField.highlight(this.props.highlight))}>
            <input readOnly={!this.props.enabled}
                   id={this.r}
                   type="checkbox"
                   checked={this.props.value}
                   placeholder={this.props.placeholder}
                   onChange={e => this.props.onChange(e.target.checked)}
                   onKeyUp={(e) => this.keyup(e)}
                   tabIndex={this.props.tabIndex}
            />
            {this.props.placeholder && <label htmlFor={this.r}>{this.props.placeholder}</label>}
            </span>
    }
}