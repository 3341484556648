import * as React from "react"

export default class Modal extends React.Component {

    modal() {
        return <div className="relative bg-white rounded-lg w-full max-w-md m-auto flex-col flex" tabIndex="-1"
                    role="dialog" onKeyUp={this.props.onKeyUp}
                    aria-hidden={false} ref="modal">
            <div className="modal-dialog">
                <div className='modal-content'>
                    {this.props.children}
                </div>
            </div>
        </div>
    }

    render() {
        return <div className="fixed pin z-50 overflow-auto bg-smoke-light flex">
            {this.modal()}
        </div>
    }
}