import React from "react"
import PropTypes from "prop-types";

export const Bemerkung = ({bemerkung}) => <div className="border mb-2">
    <div className="flex justify-between w-full text-sm mb-2">
        <div className="w-1/2">Autor: {bemerkung.autor.name}</div>
        <div className="w-1/2 text-right">Datum: {bemerkung.erstellt.humanReadable}</div>
    </div>
    <div className="w-full overflow-x-auto">
        {bemerkung.text}
    </div>
</div>

Bemerkung.propTypes = {
    bemerkung: PropTypes.shape({
        Id: PropTypes.string.isRequired,
        text: PropTypes.string.isRequired,
        autor: PropTypes.shape({
            name: PropTypes.string.isRequired,
        }).isRequired,
        erstellt: PropTypes.shape({
            humanReadable: PropTypes.string.isRequired,
        }).isRequired,
    }).isRequired,
}