import {graphql} from "react-apollo/index"
import * as React from "react"
import {Label} from "./components"
import gql from "graphql-tag";
import Autosuggest from "react-autosuggest"
import {AutosuggestTheme} from "./App"

const getSuggestionValue = suggestion => suggestion;
const renderSuggestion = (suggestion) => (
    <div>{suggestion.name}</div>
);

class CountrySelect extends React.Component {
    initialState = {
        laender: [{iso: "DEU", name: "Deutschland"}, {iso: "CHE", name: "Schweiz"}],
    }

    constructor(props, context) {
        super(props, context);

        this.state = {
            laender: this.initialState.laender,
            name: props.name,
            title: props.title,
            value: props.value,
            textValue: this.textValueForValue(props.value),
            eventHandler: props.onChange,
            suggestions: [],
        }

        this.handleSuggestionChange = this.handleSuggestionChange.bind(this);
        this.handleSuggestionFetchRequest = this.handleSuggestionFetchRequest.bind(this);
        this.handleSuggestionClearRequest = this.handleSuggestionClearRequest.bind(this);
        this.handleKeyUp = this.handleKeyUp.bind(this)
    }

    textValueForValue(value, laender) {
        if (typeof laender === 'undefined') {
            laender = this.state ? this.state.laender : this.initialState.laender
        }
        for (const land of laender) {
            if (land.iso === value) {
                return land.name
            }
        }
        return ""
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.data.laender && nextProps.data.laender !== this.state.laender) {
            this.setState({
                "laender": nextProps.data.laender,
                textValue: this.state.value ? this.textValueForValue(this.state.value, nextProps.data.laender) : this.state.textValue,
            })
        }
        this.setState({
            name: nextProps.name,
            title: nextProps.title,
            value: nextProps.value,
            onChange: nextProps.onChange,
        })
    }

    handleKeyPress(event) {
        if (event.key === 'Enter') {
            event.preventDefault();
        }
    }

    handleKeyUp(event) {
        if (event.key === 'Enter') {
            event.preventDefault();
        } else {
            this.props.onKeyUp && this.props.onKeyUp(event)
        }
    }

    componentWillMount() {
        try {
            // noinspection JSIgnoredPromiseFromCall
            this.handleSuggestionFetchRequest({value: this.state.textValue})
        } catch (error) {
            // ignore
        }
    }

    render() {
        const inputProps = {
            placeholder: "Zum Suchen tippen...",
            value: this.state.textValue,
            onChange: this.handleSuggestionChange,
            onKeyPress: this.handleKeyPress,
            onKeyUp: this.handleKeyUp,
            className: "leading-normal border w-full",
            name: "3accbd9a", /* heißt so, weil Chrome sonst Autocompletion macht 🖕 */
            autoComplete: "off",
            "aria-autocomplete": "none",
        }

        let noHeader = this.state.title === null || this.state.title === undefined
        return <div>
            {noHeader || <Label name={this.state.name} title={this.state.title}/>}{noHeader || <br/>}
            <Autosuggest suggestions={this.state.suggestions}
                         onSuggestionsFetchRequested={this.handleSuggestionFetchRequest}
                         onSuggestionsClearRequested={this.handleSuggestionClearRequest}
                         shouldRenderSuggestions={() => true}
                         getSuggestionValue={getSuggestionValue} theme={AutosuggestTheme}
                         renderSuggestion={renderSuggestion} inputProps={inputProps}/>
        </div>
    }

    async handleSuggestionFetchRequest({value}) {
        const inputValue = value.trim().toLowerCase();

        const laender = this.state.laender;

        const suggestions = laender.filter(land =>
            inputValue.split(' ').every(input =>
                land.name.toLowerCase().includes(input),
            ),
        );

        this.setState({
            suggestions,
        })
    }

    handleSuggestionClearRequest() {
        this.setState({
            suggestions: [],
        })
    }

    handleSuggestionChange(event, {newValue}) {
        if (typeof newValue === 'string' || newValue instanceof String) {
            this.setState({
                textValue: newValue,
                value: null,
            })
            this.state.eventHandler && this.state.eventHandler({
                target: {
                    name: this.state.name,
                    type: "autosuggest",
                    value: null,
                },
            })
        } else {
            this.setState({
                textValue: newValue.name,
                value: newValue.Id,
            })
            this.state.eventHandler && this.state.eventHandler({
                target: {
                    name: this.state.name,
                    type: "autosuggest",
                    value: newValue.iso,
                },
            })
        }
    }
}

export default graphql(gql`{laender{iso,name}}`, {options: {fetchPolicy: "network-only"}})(CountrySelect);