import * as React from "react"
import PropTypes from "prop-types"
import {KurstypSelector} from "./KurstypSelector"

export class KurstypenListe extends React.Component {
    static propTypes = {
        kurstypen: PropTypes.arrayOf(PropTypes.shape(
            {
                Id: PropTypes.string.isRequired,
                name: PropTypes.string.isRequired,
            })).isRequired,
        addKurstyp: PropTypes.func.isRequired,
        removeKurstyp: PropTypes.func.isRequired,
    }

    state = {
        editing: false,
        saving: false,
    }

    async addToList(kurstyp) {
        this.setState({editing: false, saving: true});
        this.props.addKurstyp(kurstyp).then(() => this.setState({editing: false, saving: false}));
    }

    async removeRabatt(kurstypId) {
        this.setState({editing: false, saving: true});
        this.props.removeKurstyp(kurstypId).then(() => this.setState({editing: false, saving: false}));
    }


    renderEditor() {
        return <KurstypSelector onSave={(kurstyp) => this.addToList(kurstyp)}
                                onCancel={() => this.setState({editing: false})}/>;
    }

    renderAddButton() {
        return <button className="text-dst-logo-gruen-mitte hover:text-dst-logo-gruen-hinten underline"
                       onClick={() => this.setState({editing: true})}>Kurstyp zuordnen...</button>;
    }

    renderItem(kurstyp, index) {
        return <div key={index}><span>{kurstyp.name}</span>
            <button className="text-dst-logo-gruen-mitte hover:text-dst-logo-gruen-hinten underline"
                    onClick={() => this.removeRabatt(kurstyp.Id)}>Zuordnung entfernen
            </button>
        </div>;
    }


    render() {
        let {kurstypen} = this.props
        return <td>
            {kurstypen.map((kurstyp, index) => this.renderItem(kurstyp, index))}
            {this.state.saving ? "wird gespeichert..." : this.state.editing ? this.renderEditor() : this.renderAddButton()}
        </td>
    }
}