import * as React from "react"
import gql from "graphql-tag"
import {graphql} from "react-apollo/index"
import {Link} from "react-router-dom"
import {Loading} from "./components"
import * as Reactable from "reactable"

const Table = Reactable.Table
const Tr = Reactable.Tr
const Td = Reactable.Td
const Thead = Reactable.Thead
const Th = Reactable.Th

class RegionListe extends React.Component {
    state = {
        filter: "",
    }

    render() {
        const data = this.props.data
        return <div className="regionen-container">
            <h2 className="heading">Regionen</h2>
            {data.regionen && data.regionen.length > 0 &&
            <div>
                <input type="text" className="w-full border h-8 p-2" value={this.state.filter} autoFocus={true}
                       placeholder="Zum Filtern tippen..."
                       onChange={(event) => this.setState({filter: event.target.value})}/><br/>
                <Table className="w-full alternating-table" sortable={[
                    'Name', 'Land',
                ]} filterable={['Name', 'Land']} filterBy={this.state.filter}
                       hideFilterInput
                       defaultSort={{column: 'Name', direction: 'asc'}} noDataText="Keine Ergebnisse">
                    <Thead className="text-left">
                        <Th className="text-left" column="Name">Name</Th>
                        <Th className="text-left" column="Land">Land</Th>
                    </Thead>
                    {data.regionen.map((kursort, index) => Regionzeile(kursort, index))}
                </Table>
            </div>}
            {data.loading && <Loading/>}
            {data.regionen && data.regionen.length === 0 && <div>Keine Regionen</div>}
            <div className="h-auto mt-2"><Link className="btn btn-gruen no-underline" role="button"
                                               to="/regionen/neu">Neue Region</Link></div>
        </div>
    }
}

const Regionzeile = (region, index) => <Tr key={region.Id} index={index}>
    <Td column="Name">{region.name}</Td>
    <Td column="Land">{region.land.name}</Td>
    <Td column="Zeitzone">{region.zeitzone}</Td>
</Tr>


export default graphql(gql`{regionen{Id,name,land{name}}}`, {
    options: {
        fetchPolicy: "network-only",
    },
})(RegionListe)