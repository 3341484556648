import * as React from "react"
import {graphql} from "react-apollo/index"
import gql from "graphql-tag";
import * as Reactable from "reactable"
import {Link} from "react-router-dom"
import {LinkButton, Loading, ResetButtonItem, StatusButton} from "./components"

import 'moment/locale/de'
import 'react-dates/initialize'
import 'react-dates/lib/css/_datepicker.css';
import {DateRangePicker, isSameDay} from 'react-dates'
import moment from "moment"
import * as queryString from "query-string"
import {NewsletterButton} from "./Newslettereinträge"
import {BuchungExporter} from "./BuchungExporter"
import {TeilnehmerExporter} from "./TeilnehmerExporter"


const Table = Reactable.Table
const Tr = Reactable.Tr
const Td = Reactable.Td
const Thead = Reactable.Thead
const Th = Reactable.Th

const defaultStatusFilter = ["Geplant", "Veröffentlicht", "Wenige Restplätze", "Ausverkauft", "Wird durchgeführt", "Durchgeführt",
    "Abgesagt", "Abgebrochen"]

class Kursterminliste extends React.Component {
    state = {
        filter: "",
        startDate: moment().weekday(0),
        endDate: null,
        statusFilter: defaultStatusFilter,
        focusedInput: null,
        previousFilterSet: null,
    }

    constructor(props, context) {
        super(props, context)

        moment.locale('de')

        this.state = this.getStateForQuery()
        this.refetch()

        this.filterTable = this.filterTable.bind(this)
    }

    componentDidUpdate(prevProps) {
        if (prevProps.location !== this.props.location) {
            // neuen Querystring parsen
            this.setState(this.getStateForQuery, this.refetch)
        }
    }

    getStateForQuery() {
        let state = {...this.state}
        let query = queryString.parse(this.props.location.hash)
        if (query.filter) {
            state = {...state, filter: query.filter}
        }
        if (query.startDate) {
            state = {...state, startDate: moment(query.startDate)}
        }
        if (query.endDate) {
            state = {...state, endDate: moment(query.endDate)}
        }
        if (query.statusFilter) {
            state = {...state, statusFilter: [].concat(query.statusFilter)}
        }
        if (query.filter && /^[0-9]{6}-[A-Za-z]{2,6}-[A-Za-z]+$/.test(query.filter)) {
            let previousFilterSet = state.previousFilterSet ? state.previousFilterSet : {
                startDate: state.startDate,
                endDate: state.endDate,
                statusFilter: state.statusFilter,
            }
            state = {
                ...state,
                startDate: null,
                endDate: null,
                statusFilter: [],
                previousFilterSet,
            }
        } else if (state.previousFilterSet) {
            state = {
                ...state,
                startDate: state.previousFilterSet.startDate,
                endDate: state.previousFilterSet.endDate,
                statusFilter: state.previousFilterSet.statusFilter,
            }
        }
        return state
    }

    updateStatusFilter(status, active) {
        let statusFilter = this.state.statusFilter.slice(0)
        if (status === "reset") {
            statusFilter = defaultStatusFilter
        } else {
            if (active) {
                statusFilter.push(status)
            } else {
                let index = statusFilter.indexOf(status)
                if (index !== -1) {
                    statusFilter.splice(index, 1)
                }
            }
        }
        this.setState({statusFilter}, () => {
            this.refetch()
            let query = queryString.parse(this.props.location.hash)
            query.statusFilter = (() => {
                // comparing the status filter with the default
                // if they're equal we set the filter in the url to empty string
                let defaults = new Set(defaultStatusFilter)
                let filters = new Set(statusFilter)
                if (defaults.size !== filters.size) {
                    return [...filters]
                }
                for (let a of filters) {
                    if (!defaults.has(a)) {
                        return [...filters]
                    }
                }
                return undefined
            })()
            this.props.history.push("#" + queryString.stringify(query, {sort: false}))
        })
    }

    refetch() {
        this.props.data.refetch({
            startDate: this.state.startDate ? this.state.startDate.format("YYYY-MM-DD") : null,
            endDate: this.state.endDate ? this.state.endDate.format("YYYY-MM-DD") : null,
            status: this.state.statusFilter,
        })
    }

    renderDatePresets() {
        const presets = [
            {text: "Diese Woche", start: moment().weekday(0), end: moment().weekday(0).add(6, 'days')},
            {
                text: "In zwei Wochen",
                start: moment().weekday(0).add(14, "days"),
                end: moment().weekday(0).add(6 + 14, 'days'),
            },
            {text: "Dieser Monat", start: moment().startOf('month'), end: moment().endOf('month')},
            {
                text: "Nächster Monat",
                start: moment().startOf('month').add(1, "month"),
                end: moment().startOf('month').add(1, "month").endOf('month'),
            },
            {text: "Dieses Jahr", start: moment().startOf("year"), end: moment().endOf("year")},
            {text: "Zurücksetzen", start: moment().weekday(0)},
        ]
        const {startDate, endDate} = this.state

        return (
            <div className="mx-1 pb-1">
                {presets.map(({text, start, end}) => {
                    const isSelected = isSameDay(start, startDate) && isSameDay(end, endDate)
                    return (
                        <button
                            key={text}
                            className={(isSelected ? "bg-dst-logo-gruen-hinten" : "bg-dst-logo-gruen-mitte") + " font-small p-1 mx-1 font-normal btn"}
                            type="button"
                            onClick={() => {
                                this.onDatesChange({
                                    startDate: start,
                                    endDate: end,
                                })
                                this.setState({focusedInput: null})
                            }}
                        >
                            {text}
                        </button>
                    );
                })}
            </div>
        );
    }

    onDatesChange({startDate, endDate}) {
        this.setState({
            startDate: startDate,
            endDate: endDate,
        }, () => {
            this.refetch()
            let query = queryString.parse(this.props.location.hash)
            query.startDate = startDate ? startDate.format('YYYY-MM-DD') : undefined
            query.endDate = endDate ? endDate.format('YYYY-MM-DD') : undefined
            this.props.history.push("#" + queryString.stringify(query, {sort: false}))
        })
    }

    render() {
        return (
            <div className="kurstermin-container">
                <h2 className="heading">Kurstermine
                    <LinkButton title="Neu laden" onClick={() => this.props.data.refetch()}> <span role="img"
                                                                                                   aria-label="Reload">🔄</span></LinkButton>
                </h2>
                <div className="h-8 mb-3">
                    <Link className="btn btn-gruen no-underline mr-2" role="button"
                          to={"/kurstermine/neu"}>Neuer Kurstermin</Link>
                    <NewsletterButton/>
                    <BuchungExporter/>
                    <TeilnehmerExporter/>
                </div>
                <DateRangePicker
                    startDate={this.state.startDate}
                    startDateId="569306f7cc06"
                    endDate={this.state.endDate}
                    endDateId="fdea50aa61fe"
                    onDatesChange={e => this.onDatesChange(e)}
                    focusedInput={this.state.focusedInput}
                    onFocusChange={focusedInput => this.setState({focusedInput})}
                    startDatePlaceholderText="Kurse nach" endDatePlaceholderText="Kurse vor"
                    showClearDates={true} showDefaultInputIcon={true} block={true}
                    numberOfMonths={3} isOutsideRange={() => false}
                    renderCalendarInfo={() => this.renderDatePresets()}
                />
                <div className="flex justify-between mt-2 pr-2">
                    {this.props.data.kursstatus && this.props.data.kursstatus
                        .filter(status => status.text !== "Gestrichen")
                        .map(status => StatusButton({
                            status,
                            active: this.state.statusFilter.includes(status.text),
                            onClick: (status, active) => this.updateStatusFilter(status, active),
                        }))}
                    <button onClick={() => this.updateStatusFilter("reset", false)} className="ml-2">
                        <ResetButtonItem/>
                    </button>
                </div>
                <div className="filter-container w-full relative mt-2">
                    <input type="text" className="w-full border h-8 p-2" value={this.state.filter} autoFocus={true}
                           placeholder="Zum Filtern tippen..." onChange={this.filterTable}/>
                    <button onClick={() => this.filterTable({target: {value: ""}})} className="absolute pin-r mr-2 h-8">
                        <ResetButtonItem/>
                    </button>
                </div>
                <br/>
                {this.props.data.loading && <div className="absolute w-full h-full"><Loading/></div>}
                <Table className="w-full alternating-table" sortable={[
                    'Beginn', 'Kurscode', 'Location', 'Trainer',
                ]} filterable={['Beginn', 'Kurscode', 'Location', 'Trainer', 'Status']}
                       filterBy={this.state.filter.trim()}
                       hideFilterInput
                       defaultSort={{column: 'Beginn', direction: 'asc'}}>
                    <Thead className="text-left">
                        <Th className="text-left" column="Kurscode">Kurscode</Th>
                        <Th className="text-left" column="Kurstitel">Kurstitel</Th>
                        <Th className="text-left" column="Beginn">Beginn</Th>
                        <Th className="text-left" column="Location">Location</Th>
                        <Th className="text-left" column="Status">Status</Th>
                        <Th className="text-left w-32" column="Trainer">Trainer</Th>
                        <Th className="text-left w-32" column="Aktionen"/>
                    </Thead>

                    {this.props.data.kurstermine && this.props.data.kurstermine.map((termin, index) => Terminzeile(termin, index))}
                </Table>

            </div>)
    }

    filterTable(event) {
        const target = event.target;
        let value = target.value;

        this.setState({
            filter: value,
        }, () => {
            let query = queryString.parse(this.props.location.hash)
            query.filter = value
            this.props.history.push("#" + queryString.stringify(query, {sort: false}))
        });
    }
}

const Terminzeile = (termin) => {
    return <Tr className={"border-0 leading-normal" + (termin.status.text === "Abgesagt" ? " line-through" : "")}
               key={JSON.stringify(termin)}>
        <Td column="Kurscode" value={termin.kursCode}><Link
            className="text-dst-logo-gruen-hinten hover:text-dst-logo-gruen-mitte"
            to={"/kurstermine/" + termin.Id}>{termin.kursCode || ("[" + termin.Id + "]")}</Link></Td>
        <Td column="Kurstitel" value={termin.titel}><Link
            className="text-dst-logo-gruen-hinten hover:text-dst-logo-gruen-mitte"
            to={"/kurstermine/" + termin.Id}>{termin.titel}</Link></Td>
        <Td column="Beginn" value={termin.beginn.isoString}>{termin.beginn.humanReadable}</Td>
        <Td column="Location"
            value={termin.region.name + " " + (termin.location ? termin.location.name : "ZZZZZZZZ")}>
            <div>
                {termin.location ? termin.location.name : "TBD"} ({termin.region.name})
            </div>
        </Td>
        <Td column="Status"
            value={termin.status.text + " " + termin.teilnehmerKreis}>
            <span>{termin.status.text}<br/>
                {termin.teilnehmerKreis}{termin.teilnehmerKreis === "Inhouse" && " 🏢"}
                </span></Td>
        <Td column="Trainer"
            value={termin.trainer.reduce((r, t) => r + " " + t.vornamen + " " + t.nachname, "")}>{Trainerinfo(termin.trainer)}</Td>
        <Td column="Aktionen" value="">
            <div>
                <Link to={"/kurstermine/" + termin.Id + "/buchungen"}
                      className="text-dst-logo-gruen-hinten hover:text-dst-logo-gruen-mitte">{termin.buchungen.count} Buchungen</Link><br/>
                <Link to={"/kurstermine/" + termin.Id + "/teilnehmer"}
                      className="text-dst-logo-gruen-hinten hover:text-dst-logo-gruen-mitte">{termin.teilnehmer.count} Teilnehmer</Link>
            </div>
        </Td>
    </Tr>
}

const Trainerinfo = (trainers) => {
    let ts = []
    for (let i = 0; i < trainers.length; i++) {
        const trainer = trainers[i];
        ts.push(<span key={i}>{trainer.vornamen + " " + trainer.nachname}</span>)
        ts.push(<br key={"br" + i}/>)
    }
    ts.pop()
    return <div>{ts}</div>
}

export default graphql(gql`query Kurstermine($status: [String!], $endDate: String, $startDate: String){kurstermine(after: $startDate, before: $endDate, status: $status){kursCode,location{name},region{name},titel,trainer{nachname,vornamen},status{text},teilnehmerKreis,beginn{humanReadable,isoString},buchungen{count},teilnehmer{count},Id},kursstatus{text}}`, {
    variables: {
        startDate: moment().format("YYYY-MM-DD"),
        endDate: null,
        status: defaultStatusFilter,
    },
    options: {
        fetchPolicy: "network-only",
    },
})(Kursterminliste)

   
