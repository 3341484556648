import * as React from 'react';

import {Header} from "./components"
import Index from "./IndexPage";

const VIEWS = {
    LISTE: "LISTE",
    KURSTERMIN_ANLEGEN: "KURSTERMIN_ANLEGEN",
};

class App extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            view: VIEWS.LISTE,
        }
    }

    render() {
        return (
            <div className="App">
                <Header/>
                <Index/>
            </div>
        );
    }
}

export const AutosuggestTheme = {
    suggestionsContainer: 'w-64 absolute mt-1 z-30',
    suggestionsList: 'list-reset w-full border border-b-0 border-dst-logo-gruen-mitte bg-dst-hintergrund-hellgruen',
    suggestion: 'w-full border border-t-0 border-l-0 border-r-0 border-dst-logo-gruen-mitte p-1 cursor-pointer '
        + 'bg-dst-hintergrund-hellgruen text-center text-dst-logo-gruen-hinten hover:text-dst-logo-gruen-mitte',
}

export default App;
