import {gql} from "apollo-boost"
import * as React from "react"
import Autosuggest from "react-autosuggest"
import PropTypes from "prop-types"
import {AutosuggestTheme} from "../App"
import {trainerSort} from "./TrainerListe"

export class TrainerChooser extends React.Component {
    static contextTypes = {client: PropTypes.any};

    static propTypes = {
        onSelected: PropTypes.func.isRequired,
        onBlur: PropTypes.func.isRequired,
        except: PropTypes.arrayOf(PropTypes.string),
    }

    constructor(props, context) {
        super(props, context);
        this.state = {
            value: '',
            suggestions: [],
        };
    }

    handleKeyUp(event) {
        if (event.key === 'Escape' && this.state.value === "") {
            this.props.onBlur();
            event.preventDefault();
        }
    }

    async onSuggestionFetchRequested({value}) {
        try {
            let result
            if (value === "") {
                result = await this.context.client.query({
                    query: gql`{trainerliste{Id, text, vornamen, nachname}}`,
                })
            } else {
                result = await this.context.client.query({
                    query: gql`query TrainerSuche($needle:String!) {trainerliste(search:$needle){Id, text, vornamen, nachname}}`,
                    variables: {needle: value},
                })
            }

            const except = this.props.except || [];
            const trainerliste = result.data.trainerliste.filter(trainer => except.indexOf(trainer.Id) === -1).sort(trainerSort);
            this.setState({suggestions: trainerliste})
        }
        catch (error) {
            alert("couldn't query: " + error);
        }
    }

    onSuggestionSelected(_, {suggestion}) {
        this.props.onSelected(suggestion.Id)
    };

    render() {
        const {value, suggestions} = this.state;

        const inputProps = {
            placeholder: "Zum Suchen tippen...",
            value: value,
            onKeyUp: (...args) => this.handleKeyUp(...args),
            onChange: (_, {newValue}) => this.setState({value: newValue}),
            onBlur: () => this.props.onBlur(),
            className: "leading-normal border w-full",
            autoComplete: "off",
            "aria-autocomplete": "none",
            autoFocus: true,
        }

        return (
            <Autosuggest
                suggestions={suggestions}
                onSuggestionsFetchRequested={(...args) => this.onSuggestionFetchRequested(...args)}
                onSuggestionsClearRequested={() => this.setState({suggestions: []})}
                getSuggestionValue={s => s.text}
                renderSuggestion={s => s.text}
                shouldRenderSuggestions={() => true}
                onSuggestionSelected={(...args) => this.onSuggestionSelected(...args)}
                inputProps={inputProps}
                theme={AutosuggestTheme}
                highlightFirstSuggestion={true}
            />
        );
    }
}
