import * as React from "react"
import PropTypes from "prop-types"
import gql from "graphql-tag"
import {Loading} from "../components"
import {kute_http_url} from "../index"

export class SmallInvoiceLogin extends React.Component {
    static contextTypes = {
        client: PropTypes.any, // Apollo Client
    }

    static propTypes = {
        children: PropTypes.node.isRequired,
    }

    initialState = {
        inProcess: false,
        loggedIn: false,
    }
    state = this.initialState

    constructor(props, context) {
        super(props, context)
    }

    componentWillMount() {
        // noinspection JSIgnoredPromiseFromCall
        this.checkLogin(false, false)
    }

    reset() {
        this.setState(this.initialState)
    }

    win = null

    async loadToken() {
        this.win = window.open("about:blank")
        let mutation = gql`mutation smallInvoiceLogin {SmallInvoice_Login_Prozess_starten{token client_id}}`

        let result = await this.context.client.mutate({
            mutation,
        })
        const item = result.data.SmallInvoice_Login_Prozess_starten
        if (!result.errors && item) {
            this.setState({inProcess: true, token: item.token}, () => {
                let redirect_uri = kute_http_url + "/api-return/smallinvoice/v2/"
                redirect_uri = encodeURIComponent(redirect_uri)
                this.win.location = "https://api.smallinvoice.com/v2/auth/authorize?scope=contact%20invoice%20catalog&response_type=code&client_id=" + item.client_id + "&redirect_uri=" + redirect_uri + "&state=" + this.state.token
                window.setTimeout(() => this.checkLogin(true, true), 1000)
            })
        } else {
            alert("Login-Prozess konnte nicht gestartet werden")
        }
    }

    async checkLogin(reset, repeat) {
        let query = gql`query status($id: String){status: smallInvoiceLoginStatus(id: $id)}`

        let result = await this.context.client.query({
            query,
            variables: {id: Math.random().toString(36).substring(7)},
            options: {fetchPolicy: "network-only"},
        })
        if (!result.errors && !!result.data.status) {
            this.setState({loggedIn: true, inProcess: false})
            if (reset) {
                this.win = null
            }
        } else {
            if (repeat) {
                window.setTimeout(() => this.checkLogin(true, true), 3000)
            }
        }
    }

    render() {
        if (this.state.loggedIn) {
            return this.props.children
        }
        if (!this.state.inProcess) {
            return <button className="btn btn-gruen" role="button" onClick={() => this.loadToken()}>
                Login bei SmallInvoice
            </button>
        }
        return <Loading/>
    }
}
