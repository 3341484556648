import * as React from "react"
import {wrapGraphQL} from "../Infrastruktur/GraphQLQuery";
import {gql} from "apollo-boost";
import {
    Trainer_CMS_Code_soll_gesetzt_werden,
    Trainer_Email_soll_eingegeben_werden,
    Trainer_Mobiltelefon_soll_eingegeben_werden,
    Trainer_Name_soll_geaendert_werden,
    Trainer_Slackname_soll_eingegeben_werden,
    Trainer_soll_aufgenommen_werden,
} from "../Daten/FlugplanMessages.gen";
import {InlineEditor} from "../UI/Editors/InlineEditor";
import {TextField} from "../UI/Editors/TextField";
import {EditorField} from "../UI/Editors/EditorField";
import {EditorControls} from "../UI/Editors/EditorControls";
import {Editor} from "../UI/Editors/Editor";
import {Badge} from "../UI/Badge";
import {ModalContainer} from "../UI/ModalContainer";
import {
    Trainer_Bemerkung_soll_erstellt_werden,
} from "../Daten/BemerkungenMessages.gen";
import {BemerkungListe} from "../Bemerkungen/BemerkungListe"

export const TrainerDetails = () => <div>Trainer</div>;

const defaultStyle = {minWidth: "13vw", maxWidth: "13vw"};
const Th = txt => <th className="text-left" key={txt}>{txt}</th>;
const Td = ({children, style}) => <td style={style || defaultStyle}>
    <div className="flex">{children}</div>
</td>;


class TrainerBemerkungen extends React.Component {
    constructor(props) {
        super(props);
        this.state = {active: false};
    }

    render() {
        if (this.state.active) {
            return <ModalContainer close={() => {
                this.setState({active: false})
            }} title={<h3>Bemerkungen</h3>}>
                <BemerkungListe bemerkungen={this.props.bemerkungen} hasEditor={true}
                                save={value => new Trainer_Bemerkung_soll_erstellt_werden(this.props.entity, value)}
                                refetch={this.props.refetch} hideTitle={true}/>
            </ModalContainer>;
        } else {
            return <button onClick={() => {
                this.setState({active: true})
            }}><Badge>{this.props.bemerkungenAnzahl}</Badge></button>;
        }
    }
}

const Trainerzeile =
    ({Id, titel, nachname, vornamen, email, telefon, slackname, cmscode, bemerkungenAnzahl, bemerkungen}, refetch) =>
        <tr key={Id}>
            <Td><InlineEditor component={TextField} placeholder="Titel" value={titel || ""}
                              save={value => new Trainer_Name_soll_geaendert_werden(Id, value, nachname, vornamen)}
                              refetch={refetch}/></Td>
            <Td><InlineEditor component={TextField} placeholder="Vornamen" value={vornamen || ""}
                              save={value => new Trainer_Name_soll_geaendert_werden(Id, titel, nachname, value)}
                              refetch={refetch}/></Td>
            <Td><InlineEditor component={TextField} placeholder="Nachname" value={nachname || ""}
                              save={value => new Trainer_Name_soll_geaendert_werden(Id, titel, value, vornamen)}
                              refetch={refetch}/></Td>
            <Td><InlineEditor component={TextField} placeholder="CMS" value={cmscode || ""}
                              save={value => new Trainer_CMS_Code_soll_gesetzt_werden(Id, value)}
                              refetch={refetch}/></Td>
            <Td><InlineEditor component={TextField} placeholder="Email" value={email || ""}
                              save={value => new Trainer_Email_soll_eingegeben_werden(Id, value)}
                              refetch={refetch}/></Td>
            <Td><InlineEditor component={TextField} placeholder="Telefon" value={telefon || ""}
                              save={value => new Trainer_Mobiltelefon_soll_eingegeben_werden(Id, value)}
                              refetch={refetch}/></Td>
            <Td><InlineEditor component={TextField} placeholder="Slack" value={slackname || ""}
                              save={value => new Trainer_Slackname_soll_eingegeben_werden(Id, value)}
                              refetch={refetch}/></Td>
            <Td style={{maxWidth: "4vw", minWidth: "4vw"}}>
                <TrainerBemerkungen bemerkungenAnzahl={bemerkungenAnzahl} bemerkungen={bemerkungen}
                                    entity={Id}
                                    refetch={refetch}/></Td>
        </tr>;

export const trainerSort = (a, b) => {
    let anachname = a.nachname.toLowerCase()
    let bnachname = b.nachname.toLowerCase()
    if (anachname < bnachname) {
        return -1
    }
    if (anachname > bnachname) {
        return 1
    }
    let avorname = a.vornamen.toLowerCase()
    let bvorname = b.vornamen.toLowerCase()
    if (avorname < bvorname) {
        return -1
    }
    if (avorname > bvorname) {
        return 1
    }
    return 0
}

export const Trainerliste = () => wrapGraphQL(({trainerliste, refetch}) => <div>
    <h2>Trainerliste</h2>
    <div>
        <table>
            <thead>
            <tr>
                {["Titel", "Vornamen", "Nachname", "CMS", "email", "Telefon", "Slack", "Bem."].map(Th)}
            </tr>
            </thead>
            <tbody>
            {trainerliste.sort(trainerSort).map(x => Trainerzeile(x, refetch))}
            <tr>
                <th className="text-left" colSpan={7}>Trainer anlegen</th>
            </tr>
            <tr>
                <Editor
                    titel={null}
                    vornamen={""}
                    nachname={""}
                    validate={({vornamen, nachname}) => (vornamen + nachname).replace(/\s/g, "") !== ""}
                    save={({titel, vornamen, nachname}) => new Trainer_soll_aufgenommen_werden(titel, nachname, vornamen)}
                    refetch={() => {
                        refetch();
                        return true;
                    }}
                >
                    <Td>
                        <EditorField component={TextField} valuekey="titel" placeholder="Titel"/>
                    </Td>
                    <Td>
                        <EditorField component={TextField} valuekey="vornamen" placeholder="Vornamen"/>
                    </Td>
                    <Td>
                        <EditorField component={TextField} valuekey="nachname" placeholder="Nachnamen"/>
                        <EditorControls/>
                    </Td>
                    <Td></Td>
                </Editor>
                <Td>...</Td>
                <Td>...</Td>
                <Td>...</Td>
                <Td>...</Td>
            </tr>
            </tbody>
        </table>
    </div>
</div>, gql`{trainerliste{Id, titel, nachname, vornamen, email, telefon, slackname, cmscode, bemerkungenAnzahl, bemerkungen{Id, text, autor{name}, erstellt{humanReadable,isoString}}}}`, {});
