import React from "react";
import PropTypes from "prop-types"
import {gql} from "apollo-boost";


export class HistoryViewer extends React.Component {

    static contextTypes = {
        client: PropTypes.any,
    };

    constructor(props, context) {
        super(props, context);

        this.state = {
            events: [],
            error: null,
            count: 20,
            stream: null,
            type: null,
            berfore: null,
        }

        this.runQuery = this.runQuery.bind(this)
        this.eventline = this.eventline.bind(this)
    }

    componentDidMount() {
        this.runQuery(null);
    }

    runQuery() {
        this.setState({error: null});
        let filter = "";
        if (this.state.before) {
            filter += "before:\"" + this.state.before + "\"";
        }
        if (this.state.stream) {
            filter += "stream:\"" + this.state.stream + "\"";
        }
        if (this.state.type) {
            filter += "type:\"" + this.state.type + "\"";
        }
        this.context.client.query({
            query: gql(`{adminevents(count:${this.state.count || 20}${filter}){Id,time,streams,name,data,user}}`),
            fetchPolicy: "network-only",
        }).then(result => {
            this.setState({events: result.data.adminevents});
        }).catch(error => this.setState({error}));
    }

    renderObject(o) {
        return <pre className="overflow-auto">{JSON.stringify(o, null, 2)}</pre>;
    }

    eventline(event) {
        return <tr key={event.Id}>
            <td>{event.Id}</td>
            <td className="w-1/4">{event.streams.join(" ")}<br/>{event.time}</td>
            <td className="w-1/3">{event.name}</td>
            <td className="w-1/3">{this.renderObject(JSON.parse(event.data))}</td>
            <td>{event.user}</td>
        </tr>
    }

    trySetCount(input) {
        const number = parseInt(input, 10)
        if (number + "" === input) {
            this.setState({count: number})
        }
    }

    trySetBefore(before) {
        this.setState({before: before !== "" ? before : null});
    }

    trySetStream(stream) {
        this.setState({stream: stream !== "" ? stream : null});
    }

    trySetType(type) {
        this.setState({type: type !== "" ? type : null});
    }

    render() {
        return <div>
            <h2>History</h2>
            <div className="flex">
                <input type="text" placeholder="#" value={this.state.count}
                       onChange={e => this.trySetCount(e.target.value)}/>
                <input type="text" placeholder="Id" value={this.state.before}
                       onChange={e => this.trySetBefore(e.target.value)}/>
                <input type="text" placeholder="Stream" value={this.state.stream}
                       onChange={e => this.trySetStream(e.target.value)}/>
                <input type="text" placeholder="Type" value={this.state.type}
                       onChange={e => this.trySetType(e.target.value)}/>
                <button onClick={() => this.runQuery()}><span role="img" aria-label="Reload">🔄</span></button>
            </div>
            <table style={{'table-layout': 'fixed'}} className="w-full">
                {this.state.events.map(this.eventline)}
            </table>

        </div>
    }
}