import * as React from "react"
import gql from "graphql-tag"
import {graphql} from "react-apollo/index"
import {Link} from "react-router-dom"
import * as ReactDOM from "react-dom"
import * as Reactable from "reactable"
import {RechnungField} from "./Rechnung/RechnungField"
import {SmallInvoiceLogin} from "./Rechnung/SmallInvoiceLogin"
import {toCSV} from "react-csv/lib/core"
import PropTypes from "prop-types"
import {BuchungVerschoben} from "./Buchung/BuchungVerschieben"

const Table = Reactable.Table
const Tr = Reactable.Tr
const Td = Reactable.Td
const Thead = Reactable.Thead
const Th = Reactable.Th

class Buchungsliste extends React.Component {
    static contextTypes = {
        client: PropTypes.any, // Apollo Client
    };

    state = {
        filter: "",
    }

    constructor(props, context) {
        super(props, context)

        this.filterTable = this.filterTable.bind(this)
    }

    componentDidMount() {
        const el = ReactDOM.findDOMNode(this)
        window.scrollTo(0, el.offsetTop)
    }

    filterTable(event) {
        const target = event.target;
        let value = target.value;

        this.setState({
            filter: value,
        });
    }

    async createCsvData() {
        if (!this.props.data.buchungen) {
            return
        }
        let kursId = this.props.kurs
        let result = await this.context.client.query({
            query: gql`query k($kursId: String){kurstermine(Id: $kursId){kursCode}}`, options: {
                variables: {kursId},
                fetchPolicy: "network-only",
            },
        })
        if (!result.data.kurstermine[0]) {
            return
        }
        let kursCode = result.data.kurstermine[0].kursCode

        let buchungen = this.props.data.buchungen
        let objs = []
        for (let buchung of buchungen) {
            if (!!buchung.storniert) {
                continue
            }
            if (!!buchung.verschoben && buchung.verschoben.Id !== kursId) {
                continue
            }
            let obj = {
                Firma: buchung.bucherFirma,
                Vorname: buchung.bucher.vorname,
                Nachname: buchung.bucher.nachname,
                Preis: buchung.preis.wert,
                "Währung": buchung.preis.waehrung.code,
                Rechnungsnr: buchung.rechnungNummer,
                Teilnehmerzahl: buchung.teilnehmerzahl,
            }
            objs.push(obj)
        }
        let csv = toCSV(objs, null, ";", "\"")
        let element = document.createElement("a")
        const file = new Blob([csv], {type: 'text/csv'})
        element.href = URL.createObjectURL(file);
        element.download = kursCode + "-buchungen.csv";
        element.click();
    }

    render() {
        return <div className="buchungen-container">
            <h2 className="heading">Buchungen</h2>
            <input type="text" className="w-full border h-8 p-2" value={this.state.filter} autoFocus={true}
                   placeholder="Zum Filtern tippen..." onChange={this.filterTable}/><br/>
            <Table className="w-full alternating-table" sortable={[
                'Firma', 'Bucher', 'Teilnehmerzahl', 'Zeitpunkt',
            ]} defaultSort={{column: 'Zeitpunkt', direction: 'asc'}}
                   filterable={['Firma', 'Bucher', 'Teilnehmerzahl', 'Zeitpunkt', 'Rechnung']}
                   filterBy={this.state.filter}
                   hideFilterInput noDataText="Keine Buchungen">
                <Thead className="text-left">
                <Th column="Firma">Firma</Th>
                <Th column="Bucher">Bucher</Th>
                <Th column="Teilnehmerzahl">Teilnehmerzahl</Th>
                <Th column="Zeitpunkt">Zeitpunkt</Th>
                <Th column="Rechnung">Rechnung</Th>
                </Thead>
                {this.props.data.buchungen
                && this.props.data.buchungen.map((buchung) => Buchungszeile(this.props.kurs, buchung, buchung.Id))}
            </Table>
            <div className="h-auto mt-2 mb-4">
                <Link className="btn btn-gruen no-underline" role="button"
                      to={"/kurstermine/" + this.props.kurs + "/buchung/neu"}>Neue
                    Buchung</Link>
                <button className="ml-2 btn btn-gruen no-underline" onClick={() => this.createCsvData()}>
                    Buchungsliste herunterladen
                </button>
            </div>
        </div>
    }


}

const Buchungszeile = (kursId, buchung, key) => {
    let firma = buchung.bucherFirma !== null ? buchung.bucherFirma : "Keine Firma"
    let sortFirma = buchung.bucherFirma !== null ? buchung.bucherFirma : ""
    return (
        <Tr key={key}>
            <Td column="Firma" value={sortFirma}><Link
                className="text-dst-logo-gruen-hinten hover:text-dst-logo-gruen-mitte"
                to={"/kurstermine/" + kursId + "/buchung/" + buchung.Id}>
                {firma}
            </Link></Td>
            <Td column="Bucher"
                value={buchung.bucher.nachname + buchung.bucher.vorname + " " + buchung.bucher.nachname}><span>{buchung.bucher.vorname} {buchung.bucher.nachname}</span></Td>
            <Td column="Teilnehmerzahl" className="text-center" value={buchung.teilnehmerzahl}>
                <div>
                    {buchung.teilnehmerzahl} {!!buchung.storniert && <span
                    className="inline-block badge badge-rot text-xs">storniert</span>}<BuchungVerschoben
                    verschoben={buchung.verschoben} kursId={kursId}/>
                </div>
            </Td>
            <Td column="Zeitpunkt" className="text-center" value={buchung.zeitpunkt.isoString}>
                <div>
                    {buchung.zeitpunkt.humanReadable}
                </div>
            </Td>
            <Td column="Rechnung" className="text-center">
                <SmallInvoiceLogin>
                    <RechnungField rechnungNummer={buchung.rechnungNummer}
                                   buchung={buchung} kursId={kursId}
                                   buchungId={buchung.Id}/>
                </SmallInvoiceLogin>
            </Td>
        </Tr>
    )
}

export default graphql(gql`query buchungen($kurs: String){buchungen(kurs: $kurs){
    bucher{
        anrede{code,text},
        titel,
        vorname,
        nachname,
        email,
        telefon,
        Id
    },
    bucherFirma,
    storniert,
    zeitpunkt{humanReadable,isoString},
    bucherRechnungsAdresse{
        description,
        vorname,
        nachname
        abteilung,
        strasse,
        plz,
        ort,
        land{iso}
    },
    bestellNummer,
    ustId,
    gutscheinCode,
    rechnungPerPost,
    preis{
        waehrung: waehrungTyp{code,name},
        wert,
        description},
    rechnungNummer,
    kommentar,
    teilnehmerzahl,
    teilnehmer{
        anrede{text,code},
        titel,
        vorname,
        nachname,
        storniert,
        Id
    },
    verschoben {
        Id,
        titel,
    },
    Id
}}`, {
    options: ownProps => {
        return {
            fetchPolicy: "network-only",
            variables: {
                kurs: ownProps.match ? ownProps.match.params.id : ownProps.kurs,
                match: ownProps.match ? ownProps.match.url : ownProps.url,
            },
            pollInterval: 500,
        }
    },
})(Buchungsliste)