import {graphql} from "react-apollo/index"
import 'moment/locale/de'
import 'react-dates/initialize'
import 'react-dates/lib/css/_datepicker.css';
import moment from "moment"
import * as React from "react"
import gql from "graphql-tag";
import {InputField, Loading} from "./components"
import {ModalContainer} from "./UI/ModalContainer"
import {DateRangePicker, SingleDatePicker} from "react-dates"
import * as queryString from "query-string"

class Newslettereintraege extends React.Component {
    state = {
        newsletterTag: "news" + moment().format("YYMM"),
        startDate: moment(),
        anzahlKurstermine: 4,
        dateFocused: false,
    }

    constructor(props, context) {
        super(props, context)
        moment.locale('de')
        this.state.startDate = moment()

        this.refetch()

        this.kurstypliste = this.kurstypliste.bind(this)
    }

    componentWillMount() {
        this.refetch()
    }

    refetch() {
        this.props.data.refetch({
            startDate: this.state.startDate.format("YYYY-MM-DD"),
        })
    }

    onDatesChange(date) {
        this.setState({
            startDate: date,
        }, () => {
            this.refetch()
        })
    }

    render() {
        return <ModalContainer title="Terminliste für Newsletter" close={this.props.close}>
            <SingleDatePicker
                date={this.state.startDate}
                onDateChange={d => this.onDatesChange(d)}
                focused={this.state.dateFocused}
                onFocusChange={({focused}) => this.setState({dateFocused: focused})}
                id="569306f7cc06"
                showClearDates={true} showDefaultInputIcon={true} block={true}
                numberOfMonths={3} isOutsideRange={() => false}
            />
            <InputField name="newsletterTag" title="UTM-Content" value={this.state.newsletterTag}
                        onChange={val => this.setState({newsletterTag: val.target.value})}/>
            <InputField type="number" name="anzahlKurstermine" title="Max. Kurstermine pro Kurstyp"
                        value={this.state.anzahlKurstermine}
                        onChange={val => this.setState({anzahlKurstermine: val.target.value})}/>
            {this.props.data.loading ? <Loading/> :
                this.props.data.kurstypen && this.props.data.kurstypen.map(this.kurstypliste)}
        </ModalContainer>
    }

    kurstypliste(typ) {
        let termine = this.props.data.kurstermine
        termine = termine.map(termin => {
            if (termin.typ.Id === "csms" || termin.typ.Id === "csma") {
                termin.typ.Id = "csm"
            }
            return termin
        })
        termine = termine.filter(termin => termin.typ.Id === typ.Id)
        termine = termine.filter(termin => termin.teilnehmerKreis !== "Inhouse")
        termine = termine.filter(termin => ['Veröffentlicht', 'Wenige Restplätze'].includes(termin.status.text))
        termine = termine.sort((a, b) => {
            if (a.beginn.isoString < b.beginn.isoString) {
                return -1
            }
            if (a.beginn.isoString > b.beginn.isoString) {
                return 1
            }
            return 0
        })

        return termine.length > 0 && <div key={typ.Id}>
            <h3>{typ.kuerzel}</h3>
            <ul>
                {termine.slice(0, this.state.anzahlKurstermine).map(termin =>
                    <li key={termin.Id} style={{fontFamily: 'Arial', fontSize: '16px'}}><a
                        href={termin.drupalUrl +
                        "?utm_campaign=newsletter&utm_medium=email&utm_source=email-broadcast&utm_content=" +
                        this.state.newsletterTag + "&utm_term=existing-list"}>
                        {termin.region.name}, {moment(termin.beginn.isoString).format("DD.MM.")} – {moment(termin.beginn.isoString).add((termin.kurstage.length === 0 ? termin.dauer : termin.kurstage.length) - 1, 'days').format("DD.MM.YYYY")} {termin.status.text === "Wenige Restplätze" && "(Restplätze)"}
                    </a>
                    </li>)}
            </ul>
        </div>
    }
}

const Popover = graphql(gql`query Kurstermine($startDate: String){kurstypen{Id,kuerzel,name},kurstermine(after: $startDate){typ{Id},region{name},status{text},teilnehmerKreis,beginn{isoString},Id,kurstage{Id},dauer,drupalUrl}}`, {
    variables: {
        startDate: moment().format("YYYY-MM-DD"),
    },
    options: {
        fetchPolicy: "network-only",
    },
})(Newslettereintraege)

export class NewsletterButton extends React.Component {
    state = {showNewsletterPopover: false}

    render() {
        return <React.Fragment>
            {this.state.showNewsletterPopover && <Popover close={() => this.setState({showNewsletterPopover: false})}/>}
            <button className="btn btn-gruen no-underline" role="button"
                    onClick={() => this.setState({showNewsletterPopover: true})}>Kurstermine für Newsletter
            </button>
        </React.Fragment>
    }
}