import * as React from "react";
import {gql} from "apollo-boost";
import PropTypes from "prop-types"
import {
    InputField,
    InputFieldWithClassName,
    Label,
    QuarterInputField, StatusButton,
    SubmitButton,
    TeilnehmerNeuZeile,
    TextareaField,
} from "./components"
import {Redirect} from "react-router-dom"
import Teilnehmer from "./Daten/Teilnehmer"
import Rechnungsadresse from "./Daten/Rechnungsadresse"
import CountrySelect from "./CountrySelect"
import {
    Bestaetigungsemail_soll_versendet_werden,
    Kurs_soll_gebucht_werden,
    Kursbuchung_Preis_soll_geaendert_werden,
} from "./Daten/CheckinMessages.gen"
import {PreisBetragField} from "./UI/Editors/TextField"
import {WaehrungField} from "./Fields/WaehrungField"


const mutation = gql`mutation buchen($message: String!){Kurs_soll_gebucht_werden(message: $message, type: "Kurs_soll_gebucht_werden")}`;

class BuchungAnlegen extends React.Component {
    static contextTypes = {client: PropTypes.any};

    constructor(props, context) {
        super(props, context);

        this.state = {
            bucherVorname: "",
            bucherNachname: "",
            bucherFirma: "",
            bucherRechnungsAdresse: {
                strasse: "",
                plz: "",
                ort: "",
                land: "DEU",
            },
            bestellNummer: "",
            ustId: "",
            telefon: "",
            emailAdresse: "",
            gutscheinCode: "",
            rechnungPerPost: false,
            teilnehmer: [
                {
                    "anrede": "W",
                    "titel": "",
                    "vorname": "",
                    "nachname": "",
                    "email": "",
                },
            ],
            kommentar: "",
            success: false,
            bestaetigungsMailVersenden: false,
            bucherIsTeilnehmer: false,
            buchernamen_in_Rechnungsadresse_aufnehmen: true,
            buchenWennVoll: false,
            submitButtonDisabled: false,
            preisEintragen: false,
            preis: {
                wert: null,
                waehrung: null,
            },
        }

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleBucherIsTeilnehmerClick = this.handleBucherIsTeilnehmerClick.bind(this);
        this.handleTeilnehmerChange = this.handleTeilnehmerChange.bind(this);
        this.handleAdressChange = this.handleAdressChange.bind(this);
        this.addTeilnehmer = this.addTeilnehmer.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    render() {
        return <div className="kurstermin-anlegen-container">
            <h2 className="heading">Buchung anlegen</h2>
            <form onSubmit={this.handleSubmit} className="buchungEingabe">
                <h3 className="heading">Rechnungsdaten</h3>
                <InputField name="bucherVorname" type="text" title="Vorname" value={this.state.bucherVorname}
                            onChange={this.handleInputChange}/>
                <InputField name="bucherNachname" type="text" title="Nachname" value={this.state.bucherNachname}
                            onChange={this.handleInputChange}/>
                <StatusButton status={{text: "Name des Buchers in Rechnungsadresse anzeigen"}}
                              active={this.state.buchernamen_in_Rechnungsadresse_aufnehmen} className="mt-2 mb-1"
                              onClick={(a, buchernamen_in_Rechnungsadresse_aufnehmen) => this.setState({buchernamen_in_Rechnungsadresse_aufnehmen})}/>
                <InputField name="emailAdresse" type="email" title="Email-Adresse" value={this.state.emailAdresse}
                            onChange={this.handleInputChange}/>
                <InputField name="bucherFirma" type="text" title="Firma" value={this.state.bucherFirma}
                            onChange={this.handleInputChange}/>
                <InputField name="strasse" type="text" title="Straße" value={this.state.bucherRechnungsAdresse.strasse}
                            onChange={this.handleAdressChange}/>
                <Label name="plz" title="PLZ/Ort"/><br/>
                <QuarterInputField name="plz" type="text" title="PLZ" value={this.state.bucherRechnungsAdresse.plz}
                                   onChange={this.handleAdressChange}/>
                <InputFieldWithClassName className="leading-normal border w-3/4" name="ort" type="text" title="Ort"
                                         value={this.state.bucherRechnungsAdresse.ort}
                                         onChange={this.handleAdressChange}/>
                <CountrySelect name="land" title="Land" value={this.state.bucherRechnungsAdresse.land}
                               onChange={this.handleAdressChange}/>
                <InputField name="bestellNummer" type="text" title="Bestellnummer" value={this.state.bestellNummer}
                            onChange={this.handleInputChange}/>
                <InputField name="ustId" type="text" title="Ust-ID" value={this.state.ustId}
                            onChange={this.handleInputChange}/>
                <InputField name="telefon" type="tel" title="Telefon" value={this.state.telefon}
                            onChange={this.handleInputChange}/>
                <InputField name="gutscheinCode" type="text" title="Gutscheincode" value={this.state.gutscheinCode}
                            onChange={this.handleInputChange}/>
                <Label name="preis" title="Preis"/><br/>
                <StatusButton status={{text: "Preis aus Kurs übernehmen"}} active={!this.state.preisEintragen}
                              onClick={() => this.setState({preisEintragen: false})} className="mt-1 mr-2"/>
                <StatusButton status={{text: "Preis für Buchung festlegen"}} active={this.state.preisEintragen}
                              onClick={() => this.setState({preisEintragen: true})} className="mt-1 mr-2"/>
                {this.state.preisEintragen && <div><br/>
                    <PreisBetragField enabled={true} placeholder="Preis" value={this.state.preis.wert}
                                      onChange={val => this.setState({
                                          preis: {
                                              wert: val,
                                              waehrung: this.state.preis.waehrung,
                                          },
                                      })} className="leading-normal border w-1/5"/>
                    <div
                        className="w-1/5 inline-block"><WaehrungField
                        onChange={val => this.setState({
                            preis: {
                                wert: this.state.preis.wert,
                                waehrung: val.waehrung,
                            },
                        })} value={this.state.preis.waehrung} textValue={this.state.preis.waehrung}/></div>
                </div>}
                <br/>
                <StatusButton status={{text: "Bucher nimmt als Teilnehmer teil"}}
                              active={this.state.bucherIsTeilnehmer} className="mt-1"
                              onClick={this.handleBucherIsTeilnehmerClick}/>
                <h3 className="heading">Teilnehmer-Daten</h3>
                {
                    this.state.teilnehmer.map((teilnehmer, index) => <TeilnehmerNeuZeile index={index}
                                                                                         teilnehmer={teilnehmer}
                                                                                         onChange={this.handleTeilnehmerChange}/>)
                }
                <button className="mt-2 btn btn-rot" onClick={this.addTeilnehmer} type="button">Teilnehmer-hinzufügen
                </button>
                <br/>
                <TextareaField name="kommentar" title="Kommentar"
                               value={this.state.kommentar} onChange={this.handleInputChange}/>
                <StatusButton status={{text: "Rechnung per Email"}} active={!this.state.rechnungPerPost}
                              onClick={() => this.setState({rechnungPerPost: false})} className="mt-1 mr-2"/>
                <StatusButton status={{text: "Rechnung per Post"}} active={this.state.rechnungPerPost}
                              onClick={() => this.setState({rechnungPerPost: true})} className="mt-1 mr-2"/>
                <StatusButton status={{text: "Bestätigungsmail versenden"}}
                              active={this.state.bestaetigungsMailVersenden} className="mt-1"
                              onClick={(_, bestaetigungsMailVersenden) => this.setState({bestaetigungsMailVersenden})}/>
                <br/>
                <StatusButton status={{text: "Auch buchen, wenn der Kurs bereits ausgebucht ist"}}
                              active={this.state.buchenWennVoll} className="mt-1"
                              onClick={(_, buchenWennVoll) => this.setState({buchenWennVoll})}/>
                <br/>
                <SubmitButton title="Anlegen" disabled={this.state.submitButtonDisabled}/>
            </form>
            {
                this.state.success && <Redirect to={"/kurstermine/" + this.props.match.params.id + "/buchungen"} push/>
            }
        </div>;
    }

    addTeilnehmer(event) {
        event.preventDefault();
        let teilnehmer = this.state.teilnehmer;
        teilnehmer.push({"anrede": "W", "titel": "", "vorname": "", "nachname": "", "email": ""});
        this.setState({teilnehmer})
    }

    handleBucherIsTeilnehmerClick(_, value) {
        let teilnehmer = this.state.teilnehmer;

        if (value) {
            let neuerTeilnehmer = {
                "anrede": "W",
                "titel": "",
                "vorname": this.state.bucherVorname,
                "nachname": this.state.bucherNachname,
                "email": this.state.emailAdresse,
            }

            if (teilnehmer[0].vorname.trim() === ""
                && teilnehmer[0].nachname.trim() === ""
                && teilnehmer[0].email.trim() === "") {
                teilnehmer[0] = neuerTeilnehmer
            } else {
                teilnehmer.reverse().push(neuerTeilnehmer)
                teilnehmer.reverse()
            }
        } else {
            teilnehmer.reverse().pop()
            teilnehmer.length === 0 && teilnehmer.push({
                "anrede": "W",
                "titel": "",
                "vorname": "",
                "nachname": "",
                "email": "",
            })
            teilnehmer.reverse()
        }
        this.setState({teilnehmer, bucherIsTeilnehmer: value})
    }

    handleAdressChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        let bucherRechnungsAdresse = this.state.bucherRechnungsAdresse
        bucherRechnungsAdresse[name] = value
        this.setState({bucherRechnungsAdresse})
    }

    handleTeilnehmerChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        const parts = name.split('_');
        let idx = parseInt(parts[1], 10);
        let item = parts[2];

        let teilnehmer = this.state.teilnehmer;
        let thisTeilnehmer = teilnehmer[idx];
        thisTeilnehmer[item] = value;
        teilnehmer[idx] = thisTeilnehmer;
        this.setState({teilnehmer});
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        let teilnehmer = this.state.teilnehmer
        if (this.state.bucherIsTeilnehmer && (
            name === "bucherVorname"
            || name === "bucherNachname"
            || name === "emailAdresse")) {
            // wir müssen das entsprechende Teilnehmer-Feld aktualisieren
            let neuerTeilnehmer = this.state.teilnehmer[0]
            switch (name) {
                case "bucherVorname":
                    neuerTeilnehmer.vorname = value
                    break
                case "bucherNachname":
                    neuerTeilnehmer.nachname = value
                    break
                case "emailAdresse":
                    neuerTeilnehmer.email = value
                    break
                // no default
            }
            teilnehmer[0] = neuerTeilnehmer
        }

        this.setState({
            [name]: value,
            teilnehmer,
        });
    }

    async handleSubmit(event) {
        this.setState({submitButtonDisabled: true})
        event.preventDefault();
        if (this.state.bucherVorname === "" || this.state.bucherNachname === "") {
            alert("Bitte einen Vor- und Nachnamen angeben."); // todo: alle Felder abfragen
            this.setState({submitButtonDisabled: false})
            return;
        }
        let error = false
        const teilnehmer = this.state.teilnehmer.reduce((array, item) => {
            if (item.vorname !== "" && item.nachname !== "") {
                if (item.email === "") {
                    alert("Der Teilnehmer " + item.vorname + " " + item.nachname + " hat keine E-Mail-Rechnungsadresse. Bitte angeben.");
                    error = true;
                    return array;
                }
                if (item.titel === "") {
                    item.titel = null
                }
                array.push(new Teilnehmer(item.anrede, item.titel, item.vorname, item.nachname, item.email))
            }
            return array
        }, [])
        if (error) {
            this.setState({submitButtonDisabled: false})
            return
        }
        if (teilnehmer.length === 0) {
            alert("Es muss mindestens ein Teilnehmer angegeben werden")
            this.setState({submitButtonDisabled: false})
            return;
        }
        if (this.state.bucherRechnungsAdresse.land === null || this.state.bucherRechnungsAdresse.land.trim() === "") {
            alert("Bitte ein Land auswählen!")
            return
        }
        const adresse = new Rechnungsadresse(
            this.state.buchernamen_in_Rechnungsadresse_aufnehmen ? this.state.bucherVorname : null,
            this.state.buchernamen_in_Rechnungsadresse_aufnehmen ? this.state.bucherNachname : null,
            this.state.bucherRechnungsAdresse.strasse,
            this.state.bucherRechnungsAdresse.plz,
            this.state.bucherRechnungsAdresse.ort,
            this.state.bucherRechnungsAdresse.land,
        )
        const kursterminId = this.props.match.params.id;
        if (typeof kursterminId !== 'string') {
            alert("Die Kurstermin-ID ist nicht gültig. Bitte Seite neu laden.")
            return;
        }
        let buchungId
        try {
            const identifier = Math.random().toString(36).substring(7)
            let result = await this.context.client.query({
                query: gql`query id($identifier: String!){id(identifier: $identifier)}`,
                variables: {identifier},
                options: {
                    fetchPolicy: "network-only",
                },
            })
            buchungId = result.data.id
        } catch (error) {
            alert("Konnte keine ID generieren: " + error);
            return
        }
        const buchung = new Kurs_soll_gebucht_werden(
            kursterminId,
            this.state.bucherVorname,
            this.state.bucherNachname,
            this.state.bucherFirma !== "" ? this.state.bucherFirma : null,
            adresse,
            this.state.bestellNummer !== "" ? this.state.bestellNummer : null,
            this.state.ustId !== "" ? this.state.ustId : null,
            this.state.telefon !== "" ? this.state.telefon : null,
            this.state.emailAdresse !== "" ? this.state.emailAdresse : null,
            this.state.gutscheinCode !== "" ? this.state.gutscheinCode : null,
            teilnehmer,
            this.state.rechnungPerPost,
            this.state.kommentar,
            false,
            buchungId,
            this.state.buchenWennVoll,
        )
        const message = JSON.stringify(buchung);
        console.log(message);
        try {
            let result = await this.context.client.mutate({
                mutation,
                variables: {message: message},
                refetchQueries: () => [{query: gql`query buchungen($kurs: String){buchungen(kurs: $kurs){bucherVorname,bucherNachname,bucherFirma,storniert,teilnehmer{Id},Id}}`}],
            })
            let success = result.data.Kurs_soll_gebucht_werden === "ok"
            if (!success) {
                alert("Bei der Buchung ist ein Fehler aufgetreten:\n" + result.data.Kurs_soll_gebucht_werden)
                this.setState({
                    success, submitButtonDisabled: false,
                })
                return
            }
            if (this.state.preisEintragen) {
                let message = JSON.stringify(new Kursbuchung_Preis_soll_geaendert_werden(buchungId, {
                    wert: this.state.preis.wert,
                    waehrung: this.state.preis.waehrung,
                }))
                let result = await this.context.client.mutate({
                    mutation: gql`mutation x($message: String!){Kursbuchung_Preis_soll_geaendert_werden(message: $message)}`,
                    variables: {message},
                })
                success = result.data.Kursbuchung_Preis_soll_geaendert_werden === "ok"
                if (!success) {
                    alert("Beim Festsetzen des Preises ist ein Fehler aufgetreten:\n" + result.data.Kursbuchung_Preis_soll_geaendert_werden)
                    this.setState({
                        success, submitButtonDisabled: false,
                    })
                    return
                }
            }
            if (this.state.bestaetigungsMailVersenden) {
                let message = JSON.stringify(new Bestaetigungsemail_soll_versendet_werden(buchungId))
                let result = await this.context.client.mutate({
                    mutation: gql`mutation y($message: String!){Bestaetigungsemail_soll_versendet_werden(message: $message)}`,
                    variables: {message},
                })
                success = result.data.Bestaetigungsemail_soll_versendet_werden === "ok"
                if (!success) {
                    alert("Beim Versand der Bestätigungsmail ist ein Fehler aufgetreten:\n" + result.data.Bestaetigungsemail_soll_versendet_werden)
                }
            }
            this.setState({
                success, submitButtonDisabled: false,
            })
        } catch (error) {
            alert("submitting failed: " + error);
        }
    }
}

export default BuchungAnlegen;