import * as React from "react"
import PropTypes from "prop-types"

export class KurstagCreator extends React.Component {
    static contextTypes = {client: PropTypes.any};

    static propTypes = {
        onSave: PropTypes.func.isRequired,
        onCancel: PropTypes.func.isRequired,
        existing: PropTypes.arrayOf(PropTypes.shape({
            Id: PropTypes.string.isRequired,
            tag: PropTypes.number.isRequired,
        })).isRequired,
    }

    constructor(props, context) {
        super(props, context);

        let offset = 1;
        for(let tag of props.existing) {
          console.log(tag);
          if (tag.tag===offset) offset = tag.tag+1; else {
            break; // So dass Luecken, wo gerade evtl ein Tage geloescht wurde, leicht aufgefuellt werden koennen
          }
        }

        this.state = {
            beginn: "09:00:00",
            ende: "17:00:00",
          offset: offset
        };
    }

    handleKeyUp(event) {
        if (event.key === 'Escape') {
            this.props.onCancel();
            event.preventDefault();
        }
    }

    handleChange(event) {
        this.setState({
            [event.target.name]: event.target.value,
        });
    }

    handleSaveClick() {
        let {beginn, ende, offset} = this.state
        beginn = beginn.trim()
        ende = ende.trim()

        if (beginn === "" || ende === "") {
            alert("Bitte eine Beginn- und Enduhrzeit angeben")
            return
        }

        if (/^\d\d:\d\d$/.test(beginn)) {
            beginn = beginn + ":00"
        }
        if (/^\d\d:\d\d$/.test(ende)) {
            ende = ende + ":00"
        }

        this.props.onSave({beginn, ende, offset:parseInt(offset, 10)-1})
    }

    render() {
        const {beginn, ende, offset} = this.state

        return <div>
            <input type="time" step="1800" name="beginn" value={beginn} onKeyUp={(e) => this.handleKeyUp(e)}
                   onChange={(e) => this.handleChange(e)}/>&ndash;
            <input type="time" step="1800" name="ende" value={ende} onKeyUp={(e) => this.handleKeyUp(e)}
                   onChange={(event) => this.handleChange(event)}/>&nbsp;
            Tag #
            <input className="w-8" type="text" name="offset" onChange={(e) => this.handleChange(e)} value={offset} />
            <button className="text-dst-logo-gruen-mitte hover:text-dst-logo-gruen-hinten underline"
                    onClick={() => this.handleSaveClick()}>Hinzufügen
            </button>
        </div>;
    }
}
