import * as React from "react"
import PropTypes from "prop-types"
import {PreisstaffelCreator} from "./PreisstaffelCreator"

export class EintragListe extends React.Component {
    static propTypes = {
        eintraege: PropTypes.arrayOf(PropTypes.shape(
            {
                mindestteilnehmer: PropTypes.number.isRequired,
                rabatt: PropTypes.number,
                kostenfreieTeilnehmer: PropTypes.number,
                kombinierbar: PropTypes.bool.isRequired,
            })).isRequired,
        addRabatt: PropTypes.func.isRequired,
        removeRabatt: PropTypes.func.isRequired,
    }

    state = {
        editing: false,
        saving: false,
    }

    async addToList(rabatt) {
        this.setState({editing: false, saving: true});
        this.props.addRabatt(rabatt).then(() => this.setState({editing: false, saving: false}));
    }

    async removeRabatt(mindestteilnehmer) {
        this.setState({editing: false, saving: true});
        this.props.removeRabatt(mindestteilnehmer).then(() => this.setState({editing: false, saving: false}));
    }


    renderEditor() {
        return <PreisstaffelCreator onSave={(rabatt) => this.addToList(rabatt)}
                                    onCancel={() => this.setState({editing: false})}/>;
    }

    renderAddButton() {
        return <button className="text-dst-logo-gruen-mitte hover:text-dst-logo-gruen-hinten underline"
                       onClick={() => this.setState({editing: true})}>Rabatt hinzufügen...</button>;
    }

    renderItem(rabatt, index) {
        let text = ""
        if (rabatt.rabatt) {
            text = rabatt.rabatt + "%"
        } else {
            text = rabatt.kostenfreieTeilnehmer + " kostenfrei"
        }
        return <div key={index}><span>{rabatt.mindestteilnehmer} Teilnehmer: {text} (kombinierbar: {rabatt.kombinierbar ? "ja" : "nein"})</span>
            <button className="text-dst-logo-gruen-mitte hover:text-dst-logo-gruen-hinten underline"
                    onClick={() => this.removeRabatt(rabatt.mindestteilnehmer)}>entfernen
            </button>
        </div>;
    }


    render() {
        let {eintraege} = this.props
        eintraege.sort((a, b) => a.mindestteilnehmer - b.mindestteilnehmer)
        return <td>
            {eintraege.map((eintrag, index) => this.renderItem(eintrag, index))}
            {this.state.saving ? "wird gespeichert..." : this.state.editing ? this.renderEditor() : this.renderAddButton()}
        </td>
    }
}