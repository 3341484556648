
export function textValueFromPreis(preis) {
    if (preis === null) {
        return ""
    }
    let val = numberValueFromPreis(preis)
    return textValueFromFloat(val)
}

export function textValueFromFloat(val) {
    return val.toLocaleString('de-CH', {
        style: "decimal",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    })
}

export function numberValueFromPreis(preis) {
    if (preis === null) {
        return NaN
    }
    let val = preis.wert
    if (typeof val === 'string' || val instanceof String) {
        val = val.replace(",", ".")
        val = parseFloat(val)
    }
    return val
}