import * as React from "react"
import PropTypes from "prop-types"
import Modal from "./Modal"
import * as ReactDOM from "react-dom"

export class Confirm extends React.Component {
    static defaultProps = {
        'confirmLabel': 'OK',
        'abortLabel': 'Cancel',
    }

    static propTypes = {
        'confirmLabel': PropTypes.string,
        'abortLabel': PropTypes.string,
        'description': PropTypes.string,
        'message': PropTypes.string.isRequired,
    }

    constructor(props, context) {
        super(props, context)

        this.promise = new Promise((resolve, reject) => {
            this.abort = () => {
                reject()
            }
            this.confirm = () => {
                resolve()
            }
        })

        this.onKeyUp = this.onKeyUp.bind(this)
    }

    onKeyUp(event) {
        if (event.key === 'Escape') {
            this.abort()
            event.preventDefault()
        }
    }

    componentDidMount() {
        ReactDOM.findDOMNode(this.refs.confirm).focus()
    }

    render() {
        return <Modal onKeyUp={this.onKeyUp}>
            <div className="modal-header p-8 pb-4 border-b border-grey-light">
                <h4 className="modal-title">{this.props.message}</h4>
            </div>
            {this.props.description &&
            <div className="modal-body p-8 pt-4 pb-4">
                {this.props.description}
            </div>
            }
            <div className="modal-footer border-t border-grey-light p-8 pr-4 pt-4 pb-4">
                <div className="text-right">
                    {this.props.abortLabel !== "" &&
                    <button role="abort" type="button" className="btn btn-rot" // eslint-disable-line jsx-a11y/aria-role
                            onClick={this.abort}>{this.props.abortLabel}</button>}
                    &nbsp;
                    <button role="confirm" type="button" // eslint-disable-line jsx-a11y/aria-role
                            className="btn btn-gruen" ref="confirm"
                            onClick={this.confirm}>{this.props.confirmLabel}</button>
                </div>
            </div>
        </Modal>
    }
}

export default async (message, options = {}) => {
    let props = {...options, message: message}
    let wrapper = document.body.appendChild(document.createElement('div'))
    let component = ReactDOM.render(React.createElement(Confirm, props), wrapper)
    let cleanup = () => {
        if (ReactDOM.unmountComponentAtNode(wrapper)) {
            wrapper.remove()
        }
    }
    return component.promise.finally(cleanup)
}