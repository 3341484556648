import * as React from "react";
import {Editor} from "./Editor";
import {EditorField} from "./EditorField";
import {EditorControls} from "./EditorControls";
import PropTypes from "prop-types";

export class InlineEditor extends React.Component {
    static propTypes = {
        component: PropTypes.any.isRequired,
        value: PropTypes.any.isRequired,
        save: PropTypes.func.isRequired,
        validate: PropTypes.func,
        refetch: PropTypes.func,
        placeholder: PropTypes.string,
        tabIndex: PropTypes.number,
        options: PropTypes.arrayOf(PropTypes.shape({
            title: PropTypes.string.isRequired,
            value: PropTypes.string.isRequired,
        })),
    };

    render() {
        return (<Editor
            value={this.props.value}
            save={({value}) => this.props.save(value)}
            validate={this.props.validate}
            refetch={this.props.refetch}
        >
            <EditorField component={this.props.component} className={this.props.className} valuekey="value"
                         placeholder={this.props.placeholder} tabIndex={this.props.tabIndex}
                         options={this.props.options}/>
            <EditorControls/>
        </Editor>);
    }
}