import * as React from "react"
import PropTypes from "prop-types"


export const KurstagDisplay = ({kurstag}) => <span>{kurstag.beginn}–{kurstag.ende}</span>;

KurstagDisplay.propTypes =
    {
        kurstag: PropTypes.shape(
            {
                Id: PropTypes.string.isRequired,
                offset: PropTypes.number.isRequired,
                beginn: PropTypes.string.isRequired,
                ende: PropTypes.string.isRequired,
            }).isRequired,
    };
