/*gen*/class Kontakt_Name_soll_korrigiert_werden {
/*gen*/    static messageName = "Kontakt_Name_soll_korrigiert_werden"
/*gen*/	kontakt_Id
/*gen*/	anrede
/*gen*/	titel
/*gen*/	nachname
/*gen*/	vornamen
/*gen*/	constructor(kontakt_Id, anrede, titel, nachname, vornamen) {
/*gen*/		
/*gen*/			
/*gen*/			this.kontakt_Id = kontakt_Id
/*gen*/		
/*gen*/			
/*gen*/			this.anrede = anrede
/*gen*/		
/*gen*/			
/*gen*/			this.titel = titel
/*gen*/		
/*gen*/			
/*gen*/			this.nachname = nachname
/*gen*/		
/*gen*/			
/*gen*/			this.vornamen = vornamen
/*gen*/		
/*gen*/	}
/*gen*/}
/*gen*/class Buchung_Verwaltungskontakt_Firma_soll_hinterlegt_werden {
/*gen*/    static messageName = "Buchung_Verwaltungskontakt_Firma_soll_hinterlegt_werden"
/*gen*/	kursbuchung_Id
/*gen*/	firmenname
/*gen*/	constructor(kursbuchung_Id, firmenname) {
/*gen*/		
/*gen*/			
/*gen*/			this.kursbuchung_Id = kursbuchung_Id
/*gen*/		
/*gen*/			
/*gen*/			this.firmenname = firmenname
/*gen*/		
/*gen*/	}
/*gen*/}
/*gen*/class Kontakt_Email_hinterlegen {
/*gen*/    static messageName = "Kontakt_Email_hinterlegen"
/*gen*/	kontakt_Id
/*gen*/	email
/*gen*/	constructor(kontakt_Id, email) {
/*gen*/		
/*gen*/			
/*gen*/			this.kontakt_Id = kontakt_Id
/*gen*/		
/*gen*/			
/*gen*/			this.email = email
/*gen*/		
/*gen*/	}
/*gen*/}
/*gen*/class Kontakt_Telefonnummer_hinterlegen {
/*gen*/    static messageName = "Kontakt_Telefonnummer_hinterlegen"
/*gen*/	kontakt_Id
/*gen*/	telefonnummer
/*gen*/	constructor(kontakt_Id, telefonnummer) {
/*gen*/		
/*gen*/			
/*gen*/			this.kontakt_Id = kontakt_Id
/*gen*/		
/*gen*/			
/*gen*/			this.telefonnummer = telefonnummer
/*gen*/		
/*gen*/	}
/*gen*/}
/*gen*/class Kursbuchung_Rechnungsanschrift_soll_geaendert_werden {
/*gen*/    static messageName = "Kursbuchung_Rechnungsanschrift_soll_geaendert_werden"
/*gen*/	kursbuchung_Id
/*gen*/	firmenname
/*gen*/	vorname
/*gen*/	nachname
/*gen*/	abteilung
/*gen*/	strasse
/*gen*/	plz
/*gen*/	ort
/*gen*/	land
/*gen*/	rechnung_per_Post
/*gen*/	constructor(kursbuchung_Id, firmenname, vorname, nachname, abteilung, strasse, plz, ort, land, rechnung_per_Post) {
/*gen*/		
/*gen*/			
/*gen*/			this.kursbuchung_Id = kursbuchung_Id
/*gen*/		
/*gen*/			
/*gen*/			this.firmenname = firmenname
/*gen*/		
/*gen*/			
/*gen*/			this.vorname = vorname
/*gen*/		
/*gen*/			
/*gen*/			this.nachname = nachname
/*gen*/		
/*gen*/			
/*gen*/			this.abteilung = abteilung
/*gen*/		
/*gen*/			
/*gen*/			this.strasse = strasse
/*gen*/		
/*gen*/			
/*gen*/			this.plz = plz
/*gen*/		
/*gen*/			
/*gen*/			this.ort = ort
/*gen*/		
/*gen*/			
/*gen*/			this.land = land
/*gen*/		
/*gen*/			
/*gen*/			this.rechnung_per_Post = rechnung_per_Post
/*gen*/		
/*gen*/	}
/*gen*/}
/*gen*/class Kursbuchung_Bestellnummer_hinterlegen {
/*gen*/    static messageName = "Kursbuchung_Bestellnummer_hinterlegen"
/*gen*/	kursbuchung_Id
/*gen*/	bestellnummer
/*gen*/	constructor(kursbuchung_Id, bestellnummer) {
/*gen*/		
/*gen*/			
/*gen*/			this.kursbuchung_Id = kursbuchung_Id
/*gen*/		
/*gen*/			
/*gen*/			this.bestellnummer = bestellnummer
/*gen*/		
/*gen*/	}
/*gen*/}
/*gen*/class Kursbuchung_Ust_Id_hinterlegen {
/*gen*/    static messageName = "Kursbuchung_Ust_Id_hinterlegen"
/*gen*/	kursbuchung_Id
/*gen*/	uSt_Id
/*gen*/	constructor(kursbuchung_Id, uSt_Id) {
/*gen*/		
/*gen*/			
/*gen*/			this.kursbuchung_Id = kursbuchung_Id
/*gen*/		
/*gen*/			
/*gen*/			this.uSt_Id = uSt_Id
/*gen*/		
/*gen*/	}
/*gen*/}
/*gen*/class Kursbuchung_Gutscheincode_soll_hinterlegt_werden {
/*gen*/    static messageName = "Kursbuchung_Gutscheincode_soll_hinterlegt_werden"
/*gen*/	kursbuchung_Id
/*gen*/	gutscheincode
/*gen*/	constructor(kursbuchung_Id, gutscheincode) {
/*gen*/		
/*gen*/			
/*gen*/			this.kursbuchung_Id = kursbuchung_Id
/*gen*/		
/*gen*/			
/*gen*/			this.gutscheincode = gutscheincode
/*gen*/		
/*gen*/	}
/*gen*/}
/*gen*/class Kursbuchung_Preis_soll_geaendert_werden {
/*gen*/    static messageName = "Kursbuchung_Preis_soll_geaendert_werden"
/*gen*/	kursbuchung_Id
/*gen*/	preis
/*gen*/	constructor(kursbuchung_Id, preis) {
/*gen*/		
/*gen*/			
/*gen*/			this.kursbuchung_Id = kursbuchung_Id
/*gen*/		
/*gen*/			
/*gen*/			this.preis = preis
/*gen*/		
/*gen*/	}
/*gen*/}
/*gen*/class Kursbuchung_Kommentar_aendern {
/*gen*/    static messageName = "Kursbuchung_Kommentar_aendern"
/*gen*/	kursbuchung_Id
/*gen*/	kommentar
/*gen*/	constructor(kursbuchung_Id, kommentar) {
/*gen*/		
/*gen*/			
/*gen*/			this.kursbuchung_Id = kursbuchung_Id
/*gen*/		
/*gen*/			
/*gen*/			this.kommentar = kommentar
/*gen*/		
/*gen*/	}
/*gen*/}
/*gen*/class Kursbuchung_soll_verschoben_werden {
/*gen*/    static messageName = "Kursbuchung_soll_verschoben_werden"
/*gen*/	kursbuchung_Id
/*gen*/	alte_Kurstermin_Id
/*gen*/	kurstermin_Id
/*gen*/	bestaetigungsmail_senden
/*gen*/	preis_uebernehmen
/*gen*/	constructor(kursbuchung_Id, alte_Kurstermin_Id, kurstermin_Id, bestaetigungsmail_senden, preis_uebernehmen) {
/*gen*/		
/*gen*/			
/*gen*/			this.kursbuchung_Id = kursbuchung_Id
/*gen*/		
/*gen*/			
/*gen*/			this.alte_Kurstermin_Id = alte_Kurstermin_Id
/*gen*/		
/*gen*/			
/*gen*/			this.kurstermin_Id = kurstermin_Id
/*gen*/		
/*gen*/			
/*gen*/			this.bestaetigungsmail_senden = bestaetigungsmail_senden
/*gen*/		
/*gen*/			
/*gen*/			this.preis_uebernehmen = preis_uebernehmen
/*gen*/		
/*gen*/	}
/*gen*/}
/*gen*/class Kursbuchung_Teilnahme_soll_bestaetigt_werden {
/*gen*/    static messageName = "Kursbuchung_Teilnahme_soll_bestaetigt_werden"
/*gen*/	kursbuchung_Id
/*gen*/	teilnahme_Id
/*gen*/	kurstermin_Id
/*gen*/	constructor(kursbuchung_Id, teilnahme_Id, kurstermin_Id) {
/*gen*/		
/*gen*/			
/*gen*/			this.kursbuchung_Id = kursbuchung_Id
/*gen*/		
/*gen*/			
/*gen*/			this.teilnahme_Id = teilnahme_Id
/*gen*/		
/*gen*/			
/*gen*/			this.kurstermin_Id = kurstermin_Id
/*gen*/		
/*gen*/	}
/*gen*/}
/*gen*/class Kontakt_wird_Newsletterempfang_zustimmen {
/*gen*/    static messageName = "Kontakt_wird_Newsletterempfang_zustimmen"
/*gen*/	kontakt_Id
/*gen*/	constructor(kontakt_Id) {
/*gen*/		
/*gen*/			
/*gen*/			this.kontakt_Id = kontakt_Id
/*gen*/		
/*gen*/	}
/*gen*/}
/*gen*/class Kontakt_wird_Zustimmung_zum_Newsletterempfang_widerrufen {
/*gen*/    static messageName = "Kontakt_wird_Zustimmung_zum_Newsletterempfang_widerrufen"
/*gen*/	kontakt_Id
/*gen*/	constructor(kontakt_Id) {
/*gen*/		
/*gen*/			
/*gen*/			this.kontakt_Id = kontakt_Id
/*gen*/		
/*gen*/	}
/*gen*/}
/*gen*/class Kurs_soll_gebucht_werden {
/*gen*/    static messageName = "Kurs_soll_gebucht_werden"
/*gen*/	kursId
/*gen*/	bucherVorname
/*gen*/	bucherNachname
/*gen*/	bucherFirma
/*gen*/	bucherRechnungsAdresse
/*gen*/	bestellNummer
/*gen*/	ustId
/*gen*/	telefon
/*gen*/	emailAdresse
/*gen*/	gutscheinCode
/*gen*/	teilnehmer
/*gen*/	rechnungPerPost
/*gen*/	kommentar
/*gen*/	bestaetigungsemail_soll_versendet_werden
/*gen*/	kursbuchung_Id
/*gen*/	buchen_wenn_voll
/*gen*/	constructor(kursId, bucherVorname, bucherNachname, bucherFirma, bucherRechnungsAdresse, bestellNummer, ustId, telefon, emailAdresse, gutscheinCode, teilnehmer, rechnungPerPost, kommentar, bestaetigungsemail_soll_versendet_werden, kursbuchung_Id, buchen_wenn_voll) {
/*gen*/		
/*gen*/			
/*gen*/			this.kursId = kursId
/*gen*/		
/*gen*/			
/*gen*/			this.bucherVorname = bucherVorname
/*gen*/		
/*gen*/			
/*gen*/			this.bucherNachname = bucherNachname
/*gen*/		
/*gen*/			
/*gen*/			this.bucherFirma = bucherFirma
/*gen*/		
/*gen*/			
/*gen*/			this.bucherRechnungsAdresse = bucherRechnungsAdresse
/*gen*/		
/*gen*/			
/*gen*/			this.bestellNummer = bestellNummer
/*gen*/		
/*gen*/			
/*gen*/			this.ustId = ustId
/*gen*/		
/*gen*/			
/*gen*/			this.telefon = telefon
/*gen*/		
/*gen*/			
/*gen*/			this.emailAdresse = emailAdresse
/*gen*/		
/*gen*/			
/*gen*/			this.gutscheinCode = gutscheinCode
/*gen*/		
/*gen*/			
/*gen*/			this.teilnehmer = teilnehmer
/*gen*/		
/*gen*/			
/*gen*/			this.rechnungPerPost = rechnungPerPost
/*gen*/		
/*gen*/			
/*gen*/			this.kommentar = kommentar
/*gen*/		
/*gen*/			
/*gen*/			this.bestaetigungsemail_soll_versendet_werden = bestaetigungsemail_soll_versendet_werden
/*gen*/		
/*gen*/			
/*gen*/			this.kursbuchung_Id = kursbuchung_Id
/*gen*/		
/*gen*/			
/*gen*/			this.buchen_wenn_voll = buchen_wenn_voll
/*gen*/		
/*gen*/	}
/*gen*/}
/*gen*/class Kursbuchung_Teilnehmer_soll_hinzugefuegt_werden {
/*gen*/    static messageName = "Kursbuchung_Teilnehmer_soll_hinzugefuegt_werden"
/*gen*/	kursbuchung_Id
/*gen*/	anrede
/*gen*/	titel
/*gen*/	vorname
/*gen*/	nachname
/*gen*/	email
/*gen*/	preis_aktualisieren
/*gen*/	constructor(kursbuchung_Id, anrede, titel, vorname, nachname, email, preis_aktualisieren) {
/*gen*/		
/*gen*/			
/*gen*/			this.kursbuchung_Id = kursbuchung_Id
/*gen*/		
/*gen*/			
/*gen*/			this.anrede = anrede
/*gen*/		
/*gen*/			
/*gen*/			this.titel = titel
/*gen*/		
/*gen*/			
/*gen*/			this.vorname = vorname
/*gen*/		
/*gen*/			
/*gen*/			this.nachname = nachname
/*gen*/		
/*gen*/			
/*gen*/			this.email = email
/*gen*/		
/*gen*/			
/*gen*/			this.preis_aktualisieren = preis_aktualisieren
/*gen*/		
/*gen*/	}
/*gen*/}
/*gen*/class Buchung_soll_storniert_werden {
/*gen*/    static messageName = "Buchung_soll_storniert_werden"
/*gen*/	buchung_Id
/*gen*/	constructor(buchung_Id) {
/*gen*/		
/*gen*/			
/*gen*/			this.buchung_Id = buchung_Id
/*gen*/		
/*gen*/	}
/*gen*/}
/*gen*/class Teilnehmer_soll_storniert_werden {
/*gen*/    static messageName = "Teilnehmer_soll_storniert_werden"
/*gen*/	teilnehmer_Id
/*gen*/	buchung_Id
/*gen*/	preis_aktualisieren
/*gen*/	constructor(teilnehmer_Id, buchung_Id, preis_aktualisieren) {
/*gen*/		
/*gen*/			
/*gen*/			this.teilnehmer_Id = teilnehmer_Id
/*gen*/		
/*gen*/			
/*gen*/			this.buchung_Id = buchung_Id
/*gen*/		
/*gen*/			
/*gen*/			this.preis_aktualisieren = preis_aktualisieren
/*gen*/		
/*gen*/	}
/*gen*/}
/*gen*/class Rechungsstellung_soll_eingetragen_werden {
/*gen*/    static messageName = "Rechungsstellung_soll_eingetragen_werden"
/*gen*/	buchung_Id
/*gen*/	rechnungsnummer
/*gen*/	constructor(buchung_Id, rechnungsnummer) {
/*gen*/		
/*gen*/			
/*gen*/			this.buchung_Id = buchung_Id
/*gen*/		
/*gen*/			
/*gen*/			this.rechnungsnummer = rechnungsnummer
/*gen*/		
/*gen*/	}
/*gen*/}
/*gen*/class Bestaetigungsemail_soll_versendet_werden {
/*gen*/    static messageName = "Bestaetigungsemail_soll_versendet_werden"
/*gen*/	buchung_Id
/*gen*/	constructor(buchung_Id) {
/*gen*/		
/*gen*/			
/*gen*/			this.buchung_Id = buchung_Id
/*gen*/		
/*gen*/	}
/*gen*/}
/*gen*/class Kontakt_ActiveCampaign_Id_soll_entfernt_werden {
/*gen*/    static messageName = "Kontakt_ActiveCampaign_Id_soll_entfernt_werden"
/*gen*/	kontakt_Id
/*gen*/	constructor(kontakt_Id) {
/*gen*/		
/*gen*/			
/*gen*/			this.kontakt_Id = kontakt_Id
/*gen*/		
/*gen*/	}
/*gen*/}
/*gen*/class Kontakt_ActiveCampaign_Sync_anfordern {
/*gen*/    static messageName = "Kontakt_ActiveCampaign_Sync_anfordern"
/*gen*/	kontakt_Id
/*gen*/	constructor(kontakt_Id) {
/*gen*/		
/*gen*/			
/*gen*/			this.kontakt_Id = kontakt_Id
/*gen*/		
/*gen*/	}
/*gen*/}
/*gen*/class Kontakt_ActiveCampaign_Email_soll_ausgeschlossen_werden {
/*gen*/    static messageName = "Kontakt_ActiveCampaign_Email_soll_ausgeschlossen_werden"
/*gen*/	kontakt_Id
/*gen*/	email
/*gen*/	constructor(kontakt_Id, email) {
/*gen*/		
/*gen*/			
/*gen*/			this.kontakt_Id = kontakt_Id
/*gen*/		
/*gen*/			
/*gen*/			this.email = email
/*gen*/		
/*gen*/	}
/*gen*/}
/*gen*/export {
/*gen*/    Kontakt_Name_soll_korrigiert_werden,
/*gen*/    Buchung_Verwaltungskontakt_Firma_soll_hinterlegt_werden,
/*gen*/    Kontakt_Email_hinterlegen,
/*gen*/    Kontakt_Telefonnummer_hinterlegen,
/*gen*/    Kursbuchung_Rechnungsanschrift_soll_geaendert_werden,
/*gen*/    Kursbuchung_Bestellnummer_hinterlegen,
/*gen*/    Kursbuchung_Ust_Id_hinterlegen,
/*gen*/    Kursbuchung_Gutscheincode_soll_hinterlegt_werden,
/*gen*/    Kursbuchung_Preis_soll_geaendert_werden,
/*gen*/    Kursbuchung_Kommentar_aendern,
/*gen*/    Kursbuchung_soll_verschoben_werden,
/*gen*/    Kursbuchung_Teilnahme_soll_bestaetigt_werden,
/*gen*/    Kontakt_wird_Newsletterempfang_zustimmen,
/*gen*/    Kontakt_wird_Zustimmung_zum_Newsletterempfang_widerrufen,
/*gen*/    Kurs_soll_gebucht_werden,
/*gen*/    Kursbuchung_Teilnehmer_soll_hinzugefuegt_werden,
/*gen*/    Buchung_soll_storniert_werden,
/*gen*/    Teilnehmer_soll_storniert_werden,
/*gen*/    Rechungsstellung_soll_eingetragen_werden,
/*gen*/    Bestaetigungsemail_soll_versendet_werden,
/*gen*/    Kontakt_ActiveCampaign_Id_soll_entfernt_werden,
/*gen*/    Kontakt_ActiveCampaign_Sync_anfordern,
/*gen*/    Kontakt_ActiveCampaign_Email_soll_ausgeschlossen_werden,
/*gen*/}