import * as React from "react"
import gql from "graphql-tag"
import {graphql} from "react-apollo/index"
import {Link} from "react-router-dom"
import * as ReactDOM from "react-dom"
import * as Reactable from "reactable"
import select from "./UI/ModalSelect"
import {toCSV} from "react-csv/lib/core"
import confirmation from "./UI/Confirm"
import moment from "moment"
import 'moment/locale/de'
import {LinkButton, StatusButton} from "./components"
import {Kontakt_Email_hinterlegen, Teilnehmer_soll_storniert_werden} from "./Daten/CheckinMessages.gen"
import PropTypes from "prop-types"
import {InlineEditor} from "./UI/Editors/InlineEditor"
import {NoBlurTextField} from "./UI/Editors/TextField"
import {TeilnahmeBestätigen} from "./Teilnahme/TeilnahmeBestätigen"
import {NewsletterSetter} from "./Teilnahme/NewsletterSetter"

const Table = Reactable.Table
const Tr = Reactable.Tr
const Td = Reactable.Td
const Thead = Reactable.Thead
const Th = Reactable.Th
const CaseInsensitive = Reactable.Sort.CaseInsensitive

class Teilnehmerliste extends React.Component {
    static contextTypes = {client: PropTypes.any};

    state = {
        filter: "",
        directMail: {
            csvData: [],
            download: false,
        },
        pdfDownload: false,
        showStorno: true,
    }

    constructor(props, context) {
        super(props, context)

        this.filterTable = this.filterTable.bind(this)
    }

    componentDidMount() {
        const el = ReactDOM.findDOMNode(this)
        window.scrollTo(0, el.offsetTop)
    }

    async createScrumAllianceCsvData() {
        let teilnehmer = this.props.data.teilnehmer
        let objs = []
        for (let tn of teilnehmer) {
            if (!!tn.storniert || !tn.bestaetigt) {
                continue
            }
            let obj = [
                tn.nachname,
                ((tn.titel ? tn.titel : "") + " " + tn.vorname).trim(),
                tn.email,
            ]
            objs.push(obj)
        }
        if (objs.length === 0) {
            alert("Es gibt keine Teilnehmer. Wurden die Teilnehmer bestätigt?")
            return
        }
        let csv = toCSV(objs, null, ",", "")
        try {
            await Teilnehmerliste.copyTextToClipboard(csv)
            alert("Teilnehmerliste erfolgreich ins Clipboard kopiert.")
        } catch (error) {
            alert("Text konnte nicht kopiert werden.")
        }
    }

    static fallbackCopyTextToClipboard(text) {
        let textArea = document.createElement("textarea");
        textArea.value = text;
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();

        let successful = document.execCommand('copy');
        document.body.removeChild(textArea);
        let msg = successful ? 'successful' : 'unsuccessful';
        if (msg === 'unsuccessful') {
            throw Error("copy unsuccessful")
        }

    }

    static async copyTextToClipboard(text) {
        if (!navigator.clipboard) {
            Teilnehmerliste.fallbackCopyTextToClipboard(text);
            return;
        }
        await navigator.clipboard.writeText(text)
    }

    async createCsvData() {
        let kurs = this.props.kurs, teilnehmer = this.props.data.teilnehmer
        if (kurs.kurstage.length === 0) {
            try {
                await confirmation("Es gibt noch keine Kurszeiten. Diese werden für den Teilnehmerlistenexport nach DirectMail benötigt.", {
                    abortLabel: "",
                    confirmLabel: "Okay",
                })
                return;
            } catch (error) {
                // noinspection UnnecessaryReturnStatementJS
                return;
            }
        }
        let kurszeiten = kurs.kurstage.map(kurstag => "Tag " + (kurstag.offset + 1) + ": " + kurstag.beginn + "–" + kurstag.ende + " Uhr").reduce((s, e) => s + "\n" + e, "").trim()
        let trainer = "", trainer2 = ""
        try {
            let trainers = kurs.trainer.map((trainer) => {
                return {label: trainer.vornamen + " " + trainer.nachname}
            })
            if (trainers.length === 1) {
                trainer = trainers[0].label
            } else {
                trainer = await select("Bitte Chef-Trainer für Teilnehmerliste auswählen.", {
                    options: trainers,
                    abortLabel: "Abbrechen",
                })
                trainers = trainers.filter(item => item.label !== trainer)
                if (trainers.length === 1) {
                    trainer2 = trainers[0].label
                } else {
                    trainer2 = await select("Bitte zweiten Trainer für Teilnehmerliste auswählen.", {
                        options: trainers,
                        abortLabel: "Abbrechen",
                    })
                }

            }
        } catch (error) {
            // noinspection UnnecessaryReturnStatementJS
            return;
        }
        let objs = []
        let location = "", googleMapsUrl = ""
        if (kurs.location) {
            location = kurs.location.name + ", " + kurs.location.vollstaendigeAdresse.replace(/(\r|\n|\r\n)/, ", ")
            googleMapsUrl = kurs.location.googleMapsUrl ? kurs.location.googleMapsUrl : ""
        }
        moment.locale('de')
        let ende = moment(kurs.beginn.isoString).add(kurs.dauer - 1, "days").format("L")
        for (let tn of teilnehmer) {
            if (!!tn.storniert) {
                continue
            }
            let anrede = ""
            if (tn.anrede) {
                if (tn.anrede.code !== "D") {
                    anrede = tn.anrede.text
                }
            }
            let obj = {
                Anrede: anrede,
                Titel: tn.titel,
                Vorname: tn.vorname,
                Nachname: tn.nachname,
                Email: tn.email,
                Kurscode: kurs.kursCode,
                Beginn: kurs.beginn.humanReadable,
                Ende: ende,
                Kontaktstunden: kurs.kontaktstunden,
                Trainer: trainer,
                "Trainer 2": trainer2,
                Kurstyp: kurs.typ.name,
                Location: location,
                Region: kurs.region.name,
                "Google Maps Link": googleMapsUrl,
                Kurszeiten: kurszeiten,
            }
            objs.push(obj)
        }
        let csv = toCSV(objs, null, ";", "\"")
        let element = document.createElement("a")
        const file = new Blob([csv], {type: 'text/csv'})
        element.href = URL.createObjectURL(file);
        element.download = this.props.kurs.kursCode + "-teilnehmer.csv";
        element.click();
    }

    async downloadPdf(type = "teilnehmerliste") {
        this.setState({
            pdfDownload: true,
        })

        let query
        if (type === "teilnehmerliste") {
            query = gql`query teilnehmerliste($Id: String!){kurstermine(Id: $Id){ teilnehmer { teilnehmerliste } } }`
        } else {
            query = gql`query teilnehmerliste($Id: String!){kurstermine(Id: $Id){ teilnehmer { teilnehmeretiketten } } }`
        }
        try {
            let result = await this.context.client.query({
                query,
                variables: {Id: this.props.kurs.Id},
                fetchPolicy: "network-only",
            })

            const data = result.data.kurstermine[0].teilnehmer[type]
            if (data === null) {
                alert("PDF konnte nicht erstellt werden")
                this.setState({
                    pdfDownload: false,
                })
                return
            }

            const linkSource = `data:application/pdf;base64,${data}`;
            const downloadLink = document.createElement("a");
            const fileName = `${type}_${this.props.kurs.kursCode}.pdf`;

            downloadLink.href = linkSource;
            downloadLink.download = fileName;
            downloadLink.click();

            this.setState({
                pdfDownload: false,
            })
        } catch (error) {
            alert("Konnte das PDF nicht herunterladen")
            this.setState({
                pdfDownload: false,
            })
        }
    }

    filterTable(event) {
        const target = event.target;
        let value = target.value;

        this.setState({
            filter: value,
        });
    }

    render() {
        let {teilnehmer} = this.props.data;
        if (teilnehmer && !this.state.showStorno) {
            teilnehmer = teilnehmer.filter(tn => !tn.storniert)
        }
        return <div className="teilnehmeren-container">
            <h2 className="heading">Teilnehmer</h2>
            <input type="text" className="w-full border h-8 p-2" value={this.state.filter} autoFocus={true}
                   placeholder="Zum Filtern tippen..." onChange={this.filterTable} tabIndex={1}/><br/>
            <div className="flex justify-between mt-2 pr-2">
                <StatusButton onClick={() => this.setState({showStorno: !this.state.showStorno})}
                              active={this.state.showStorno} status={{text: "Stornierte Teilnahmen anzeigen"}}/>
            </div>
            <Table className="w-full alternating-table" sortable={[
                {column: 'Name', sortFunction: CaseInsensitive}, {column: 'Email', sortFunction: CaseInsensitive},
            ]} defaultSort={{column: 'Name', direction: 'asc'}}
                   filterable={['Name', 'Email', 'Storniert']} filterBy={this.state.filter}
                   hideFilterInput noDataText="Keine Teilnehmer">
                <Thead className="text-left">
                    <Th className="text-left pr-3" column="Name">Name</Th>
                    <Th className="text-left" column="Email">Email</Th>
                    <Th className="text-left w-32" column="bestaetigt">Bestätigt</Th>
                    <Th className="text-left w-32" column="newsletter">Newsletter</Th>
                    <Th className="text-left w-64" column="Storniert"/>
                </Thead>
                {teilnehmer
                && teilnehmer.map((teilnehmer, idx) => Teilnehmerzeile(teilnehmer, this.props.kursId, (teilnehmerId) => this.onStorno(teilnehmerId, teilnehmer.buchungId), this.props.data.refetch, idx))}
            </Table>
            <div className="h-auto mt-2 mb-4"><Link className="btn btn-gruen no-underline" role="button"
                                                    to={"/kurstermine/" + this.props.kursId + "/buchung/neu"}>Neue
                Buchung</Link>
                <button className="ml-2 btn btn-gruen no-underline" onClick={() => this.createCsvData()}
                        disabled={this.state.directMail.download}>
                    Teilnehmerliste für DirectMail herunterladen
                </button>
                <button className="ml-2 btn btn-gruen no-underline" onClick={() => this.downloadPdf("teilnehmerliste")}
                        disabled={this.state.pdfDownload}>
                    PDF-Teilnehmerliste herunterladen
                </button>
                <button className="ml-2 btn btn-gruen no-underline"
                        onClick={() => this.downloadPdf("teilnehmeretiketten")}
                        disabled={this.state.pdfDownload}>
                    Namensetiketten herunterladen
                </button>
                {teilnehmer && teilnehmer.reduce((prev, cur) => prev || !!cur.bestaetigt, false) &&
                <button className="ml-2 btn btn-gruen no-underline" onClick={() => this.createScrumAllianceCsvData()}>
                    Teilnehmerliste für ScrumAlliance kopieren
                </button>}
            </div>
        </div>
    }

    async onStorno(id, buchungId) {
        let teilnehmer
        for (const tn of this.props.data.teilnehmer) {
            if (tn.Id === id) {
                teilnehmer = tn
                break
            }
        }
        try {
            await confirmation("Wirklich stornieren?", {
                description: "Soll der Teilnehmer " + teilnehmer.vorname + " " + teilnehmer.nachname + " wirklich storniert werden?",
                confirmLabel: "Stornieren",
                abortLabel: "Nicht stornieren",
            })
        } catch (error) {
            // noinspection UnnecessaryReturnStatementJS
            return;
        }
        let neuBerechnen = true
        try {
            await confirmation("Preis neu berechnen?", {
                description: "Soll der Preis der Buchung mit dem stornierten Teilnehmer automatisch neu berechnet werden?",
                confirmLabel: "Neu berechnen",
                abortLabel: "Nicht neu berechnen",
            })
        } catch (error) {
            neuBerechnen = false
        }
        const mutation = gql`mutation teilnehmerStornieren($message: String!){Teilnehmer_soll_storniert_werden(message: $message)}`
        const message = new Teilnehmer_soll_storniert_werden(id, buchungId, neuBerechnen)
        const mutationName = "Teilnehmer_soll_storniert_werden"
        await this.send(mutation, message, mutationName)
        this.props.data.refetch()
    }

    async send(mutation, message, mutationName) {
        try {
            await this.wrappedSend(mutation, message, mutationName)
        } catch (error) {
            alert("Beim Speichern ist ein Fehler aufgetreten:\n: " + error);
        }
    }

    async wrappedSend(mutation, message, mutationName) {
        let result = await this.context.client.mutate({
            mutation,
            variables: {message: JSON.stringify(message)},
            refetchQueries: [{query: query, variables: {kursId: this.props.kurs.Id, match: this.props.url}}],
        })
        const res = result.data[mutationName]
        if (res !== "ok") {
            throw Error(res)
        }
    }
}

const Teilnehmerzeile = (teilnehmer, kursId, onStorno, refetch, idx) =>
    <Tr key={teilnehmer.Id}>
        <Td column="Name" value={teilnehmer.nachname + teilnehmer.vorname} className="w-1 whitespace-no-wrap pr-3">
        <span>{teilnehmer.titel} {teilnehmer.vorname} {teilnehmer.nachname} (<Link
            to={"/kurstermine/" + kursId + "/buchung/" + teilnehmer.buchungId}>zur Buchung</Link>)</span>
        </Td>
        <Td column="Email" className="w-auto" value={teilnehmer.email.toLowerCase()}>
            {!!teilnehmer.storniert ?
                teilnehmer.email :
                <InlineEditor component={NoBlurTextField} value={teilnehmer.email} tabIndex={idx + 1}
                              save={val => new Kontakt_Email_hinterlegen(teilnehmer.kontaktId, val)}
                              className="w-full bg-transparent"/>
            }
        </Td>
        <Td column="bestaetigt">
            <TeilnahmeBestätigen refetch={refetch} bestaetigt={!!teilnehmer.bestaetigt}
                                 buchungId={teilnehmer.buchungId}
                                 kursterminId={kursId} teilnahmeId={teilnehmer.Id}/>
        </Td>
        <Td column="newsletter">
            <NewsletterSetter refetch={refetch} newsletter={!!teilnehmer.kontakt.newsletter}
                              kontaktId={teilnehmer.kontaktId}/>
        </Td>
        <Td column="Storniert" value={!!teilnehmer.storniert ? "storniert" : ""}>{!!teilnehmer.storniert ? <span
                className="inline-block badge badge-rot text-xs">storniert</span> :
            (!!teilnehmer.bestaetigt ? "bereits bestätigt" :
                <LinkButton onClick={() => onStorno(teilnehmer.Id)} className="">Teilnehmer
                    stornieren
                </LinkButton>)}</Td>
    </Tr>
;

const query = gql`query teilnehmer($kursId: String){teilnehmer(kurs: $kursId){anrede{text,code},vorname,nachname,titel,email,bestaetigt,kontakt{newsletter},storniert,Id,kontaktId,buchungId}}`
export default graphql(query, {
    options: ownProps => {
        return {
            fetchPolicy: "network-only",
            variables: {
                kursId: ownProps.match ? ownProps.match.params.id : ownProps.kursId,
                match: ownProps.match ? ownProps.match.url : ownProps.url,
            },
            pollInterval: 500,
        }
    },
})(Teilnehmerliste)