import {graphql} from "react-apollo/index"
import gql from "graphql-tag";
import * as React from "react"
import {SubmitButton} from "../components"
import PropTypes from "prop-types"

const mutation = gql`mutation anmelden($benutzer: String!, $passwort: String!){anmelden(benutzer: $benutzer, passwort: $passwort){success,jwt}}`;

export class Login extends React.Component {
    static contextTypes = {client: PropTypes.any}

    static propTypes = {callback: PropTypes.func}

    state = {
        username: "",
        password: "",
        message: null,
    }

    render() {
        return <div className="w-1/4 my-2 mx-auto login-container">
            {this.state.message &&
            <p className={"mb-2 " + (this.state.error ? "text-red" : "text-green-dark")}>{this.state.message}</p>}
            <form onSubmit={event => this.handleSubmit(event)} className="loginForm">
                <input className="leading-normal border w-full" type="text" value={this.state.username}
                       onChange={event => this.handleChange(event)}
                       name="username" autoFocus={true} placeholder="Benutzername"/><br/>
                <input className="leading-normal border w-full mt-2" type="password" value={this.state.password}
                       onChange={event => this.handleChange(event)} name="password" id="password" placeholder="Passwort"/><br/>
                <SubmitButton title="Anmelden" full={true}/>
            </form>
        </div>
    }

    async handleSubmit(event) {
        event.preventDefault();
        if (this.state.username === "" || this.state.password === "") {
            this.setState({message: "Bitte Benutzername und Passwort angeben.", error: true})
            return;
        }
        try {
            let result = await this.context.client.mutate({
                mutation,
                variables: {benutzer: this.state.username, passwort: this.state.password},
            })
            if (!!result.data.anmelden.success === false) {
                this.setState({message: "Benutzername oder Passwort falsch.", error: true})
            } else {
                if (result.data.anmelden.jwt) {
                    localStorage.setItem('Kute::LoginToken', result.data.anmelden.jwt)
                }
                this.setState({message: "Erfolgreich angemeldet.", error: false})
                this.props.callback()
            }
        } catch (error) {
            this.setState({message: "Beim Anmelden ist ein Fehler aufgetreten. Bitte neu laden.", error: true})
            console.warn("submitting failed: " + error);
        }
    }

    handleChange(event) {
        this.setState({[event.target.name]: event.target.value})
    }
}