import PropTypes from "prop-types";
import * as React from "react";

export class EditorField extends React.Component {
    static contextTypes = {
        editor: PropTypes.any,
    };

    static propTypes = {
        component: PropTypes.any.isRequired,
        valuekey: PropTypes.string.isRequired,
        placeholder: PropTypes.string,
        className: PropTypes.string,
        tabIndex: PropTypes.number,
        options: PropTypes.arrayOf(PropTypes.shape({
            title: PropTypes.string.isRequired,
            value: PropTypes.string.isRequired,
        })),
    };

    constructor(props, context) {
        super(props, context);
        this.state = {highlight: ""};

        this.highlight = this.highlight.bind(this);
    }

    highlight(reason) {
        this.setState({highlight: reason});
        setTimeout(() => this.setState({highlight: ""}), 1000);
    }

    componentDidMount() {
        this.context.editor.registerField(this.props.valuekey, this);
    }

    componentWillUnmount() {
        this.context.editor.unRegisterField(this.props.valuekey);
    }

    render() {
        const Component = this.props.component;
        return <Component
            className={this.props.className || ""}
            value={this.context.editor.get(this.props.valuekey)}
            placeholder={this.props.placeholder}
            enabled={!this.context.editor.saving}
            onChange={value => this.context.editor.set(this.props.valuekey, value)}
            save={() => this.context.editor.save()}
            revert={() => this.context.editor.reset(this.props.valuekey)}
            highlight={this.state.highlight}
            options={this.props.options}
            tabIndex={this.props.tabIndex}
            acceptCharset="utf-8"
        />
    }
}
