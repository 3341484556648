import {graphql} from "react-apollo/index"
import * as React from "react"
import {Label} from "./components"
import gql from "graphql-tag";
import PropTypes from "prop-types"
import {AutosuggestTheme} from "./App"
import Autosuggest from "react-autosuggest"

const getSuggestionValue = suggestion => suggestion;
const renderSuggestion = (suggestion) => (
    <div>{suggestion.name}</div>
);


class KurstypSelect extends React.Component {
    static propTypes = {
        name: PropTypes.string.isRequired,
        title: PropTypes.string,
        value: PropTypes.string,
        onChange: PropTypes.func,
    }

    initialState = {
        kurstypen: [],
    }

    constructor(props, context) {
        super(props, context);

        this.state = {
            kurstypen: this.initialState.kurstypen,
            name: props.name,
            title: props.title,
            textValue: this.textValueFor(props.value),
            value: props.value,
            eventHandler: props.onChange,
            suggestions: [],
        }

        this.handleSuggestionChange = this.handleSuggestionChange.bind(this);
        this.handleSuggestionFetchRequest = this.handleSuggestionFetchRequest.bind(this);
        this.handleSuggestionClearRequest = this.handleSuggestionClearRequest.bind(this);
        this.handleKeyUp = this.handleKeyUp.bind(this)
        this.textValueFor = this.textValueFor.bind(this)
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.data.kurstypen) {
            this.setState({
                "kurstypen": nextProps.data.kurstypen,
            })
        }
        this.setState({
            name: nextProps.name,
            title: nextProps.title,
            value: nextProps.value,
            onChange: nextProps.onChange,
        })
    }

    textValueFor(name) {
        let value = ""
        const state = this.state || this.initialState
        state.kurstypen.forEach(item => {
            if (item.Id === name) {
                value = item.name
            }
        })
        return value
    }

    handleKeyPress(event) {
        if (event.key === 'Enter') {
            event.preventDefault();
        }
    }

    handleKeyUp(event) {
        if (event.key === 'Enter') {
            event.preventDefault();
        } else {
            this.props.onKeyUp && this.props.onKeyUp(event)
        }
    }

    componentWillMount() {
        try {
            // noinspection JSIgnoredPromiseFromCall
            this.handleSuggestionFetchRequest({value: this.state.textValue})
        } catch (error) {
            // ignore
        }
    }


    render() {
        const inputProps = {
            placeholder: "Zum Suchen tippen...",
            value: this.state.textValue,
            onChange: this.handleSuggestionChange,
            onKeyPress: this.handleKeyPress,
            onKeyUp: this.handleKeyUp,
            className: "leading-normal border w-full",
            name: "3accbd9a", /* heißt so, weil Chrome sonst Autocompletion macht 🖕 */
            autoComplete: "off",
            "aria-autocomplete": "none",
        }

        let noHeader = this.state.title === null || this.state.title === undefined
        return <div>
            {noHeader || <Label name={this.state.name} title={this.state.title}/>}{noHeader || <br/>}
            <Autosuggest suggestions={this.state.suggestions}
                         onSuggestionsFetchRequested={this.handleSuggestionFetchRequest}
                         onSuggestionsClearRequested={this.handleSuggestionClearRequest}
                         shouldRenderSuggestions={() => true}
                         getSuggestionValue={getSuggestionValue} theme={AutosuggestTheme}
                         renderSuggestion={renderSuggestion} inputProps={inputProps}/>
        </div>
    }

    async handleSuggestionFetchRequest({value}) {
        const inputValue = value.trim().toLowerCase();

        const typen = this.state.kurstypen;

        let suggestions = typen.filter(typ =>
            inputValue.split(' ').every(input =>
                typ.name.toLowerCase().includes(input) || typ.name.match(/[A-Z0-9]/g).reduce((prev, item) => prev + item, "").toLowerCase().includes(input),
            ),
        );

        suggestions.sort((a, b) => {
            let an = a.name.toLowerCase()
            let bn = b.name.toLowerCase()
            if (an < bn) {
                return -1
            }
            if (bn < an) {
                return 1
            }
            return 0
        })

        this.setState({
            suggestions,
        })
    }

    handleSuggestionClearRequest() {
        this.setState({
            suggestions: [],
        })
    }

    handleSuggestionChange(event, {newValue}) {
        if (typeof newValue === 'string' || newValue instanceof String) {
            this.setState({
                textValue: newValue,
                value: null,
            })
            this.state.eventHandler && this.state.eventHandler({
                target: {
                    name: this.state.name,
                    type: "autosuggest",
                    value: null,
                },
            })
        } else {
            this.setState({
                textValue: newValue.name,
                value: newValue.Id,
            })
            this.state.eventHandler && this.state.eventHandler({
                target: {
                    name: this.state.name,
                    type: "autosuggest",
                    value: newValue.Id,
                },
            })
        }
    }
}

export default graphql(gql`{kurstypen{Id,name}}`, {options: {fetchPolicy: "network-only"}})(KurstypSelect);