import * as React from "react"
import PropTypes from "prop-types"
import {KurstagDisplay} from "./KurstagDisplay";
import {KurstagCreator} from "./KurstagCreator"

export class KurstagListField extends React.Component {

    static contextTypes = {client: PropTypes.any};

    static propTypes = {
        kurstage: PropTypes.arrayOf(PropTypes.shape(
            {
                Id: PropTypes.string.isRequired,
                offset: PropTypes.number.isRequired,
                beginn: PropTypes.string.isRequired,
                ende: PropTypes.string.isRequired,
            })).isRequired,
        addKurstag: PropTypes.func.isRequired,
        removeKurstag: PropTypes.func.isRequired,
    }

    constructor(props, context) {
        super(props, context);

        this.state = {
            editing: false,
            saving: false,
        };
    }

    renderItem(kurstag, index) {
        return <div>Tag {kurstag.offset + 1}: <KurstagDisplay kurstag={kurstag}/>&nbsp;
            <button className="text-dst-logo-gruen-mitte hover:text-dst-logo-gruen-hinten underline"
                    onClick={() => this.removeKurstag(kurstag.Id)}>entfernen
            </button>
        </div>;
    }

    async addToList(kurstag) {
        this.setState({editing: false, saving: true});
        this.props.addKurstag(kurstag).then(() => this.setState({editing: false, saving: false}));
    }

    async removeKurstag(id) {
        this.setState({editing: false, saving: true});
        this.props.removeKurstag(id).then(() => this.setState({editing: false, saving: false}));
    }

    renderEditor() {
        return <KurstagCreator onSave={(kurstag) => this.addToList(kurstag)}
                               onCancel={() => this.setState({editing: false})}
                               existing={this.props.kurstage.map((kurstag) => {
                                   return {Id: kurstag.Id, tag: kurstag.offset + 1}
                               })}/>;
    }

    renderAddButton() {
        return <button className="text-dst-logo-gruen-mitte hover:text-dst-logo-gruen-hinten underline"
                       onClick={() => this.setState({editing: true})}>Kurstag hinzufügen...</button>;
    }

    render() {
        return (<table className="leading-tight">
            <thead>
            <tr>
                <td>
                    {this.state.saving ? "wird gespeichert..." : this.state.editing ? this.renderEditor() : this.renderAddButton()}
                </td>
            </tr>
            </thead>
            <tbody>
            {this.props.kurstage.map((kurstag, idx) => <tr key={kurstag.Id}>
                <td>{this.renderItem(kurstag, idx)}</td>
            </tr>)}
            </tbody>
        </table>);
    }
}
