import * as React from "react";
import Link from "react-router-dom/es/Link"

import logo from './DST-Logo-RGB.svg';
import PropTypes from "prop-types"
import {graphql} from "react-apollo"
import gql from "graphql-tag";
import {TopAlertContainer} from "./UI/Alert"
import {VersionInfo} from "./VersionCheck"

export const LinkButton = (props) => <button {...props}
                                             className={(props.className || "") + " text-dst-logo-gruen-hinten hover:text-dst-logo-gruen-mitte"}/>

export const QuarterInputField = (props) => <InputFieldWithClassName
    className="w-1/4 leading-normal border" {...props}/>;

export const FifthSelectField = (props) => <select className="leading-normal border w-full lg:w-1/5" name={props.name}
                                                   onChange={props.onChange} value={props.value}>
    {props.values.map((value, index) => <option value={props.valueKey ? value[props.valueKey] : value} key={index}>
        {props.titleKey ? value[props.titleKey] : value}
    </option>)}}
</select>

export const InputField = (props) => <div className={props.divClassName}>
    <Label name={props.name} title={props.title}/><br/>
    <Input {...props}/><br/>
</div>;

export const InputFieldWithClassName = (props) => <input className={"leading-normal border " + props.className}
                                                         type={props.type}
                                                         value={props.value} placeholder={props.title}
                                                         onChange={props.onChange} name={props.name} {...props}/>;

export const Input = (props) => <input className="leading-normal border w-full" type={props.type} value={props.value}
                                       onChange={props.onChange} name={props.name} onKeyDown={props.onKeyDown}
                                       onKeyUp={props.onKeyUp}
                                       placeholder={props.placeholder ? props.placeholder : props.title} {...props}/>

export const TextareaField = (props) => <div>
    <Label name={props.name} title={props.title}/><br/>
    <Textarea {...props}/>
</div>;

export const Textarea = (props) => <textarea className={props.className || "leading-normal border w-full"}
                                             onChange={props.onChange}
                                             onKeyUp={props.onKeyUp}
                                             placeholder={props.placeholder ? props.placeholder : props.title}
                                             name={props.name} onKeyDown={props.onKeyDown} value={props.value}/>

export const SelectField = (props) => <div>
    {props.noHeader || <Label name={props.name} title={props.title}/>}{props.noHeader || <br/>}
    <Select {...props}/>
    <br/>
</div>;

SelectField.defaultProps = {
    noHeader: false,
}

export const Select = (props) => <select name={props.name} onChange={props.onChange} value={props.value}>
    {props.values.map((value, index) => <option value={props.valueKey ? value[props.valueKey] : value} key={index}>
        {props.titleKey ? value[props.titleKey] : value}
    </option>)}}
</select>

export const Label = (props) => <label className="font-bold leading-normal" htmlFor={props.name}>{props.title}</label>;

Label.propTypes = {
    name: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
}

export const SubmitButton = (props) => <input className={"mt-2 btn btn-gruen " + (props.full ? "w-full" : "")}  type="submit" value={props.title}
                                              disabled={props.disabled}/>
SubmitButton.defaultProps = {
    disabled: false,
}

export const Header = () => {
    return <header className="bg-dst-hintergrund-hellgruen text-center relative">
        <TopAlertContainer>
            <VersionInfo/>
        </TopAlertContainer>
        <Link to="/"><img src={logo} className="h-16 md:h-24 mt-3" alt="logo"/></Link>
        <h1 className="text-dst-logo-gruen-hinten font-bold text-2xl leading-loose">reisebüro</h1>
    </header>
}

export const Vier04 = () => {
    return <h1>404</h1>
}

export const TeilnehmerNeuZeile = (props) => <div>
    <AnredeField name={"teilnehmer_" + props.index + "_anrede"} title="Anrede"
                 value={props.teilnehmer.anrede}
                 onChange={props.onChange}/>
    <InputFieldWithClassName name={"teilnehmer_" + props.index + "_titel"} type="text" title="Titel"
                             value={props.teilnehmer.titel} className="w-full lg:w-1/5 leading-normal border"
                             onChange={props.onChange}/>
    <InputFieldWithClassName name={"teilnehmer_" + props.index + "_vorname"} type="text" title="Vorname"
                             value={props.teilnehmer.vorname} className="w-full lg:w-1/5 leading-normal border"
                             onChange={props.onChange}/>
    <InputFieldWithClassName name={"teilnehmer_" + props.index + "_nachname"} type="text" title="Nachname"
                             value={props.teilnehmer.nachname} className="w-full lg:w-1/5 leading-normal border"
                             onChange={props.onChange}/>
    <InputFieldWithClassName name={"teilnehmer_" + props.index + "_email"} type="email" title="E-Mail"
                             value={props.teilnehmer.email} className="w-full lg:w-1/5 leading-normal border"
                             onChange={props.onChange}/>
</div>

const _AnredeField = (props) => <FifthSelectField name={props.name} title={props.title}
                                                  value={props.value}
                                                  onChange={props.onChange}
                                                  values={props.data.anreden || []} valueKey="code" titleKey="text"/>

export const AnredeField = graphql(gql`{anreden{code,text}}`, {
    options: {
        fetchPolicy: "network-only",
    },
})(_AnredeField)

export const StatusButton = (props) => <button key={props.status.text} type="button"
                                               className={(props.active ? "bg-dst-logo-gruen-hinten" : "bg-dst-logo-gruen-mitte") + " max-w-64 relative px-2 font-normal btn " + (props.className || "")}
                                               onClick={() => props.onClick(props.status.text, !props.active)}>
    <span className="mr-8">{props.status.text}</span>
    <svg className="h-4 absolute pin-r pr-4" version="1.1" xmlns="http://www.w3.org/2000/svg"
         xmlnsXlink="http://www.w3.org/1999/xlink"
         viewBox="0 0 474.8 474.801"
         xmlSpace="preserve">
        <g>
            <g>
                <path className="fill-white" d="M396.283,257.097c-1.14-0.575-2.282-0.862-3.433-0.862c-2.478,0-4.661,0.951-6.563,2.857l-18.274,18.271
			c-1.708,1.715-2.566,3.806-2.566,6.283v72.513c0,12.565-4.463,23.314-13.415,32.264c-8.945,8.945-19.701,13.418-32.264,13.418
			H82.226c-12.564,0-23.319-4.473-32.264-13.418c-8.947-8.949-13.418-19.698-13.418-32.264V118.622
			c0-12.562,4.471-23.316,13.418-32.264c8.945-8.946,19.7-13.418,32.264-13.418H319.77c4.188,0,8.47,0.571,12.847,1.714
			c1.143,0.378,1.999,0.571,2.563,0.571c2.478,0,4.668-0.949,6.57-2.852l13.99-13.99c2.282-2.281,3.142-5.043,2.566-8.276
			c-0.571-3.046-2.286-5.236-5.141-6.567c-10.272-4.752-21.412-7.139-33.403-7.139H82.226c-22.65,0-42.018,8.042-58.102,24.126
			C8.042,76.613,0,95.978,0,118.629v237.543c0,22.647,8.042,42.014,24.125,58.098c16.084,16.088,35.452,24.13,58.102,24.13h237.541
			c22.647,0,42.017-8.042,58.101-24.13c16.085-16.084,24.134-35.45,24.134-58.098v-90.797
			C402.001,261.381,400.088,258.623,396.283,257.097z"/>
                {props.active &&
                <path className="fill-white" d="M467.95,93.216l-31.409-31.409c-4.568-4.567-9.996-6.851-16.279-6.851c-6.275,0-11.707,2.284-16.271,6.851
			L219.265,246.532l-75.084-75.089c-4.569-4.57-9.995-6.851-16.274-6.851c-6.28,0-11.704,2.281-16.274,6.851l-31.405,31.405
			c-4.568,4.568-6.854,9.994-6.854,16.277c0,6.28,2.286,11.704,6.854,16.274l122.767,122.767c4.569,4.571,9.995,6.851,16.274,6.851
			c6.279,0,11.704-2.279,16.274-6.851l232.404-232.403c4.565-4.567,6.854-9.994,6.854-16.274S472.518,97.783,467.95,93.216z"/>}
            </g>
        </g>
    </svg>
</button>

StatusButton.propTypes = {
    status: PropTypes.shape({text: PropTypes.string.isRequired}).isRequired,
    active: PropTypes.bool.isRequired,
    onClick: PropTypes.func.isRequired,
    className: PropTypes.string,
}

export const StatusButtonField = (props) => <StatusButton status={{text: props.placeholder}} active={props.value}
                                                          onClick={(_, state) => props.onChange(state)}/>

StatusButtonField.propTypes = {
    placeholder: PropTypes.string.isRequired,
    value: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired,
}

TeilnehmerNeuZeile.propTypes = {
    'index': PropTypes.number.isRequired,
    'teilnehmer': PropTypes.shape({
        anrede: PropTypes.string.isRequired,
        vorname: PropTypes.string.isRequired,
        nachname: PropTypes.string.isRequired,
        email: PropTypes.string.isRequired,
    }).isRequired,
    'onChange': PropTypes.func.isRequired,
}


export const Loading = () => <div className="spinner">
    <div className="rect1"/>
    <div className="rect2"/>
    <div className="rect3"/>
    <div className="rect4"/>
    <div className="rect5"/>
</div>

export const ResetButtonItem = () => {
    return <svg className="DateRangePickerInput_clearDates_svg DateRangePickerInput_clearDates_svg_1"
                viewBox="0 0 12 12">
        <path fillRule="evenodd"
              d="M11.53.47a.75.75 0 0 0-1.061 0l-4.47 4.47L1.529.47A.75.75 0 1 0 .468 1.531l4.47 4.47-4.47 4.47a.75.75 0 1 0 1.061 1.061l4.47-4.47 4.47 4.47a.75.75 0 1 0 1.061-1.061l-4.47-4.47 4.47-4.47a.75.75 0 0 0 0-1.061z"/>
    </svg>
}