import PropTypes from "prop-types";
import * as React from "react";

class Field extends React.Component {

    static propTypes = {
        value: PropTypes.string,
        placeholder: PropTypes.string,
        enabled: PropTypes.bool,
        save: PropTypes.func,
        revert: PropTypes.func,
        onChange: PropTypes.func,
        className: PropTypes.string,
        highlight: PropTypes.string,
        tabIndex: PropTypes.number,
        type: PropTypes.string,
    };

    keyup(e) {
        if (e.key === "Enter") {
            this.props.save();
            e.target.blur();
            e.preventDefault();
        }
        if (e.key === "Escape") {
            this.props.revert();
            e.preventDefault();
        }
    }

    static highlight(reason) {
        if (reason === "updated") {
            return "bg-dst-logo-gruen-vorne";
        }
        if (reason === "error") {
            return "bg-red-lighter";
        }
        return "";
    }

    render() {
        let mr = "mr-2 "
        if (this.props.className.includes("mx-") || this.props.className.includes("mr-")) {
            mr = ""
        }
        return <input className={mr + this.props.className + " " + (TextField.highlight(this.props.highlight))}
                      readOnly={!this.props.enabled}
                      type={this.props.type || "text"}
                      value={this.props.value || ""}
                      placeholder={this.props.placeholder}
                      onChange={e => this.props.onChange(e.target.value)}
                      onKeyUp={(e) => this.keyup(e)}
                      tabIndex={this.props.tabIndex}
        />
    }
}

export const TextField = Field

export const TelephoneField = props => <Field type="tel" {...props}/>

export const EmailField = props => <Field type="email" {...props}/>

export const IntegerField = props => <Field type="number" step="1" {...props}/>

export class NoBlurTextField extends TextField {
    keyup(e) {
        if (e.key === "Enter") {
            this.props.save();
            e.preventDefault();
        }
        if (e.key === "Escape") {
            this.props.revert();
            e.preventDefault();
        }
    }
}

export class PreisBetragField extends Field {
    render() {
        let mr = "mr-1 "
        if (this.props.className.includes("mx-") || this.props.className.includes("mr-")) {
            mr = ""
        }
        return <input className={mr + this.props.className + " " + (TextField.highlight(this.props.highlight))}
                      readOnly={!this.props.enabled}
                      type="number" step="0.01" lang="en-150"
                      value={this.props.value || ""}
                      placeholder={this.props.placeholder}
                      onChange={e => this.props.onChange(e.target.value)}
                      onKeyUp={(e) => this.keyup(e)}
                      tabIndex={this.props.tabIndex}
        />
    }
}