import * as React from "react"
import gql from "graphql-tag";
import PropTypes from "prop-types"
import {toCSV} from "react-csv/lib/core"
import confirmation from "./UI/Confirm"

const query = gql`{
    teilnehmer {
        Id
        anrede { text code }
        titel
        vorname
        nachname
        buchung {
            Id
            storniert
            kurstermin {
                Id
                kursCode
                typ { name kuerzel }
            }
        }
        kontakt {
            Id
            activeCampaignId
            email
            firma
            telefon
        }
        storniert
        bestaetigt
    }
}`

export class TeilnehmerExporter extends React.Component {
    static contextTypes = {client: PropTypes.any};

    state = {
        buttonEnabled: true,
    }

    async createCsvData() {
        try {
            await confirmation("Wirklich exportieren?", {
                description: "Sollen wirklich alle Teilnehmer exportiert werden? Dieser Export " +
                    "verursacht eine hohe Last auf dem Server und sollte daher nicht zu häufig und idealerweise nicht " +
                    "zur Peak-Zeit ausgeführt werden. Alternativ können auch in der Kurstermin-Detailansicht die " +
                    "Teilnehmer des jeweiligen Kurses exportiert werden.",
                confirmLabel: "Alle Teilnehmer exportieren",
                abortLabel: "Abbrechen",
            })
        } catch (error) {
            // noinspection UnnecessaryReturnStatementJS
            return;
        }

        this.setState({buttonEnabled: false})
        let teilnehmer = []
        try {
            let result = await this.context.client.query({query})
            if (result.data.teilnehmer) {
                teilnehmer = result.data.teilnehmer
            } else {
                alert("Konnte die Teilnehmerdaten nicht laden.")
                this.setState({buttonEnabled: true})
                return
            }
        } catch (error) {
            console.error(error)
            alert("Konnte die Buchungsdaten nicht laden.")
            this.setState({buttonEnabled: true})
            return
        }
        let objs = []
        let ids = []
        for (let tn of teilnehmer) {
            if (ids.includes(tn.Id)) {
                continue // Duplikatvermeidung
            }
            let {Id, titel, vorname, nachname, buchung, kontakt, storniert, bestaetigt} = tn
            let anrede = ""
            if (tn.anrede) {
                anrede = tn.anrede.text
            }
            let obj = {
                Id,
                anrede,
                titel,
                vorname,
                nachname,
                "Telefon": kontakt.telefon || "",
                "Email": kontakt.email || "",
                "Firma": kontakt.firma || "",
                "Kontakt-ID": kontakt.Id,
                "Buchung-ID": buchung.Id,
                "Kurstermin-ID": buchung.kurstermin.Id,
                "Kurscode": buchung.kurstermin.kursCode,
                "Kurstyp": buchung.kurstermin.typ.name,
                "Kurs-Kürzel": buchung.kurstermin.typ.kuerzel,
                "Buchung storniert": !!buchung.storniert ? "storniert" : "",
                "storniert": !!storniert ? "storniert" : "",
                "bestätigt": !!bestaetigt ? "bestätigt" : "",
                "ActiveCampaign Id": kontakt.activeCampaignId || "",
            }
            objs.push(obj)
            ids.push(tn.Id)
        }
        let csv = toCSV(objs, null, ";", "\"")
        let element = document.createElement("a")
        const file = new Blob([csv], {type: 'text/csv'})
        element.href = URL.createObjectURL(file);
        element.download = "alle-teilnehmer.csv";
        element.click();
        this.setState({buttonEnabled: true})
    }

    render() {
        return <button
            className="ml-2 btn btn-gruen no-underline"
            onClick={() => this.createCsvData()} disabled={!this.state.buttonEnabled}>
            Alle Teilnehmer herunterladen
        </button>
    }
}