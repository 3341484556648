import * as React from "react";
import {gql} from "apollo-boost";
import PropTypes from "prop-types"
import {InputField, Label, SubmitButton, TextareaField, Vier04} from "./components"
import {Redirect} from "react-router-dom"
import RegionField from "./RegionField"
import {
    Kursort_Adresse_soll_geaendert_werden, Kursort_Google_Maps_URL_soll_gesetzt_werden,
    Kursort_Name_soll_geaendert_werden,
    Kursort_soll_angelegt_werden, Region_soll_angelegt_werden,
} from "./Daten/FlugplanMessages.gen"
import CountrySelect from "./CountrySelect"


const mutation = gql`mutation regionAnlegen($message: String!){Region_soll_angelegt_werden(message: $message, type: "Region_soll_angelegt_werden")}`;


export class RegionAnlegen extends React.Component {
    static contextTypes = {client: PropTypes.any};
    initialState = {
        Id: "",
        internName: "",
        name: "",
        nameEn: "",
        land: "DEU",
        zeitzone: "Europe/Zurich",
        success: false,
        startState: null,
        change: false,
        buttonDisabled: false,
    }

    events = []
    eventResults = {}

    constructor(props, context) {
        super(props, context);

        this.state = {...this.initialState}

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    render() {
        return <div className="region-anlegen-container">
            <h2 className="heading">Region anlegen</h2>
            <InputField name="Id" type="text"
                        title="ID (oder leer lassen für generierte ID, sollte dem IATA-Code des Flughafens entsprechen)"
                        value={this.state.Id}
                        onChange={this.handleInputChange}/>
            <InputField name="name" type="text" title="Name" value={this.state.name}
                        onChange={this.handleInputChange}/>
            <InputField name="internName" type="text" title="Intern-Name"
                        value={this.state.internName} // todo: validation! nur [a-zA-Z]
                        onChange={this.handleInputChange}/>
            <InputField name="nameEn" type="text" title="Name (Englisch)" value={this.state.nameEn}
                        onChange={this.handleInputChange}/>
            <CountrySelect name="land" title="Land" value={this.state.land}
                           onChange={this.handleInputChange}/>
            <InputField name="zeitzone" type="text" title="Zeitzone" value={this.state.zeitzone}
                        onChange={this.handleInputChange}/>
            <button className="btn btn-gruen" disabled={this.state.buttonDisabled} onClick={this.handleSubmit}>Anlegen
            </button>
            {
                this.state.success && <Redirect to="/regionen" push/>
            }
        </div>;
    }

    handleInputChange(event) {
        const target = event.target;
        let value = target.type === 'checkbox' ? target.checked : target.value;
        value = target.type === 'number' ? parseInt(value, 10) : value;
        const name = target.name;

        this.setState({
            [name]: value,
        });
    }

    async handleSubmit(event) {
        this.setState({buttonDisabled: true})
        event.preventDefault();

        const idRegex = /^[A-Za-z-]+$/
        if (this.state.Id !== "" && !idRegex.test(this.state.Id)) {
            alert("Die Id darf nur aus A-Z, a-z und Bindestrichen bestehen")
            return
        }
        let id = null
        if (this.state.Id !== "") {
            id = this.state.id
        }
        const internNameRegex = /^[A-Za-z]+$/
        if (!internNameRegex.test(this.state.internName)) {
            alert("Der Internname darf nur aus lateinischen Buchstaben bestehen und sollte dem Namen entsprechen")
            return
        }
        let nameEn = null
        if (this.state.nameEn.trim() !== "") {
            nameEn = this.state.nameEn.trim()
        }
        const region = new Region_soll_angelegt_werden(id, this.state.internName, this.state.name, nameEn, this.state.land, this.state.zeitzone)
        const message = JSON.stringify(region);
        try {
            const result = await this.context.client.mutate({
                mutation,
                variables: {message},
            })
            const success = result.data.Region_soll_angelegt_werden === "ok"
            if (!success) {
                alert("Konnte die Region nicht anlegen")
                console.error({result})
            } else {
                this.setState({success})
            }
        } catch (e) {
            alert("Konnte die Region nicht anlegen")
            console.error({e})
        }
        this.setState({buttonDisabled: false})
    }

}
