import {gql} from "apollo-boost"
import * as React from "react"
import Autosuggest from "react-autosuggest"
import PropTypes from "prop-types"
import {AutosuggestTheme} from "../App"

const waehrungQuery = gql`{waehrungen{code,symbol,name}}`

const getSuggestionValue = suggestion => suggestion;
const renderSuggestion = (suggestion) => (
    <div>{suggestion.name} ({suggestion.symbol})</div>
);

export class WaehrungField extends React.Component {
    static contextTypes = {client: PropTypes.any};
    static propTypes = {
        value: PropTypes.string.isRequired,
        textValue: PropTypes.string,
        onChange: PropTypes.func,
        onKeyUp: PropTypes.func,
    }

    constructor(props, context) {
        super(props, context);

        this.state = {
            waehrung: props.value,
            locationSuggestions: [],
            waehrungName: props.textValue || "",
            eventHandler: props.onChange || undefined,
        }

        this.handleSuggestionChange = this.handleSuggestionChange.bind(this);
        this.handleSuggestionFetchRequest = this.handleSuggestionFetchRequest.bind(this);
        this.handleSuggestionClearRequest = this.handleSuggestionClearRequest.bind(this);
        this.handleKeyUp = this.handleKeyUp.bind(this)
    }

    handleKeyPress(event) {
        if (event.key === 'Enter') {
            event.preventDefault();
        }
    }

    handleKeyUp(e) {
        if (e.key === "Enter") {
            this.props.save();
            e.preventDefault();
        } else if (e.key === "Escape") {
            this.props.revert();
            e.preventDefault();
            this.props.onKeyUp && this.props.onKeyUp(e)
        } else {
            this.props.onKeyUp && this.props.onKeyUp(e)
        }
    }

    componentWillMount() {
        try {
            // noinspection JSIgnoredPromiseFromCall
            this.handleSuggestionFetchRequest({value: this.state.waehrungName})
        } catch (error) {
            // ignore
        }
    }

    render() {
        const inputProps = {
            placeholder: "Zum Suchen tippen...",
            value: this.state.waehrungName,
            onChange: this.handleSuggestionChange,
            onKeyPress: this.handleKeyPress,
            onKeyUp: this.handleKeyUp,
            className: this.props.className || "leading-normal border w-full",
            name: "3accbd9a", /* heißt so, weil Chrome sonst Autocompletion macht 🖕 */
            autoComplete: "off",
            "aria-autocomplete": "none",
        }

        return <Autosuggest suggestions={this.state.locationSuggestions}
                            onSuggestionsFetchRequested={this.handleSuggestionFetchRequest}
                            onSuggestionsClearRequested={this.handleSuggestionClearRequest}
                            shouldRenderSuggestions={() => true}
                            getSuggestionValue={getSuggestionValue} theme={AutosuggestTheme}
                            renderSuggestion={renderSuggestion} inputProps={inputProps}/>
    }

    async handleSuggestionFetchRequest({value}) {
        const inputValue = value.trim().toLowerCase();

        try {
            let result = await this.context.client.query({
                query: waehrungQuery,
            })

            const waehrungen = result.data.waehrungen;

            const suggestions = waehrungen.filter(waehrung =>
                inputValue.split(' ').every(input =>
                    waehrung.name.toLowerCase().includes(input) || waehrung.code.toLowerCase().includes(input),
                ),
            );

            this.setState({
                locationSuggestions: suggestions,
            })
        } catch (error) {
            alert("couldn't query: " + error);
        }
    }

    handleSuggestionClearRequest() {
        this.setState({
            locationSuggestions: [],
        })
    }

    handleSuggestionChange(event, {newValue}) {
        if (typeof newValue === 'string' || newValue instanceof String) {
            this.setState({
                waehrungName: newValue,
                waehrung: null,
            })
            this.state.eventHandler && this.state.eventHandler({
                waehrungName: newValue.name,
                waehrung: null,
            })
        } else {
            this.setState({
                waehrungName: newValue.name,
                waehrung: newValue.code,
            })
            this.state.eventHandler && this.state.eventHandler({
                waehrungName: newValue.name,
                waehrung: newValue.code,
            })
        }
    }
}

export const WaehrungFieldWrapper = props => {
    let {className} = props
    let classNames = className.split(" ")
    let newClassNames = []
    let width = ""
    for (let i = 0; i < classNames.length; i++) {
        const cn = classNames[i];
        if (/^w-\d\/\d\b/.test(cn)) {
            width = cn
        } else {
            newClassNames.push(cn)
        }
    }
    let textValue = ""
    for (let i = 0; i < props.options.length; i++) {
        const option = props.options[i];
        if (option.title === "textValue") {
            textValue = option.value
        }
    }
    return <div className={"inline-block " + width}>
        <WaehrungField {...props} className={newClassNames.reduce((prev, cur) => prev + " " + cur, "").trim()}
                       onChange={waehrung => props.onChange(waehrung.waehrung)} textValue={textValue}/>
    </div>
}