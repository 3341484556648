import * as React from "react"
import gql from "graphql-tag"
import {graphql} from "react-apollo/index"
import * as Reactable from "reactable"

const Table = Reactable.Table
const Tr = Reactable.Tr
const Td = Reactable.Td
const Thead = Reactable.Thead
const Th = Reactable.Th

class LogViewer extends React.Component {
    state = {
        filter: "",
        levels: [ // todo: vom Server holen
            "🔵 debug",
            "⚪ info",
            "⚠️️  warn",
            "🔴 error",
        ],
        shownLevels: {
            "🔵 debug": false,
            "⚪ info": true,
            "⚠️️  warn": true,
            "🔴 error": true,
        },
    }

    constructor(props, context) {
        super(props, context)

        this.filterTable = this.filterTable.bind(this)
        this.levelFilterClick = this.levelFilterClick.bind(this)
    }

    componentDidMount() {
        this.props.data.subscribeToMore({
            document: gql`
                subscription logger {
                    logger
                }
            `,
            updateQuery: () => {
                this.props.data.refetch()
            },
        })
    }

    filterTable(event) {
        const target = event.target;
        let value = target.value;

        this.setState({
            filter: value,
        });
    }

    levelFilterClick(event) {
        const target = event.target
        const check = target.checked
        const name = target.name

        let shownLevels = this.state.shownLevels
        shownLevels[name] = check
        this.setState({shownLevels})
    }

    render() {
        return <div className="log-container">
            <h2 className="heading">Log-Einträge</h2>
            <input type="text" className="w-full border h-8 p-2" value={this.state.filter} autoFocus={true}
                   placeholder="Zum Filtern tippen..." onChange={this.filterTable}/><br/>
            <div className="flex justify-between h-8">
                {this.state.levels.map((level) =>
                    <span className="leading-loose"><label htmlFor={level}>{level} </label>
                        <input type="checkbox"
                               name={level}
                               id={level}
                               value="checked"
                               onClick={this.levelFilterClick}
                               checked={this.state.shownLevels[level]}/></span>,
                )}
            </div>
            <Table className="w-full alternating-table" filterable={['level', 'sender', 'message']}
                   filterBy={this.state.filter}
                   hideFilterInput noDataText="Keine Einträge">
                <Thead className="text-left">
                <Th column="timestamp">Zeit</Th>
                <Th column="level">Level</Th>
                <Th column="sender">Absender</Th>
                <Th column="message">Nachricht</Th>
                </Thead>

                {this.props.data.logger && this.props.data.logger.messages
                && this.props.data.logger.messages.map((logentry) => Logzeile(logentry, this.state.shownLevels))}
            </Table>
        </div>
    }
}

const Logzeile = (logentry, shownLevels) => {
    if (!shownLevels[logentry.level]) {
        return
    }
    return <Tr key={JSON.stringify(logentry)}>
        <Td column="timestamp">{logentry.timestamp}</Td>
        <Td column="level">{logentry.level}</Td>
        <Td column="sender">{logentry.sender}</Td>
        <Td column="message">{logentry.message}</Td>
    </Tr>
};

export default graphql(gql`query logger {logger {messages{timestamp, level, sender, message}}}`, {
    options: {
        fetchPolicy: "network-only",
    },
})(LogViewer)
