import PropTypes from "prop-types";
import * as React from "react";
import {UnicodeButton} from "./UnicodeButton";

export class FullSizeEditorControls extends React.Component {
    static propTypes = {
        save: PropTypes.func.isRequired,
        revert: PropTypes.func.isRequired,
        changed: PropTypes.bool.isRequired,
        valid: PropTypes.bool.isRequired,
        showAlways: PropTypes.bool,
        error: PropTypes.any,
    };

    render() {
        return <div tabIndex={-1} className="mt-1">
            {(this.props.showAlways || this.props.changed)
            && <span>{!this.props.error
            &&
            <span>{this.props.valid ?
                <button className="btn btn-gruen mr-1" onClick={this.props.save}>Speichern</button> :
                <button className="btn btn-gruen mr-1" onClick={() => {}} disabled={true}>Eingabe ungültig</button>}
                <button className="btn btn-rot" onClick={this.props.revert}>Zurücksetzen</button>
               </span>}
                {this.props.error &&
                <button className="btn btn-rot" onClick={() => {alert(this.props.error)}}>Fehler anzeigen</button>}
                </span>}
        </div>
    }
}
