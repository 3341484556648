import PropTypes from "prop-types";
import * as React from "react";
import CountrySelect from "../../CountrySelect"

export class CountryField extends React.Component {

    static propTypes = {
        value: PropTypes.string,
        placeholder: PropTypes.string,
        enabled: PropTypes.bool,
        save: PropTypes.func,
        revert: PropTypes.func,
        onChange: PropTypes.func,
        className: PropTypes.string,
        highlight: PropTypes.string,
        tabIndex: PropTypes.number,
        type: PropTypes.string,
    };

    keyup(e) {
        if (e.key === "Enter") {
            this.props.save();
            e.target.blur();
            e.preventDefault();
        }
        if (e.key === "Escape") {
            this.props.revert();
            e.preventDefault();
        }
    }

    static highlight(reason) {
        if (reason === "updated") {
            return "bg-dst-logo-gruen-vorne";
        }
        if (reason === "error") {
            return "bg-red-lighter";
        }
        return "";
    }

    render() {
        let mr = "mr-2 "
        if (this.props.className.includes("mx-") || this.props.className.includes("mr-")) {
            mr = ""
        }
        return <div
            className={mr + this.props.className + " " + (CountryField.highlight(this.props.highlight))}>
            <CountrySelect
                value={this.props.value || ""}
                onChange={e => this.props.onChange(e.target.value)}
            />
        </div>
    }
}
