import * as React from 'react';
import {graphql} from "react-apollo"
import gql from "graphql-tag";
import PropTypes from "prop-types"
import {
    Preisstaffel_Eintrag_soll_entfernt_werden,
    Preisstaffel_Eintrag_soll_hinzugefuegt_werden,
    Preisstaffel_Kurstyp_soll_zugeordnet_werden,
    Preisstaffel_Kurstyp_Zuordnung_soll_entfernt_werden, Preisstaffel_soll_angelegt_werden,
} from "../Daten/FlugplanMessages.gen"
import {EintragListe} from "./PreisstaffelEintragListe"
import {KurstypenListe} from "./PreisstaffelKurstypenListe"

class _PreisstaffelListe extends React.Component {
    static contextTypes = {
        client: PropTypes.any,
    }

    async createRabattStaffel() {
        let name = prompt("Rabattstaffel-Name")
        if (name === null || name === "") {
            return
        }

        const mutation = gql`mutation Preisstaffel_soll_angelegt_werden($message: String!){Preisstaffel_soll_angelegt_werden(message: $message)}`
        const message = new Preisstaffel_soll_angelegt_werden(name)
        await this.send(mutation, message, "Preisstaffel_soll_angelegt_werden")
    }

    async send(mutation, message, mutationName) {
        let result = await this.context.client.mutate({
            mutation,
            variables: {message: JSON.stringify(message)},
        })
        const res = result.data[mutationName]
        if (res !== "ok") {
            alert("Es ist ein Fehler aufgetreten.")
        }
        this.props.data.refetch()
    }

    render() {
        let {data} = this.props;
        return <div>
            <table>
                <th>Name</th>
                <th>Rabatte</th>
                <th>Zugeordnete Kurstypen</th>
                <tbody>
                {data.preisstaffeln && data.preisstaffeln.map(item => <tr key={item.Id}>
                    <td>{item.name}</td>
                    <EintragListe eintraege={item.eintraege}
                                  addRabatt={async rabatt => {
                                      const mutation = gql`mutation Preisstaffel_Eintrag_soll_hinzugefuegt_werden($message: String!){Preisstaffel_Eintrag_soll_hinzugefuegt_werden(message: $message)}`
                                      const message = new Preisstaffel_Eintrag_soll_hinzugefuegt_werden(item.Id, rabatt.mindestteilnehmer, rabatt.rabatt, rabatt.kostenfreieTeilnehmer, rabatt.kombinierbar)
                                      await this.send(mutation, message, "Preisstaffel_Eintrag_soll_hinzugefuegt_werden")
                                  }}
                                  removeRabatt={async mindestteilnehmer => {
                                      const mutation = gql`mutation Preisstaffel_Eintrag_soll_entfernt_werden($message: String!){Preisstaffel_Eintrag_soll_entfernt_werden(message: $message)}`
                                      const message = new Preisstaffel_Eintrag_soll_entfernt_werden(item.Id, mindestteilnehmer)
                                      await this.send(mutation, message, "Preisstaffel_Eintrag_soll_entfernt_werden")
                                  }}/>
                    <KurstypenListe kurstypen={item.kurstypen}
                                    addKurstyp={async typId => {
                                        const mutation = gql`mutation Preisstaffel_Kurstyp_soll_zugeordnet_werden($message: String!){Preisstaffel_Kurstyp_soll_zugeordnet_werden(message: $message)}`
                                        const message = new Preisstaffel_Kurstyp_soll_zugeordnet_werden(item.Id, typId)
                                        await this.send(mutation, message, "Preisstaffel_Kurstyp_soll_zugeordnet_werden")
                                    }}
                                    removeKurstyp={async typId => {
                                        const mutation = gql`mutation Preisstaffel_Kurstyp_Zuordnung_soll_entfernt_werden($message: String!){Preisstaffel_Kurstyp_Zuordnung_soll_entfernt_werden(message: $message)}`
                                        const message = new Preisstaffel_Kurstyp_Zuordnung_soll_entfernt_werden(item.Id, typId)
                                        await this.send(mutation, message, "Preisstaffel_Kurstyp_Zuordnung_soll_entfernt_werden")
                                    }}/>
                </tr>)}
                </tbody>
            </table>
            <button className="ml-2 btn btn-gruen no-underline" onClick={async () => await this.createRabattStaffel()}>
                Neue Rabattstaffel anlegen
            </button>
        </div>
    }
}

export const PreisstaffelListe = graphql(gql`{preisstaffeln {
    Id name eintraege { mindestteilnehmer rabatt kostenfreieTeilnehmer kombinierbar } kurstypen { Id name }
}}`, {
    options: {
        fetchPolicy: "network-only",
    },
})(_PreisstaffelListe);