import PropTypes from "prop-types";
import * as React from "react";
import gql from "graphql-tag";
import {AutosuggestTheme} from "../../App"
import Autosuggest from "react-autosuggest"

const kontaktQuery = gql`query kontakte($id: Int){smallInvoiceKundenKontakte(id: $id){id,name,surname}}`

const getSuggestionValue = suggestion => suggestion;
const renderSuggestion = (suggestion) => (
    <div>{suggestion.name} {suggestion.surname}</div>
);

export class SmallInvoiceKundenkontaktField extends React.Component {
    static contextTypes = {client: PropTypes.any};

    static propTypes = {
        value: PropTypes.object,
        placeholder: PropTypes.string,
        enabled: PropTypes.bool,
        save: PropTypes.func,
        revert: PropTypes.func,
        onChange: PropTypes.func,
        className: PropTypes.string,
        highlight: PropTypes.string,
        tabIndex: PropTypes.number,
        type: PropTypes.string,
    };

    constructor(props, context) {
        super(props, context);

        this.state = {
            kunde: props.kunde,
            kontakt: props.value.kontakt,
            kontaktSuggestions: [],
            kontaktName: props.value.kontaktName,
            eventHandler: props.onChange || undefined,
        }

        this.handleSuggestionChange = this.handleSuggestionChange.bind(this);
        this.handleSuggestionFetchRequest = this.handleSuggestionFetchRequest.bind(this);
        this.handleSuggestionClearRequest = this.handleSuggestionClearRequest.bind(this);
        this.handleKeyUp = this.handleKeyUp.bind(this)
    }

    componentWillMount() {
        try {
            // noinspection JSIgnoredPromiseFromCall
            this.handleSuggestionFetchRequest({value: this.state.kontaktName})
        } catch (error) {
            // ignore
        }
    }

    handleKeyUp(e) {
        if (e.key === "Enter") {
            this.props.save();
            e.target.blur();
            e.preventDefault();
        }
        if (e.key === "Escape") {
            this.props.revert();
            e.preventDefault();
        }
    }

    static highlight(reason) {
        if (reason === "updated") {
            return "bg-dst-logo-gruen-vorne";
        }
        if (reason === "error") {
            return "bg-red-lighter";
        }
        return "";
    }

    render() {
        let mr = "mr-2 "
        if (this.props.className.includes("mx-") || this.props.className.includes("mr-")) {
            mr = ""
        }
        let {className} = this.props
        let classNames = className.split(" ")
        let newClassNames = []
        let width = ""
        for (let i = 0; i < classNames.length; i++) {
            const cn = classNames[i];
            if (/^w-\d\/\d\b/.test(cn)) {
                width = cn
            } else {
                newClassNames.push(cn)
            }
        }
        let regionFieldClasses = newClassNames.concat(SmallInvoiceKundenkontaktField.highlight(this.props.highlight)).reduce((prev, cur) => prev + " " + cur, "").trim()
        const inputProps = {
            placeholder: "Zum Suchen tippen...",
            value: this.state.kontaktName,
            onChange: (_, {newValue}) => this.setState({kontaktName: newValue.name ? newValue.name + " " + newValue.surname : newValue}),
            onKeyUp: this.handleKeyUp,
            className: regionFieldClasses + " leading-normal border w-full",
            name: "41a620ba", /* heißt so, weil Chrome sonst Autocompletion macht 🖕 */
            autoComplete: "off",
            "aria-autocomplete": "none",
        }

        return <div
            className={mr + " inline-block " + width}>
            <Autosuggest suggestions={this.state.kontaktSuggestions}
                         onSuggestionsFetchRequested={this.handleSuggestionFetchRequest}
                         onSuggestionsClearRequested={this.handleSuggestionClearRequest}
                         shouldRenderSuggestions={() => true}
                         getSuggestionValue={getSuggestionValue} theme={AutosuggestTheme}
                         onSuggestionSelected={(...args) => this.handleSuggestionChange(...args)}
                         renderSuggestion={renderSuggestion} inputProps={inputProps}/>
        </div>
    }

    async handleSuggestionFetchRequest({value}) {
        const inputValue = value.trim().toLowerCase();

        try {
            let result = await this.context.client.query({
                query: kontaktQuery,
                variables: {id: this.state.kunde}
            })

            const kunden = result.data.smallInvoiceKundenKontakte;

            let array = []
            if (inputValue !== "") {
                array = inputValue.split(' ')
            }

            const suggestions = kunden.filter(kd =>
                array.every(input =>
                    (kd.name + " " + kd.surname).toLowerCase().includes(input),
                ),
            );

            this.setState({
                kontaktSuggestions: suggestions,
            })
        } catch (error) {
            console.warn(error)
        }
    }

    handleSuggestionClearRequest() {
        this.setState({
            kontaktSuggestions: [],
        })
    }

    handleSuggestionChange(event, {suggestion}) {
        this.setState({
            kontakt: suggestion.id,
            kontaktName: suggestion.name + " " + suggestion.surname,
        })
        this.state.eventHandler && this.state.eventHandler({
            kontakt: suggestion.id,
            kontaktName: suggestion.name + " " + suggestion.surname,
        })
    }
}
