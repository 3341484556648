import * as React from "react"
import {graphql} from "react-apollo/index"
import gql from "graphql-tag";
import {Link} from "react-router-dom"
import {LinkButton, Loading, Vier04} from "./components"
import {NewsletterSetter} from "./Teilnahme/NewsletterSetter"
import KontaktAcEmailVerwaltung from "./Kontakt/KontaktAcEmailVerwaltung"
import {
    Kontakt_ActiveCampaign_Id_soll_entfernt_werden,
    Kontakt_ActiveCampaign_Sync_anfordern,
} from "./Daten/CheckinMessages.gen"
import PropTypes from "prop-types"

const Kontakt_ActiveCampaign_Sync_anfordern_Mutation = gql`mutation x($message: String!){Kontakt_ActiveCampaign_Sync_anfordern(message: $message)}`
const Kontakt_ActiveCampaign_Id_soll_entfernt_werden_Mutation = gql`mutation x($message: String!){Kontakt_ActiveCampaign_Id_soll_entfernt_werden(message: $message)}`

class KontaktDetails extends React.Component {
    static contextTypes = {
        client: PropTypes.any,
    }

    state = {
        resyncButtonDisabled: false,
    }

    render() {
        if (this.props.data.loading) {
            return <Loading/>
        }
        if (!this.props.data.kontakte || this.props.data.kontakte.length === 0) {
            return <Vier04/>
        }
        const {vorname, nachname, Id, newsletter, anrede, titel, firma, telefon, email, buchungen, teilnahmen, activeCampaignId, moeglicheDuplikate} = this.props.data.kontakte[0];
        return <div className="kontakt-container">
            <h2 className="heading">{anrede.text} {titel} {vorname} {nachname}</h2>
            <table className="w-full alternating-table">
                <tr>
                    <th>Firma</th>
                    <td>{firma}</td>
                </tr>
                <tr>
                    <th>E-Mail</th>
                    <td><a href={"mailto:" + email}
                           className="text-dst-logo-gruen-hinten hover:text-dst-logo-gruen-mitte">{email}</a></td>
                </tr>
                <tr>
                    <th>Telefon</th>
                    <td>
                        {telefon ?
                            <a href={"tel:" + telefon}
                               className="text-dst-logo-gruen-hinten hover:text-dst-logo-gruen-mitte">{telefon}</a> : "-"}
                    </td>
                </tr>
                <tr>
                    <th>Newsletter</th>
                    <td>
                        <NewsletterSetter newsletter={!!newsletter} kontaktId={Id} refetch={this.props.data.refetch}/>
                    </td>
                </tr>
                <tr>
                    <th>Active Campaign</th>
                    <td>
                        {activeCampaignId != null ?
                            <React.Fragment>
                                <a href={"https://dasscrumteam.activehosted.com/app/contacts/" + activeCampaignId}
                               target="_blank">zum Kontakt</a>
                                <LinkButton onClick={() => this.removeAcId()}>Verknüpfung löschen</LinkButton>
                            </React.Fragment>: "kein Eintrag"}<br/>
                        <LinkButton onClick={() => this.resync()} disabled={this.state.resyncButtonDisabled}>Sync erneut
                            versuchen
                        </LinkButton>
                    </td>
                </tr>
            </table>
            <KontaktBuchungen
                buchungen={buchungen}
                teilnahmen={teilnahmen}
            />
            <KontaktAcEmailVerwaltung Id={Id}/>
            {/*<MöglicheDuplikate moeglicheDuplikate={moeglicheDuplikate}/>*/}
        </div>
    }

    async removeAcId() {
        let kontaktId = this.props.data.kontakte[0].Id

        let message = new Kontakt_ActiveCampaign_Id_soll_entfernt_werden(kontaktId)
        let result = await this.context.client.mutate({
            mutation: Kontakt_ActiveCampaign_Id_soll_entfernt_werden_Mutation,
            variables: {message: JSON.stringify(message)},
        })
        const res = result.data[message.constructor.messageName]
        if (res !== "ok") {
            alert("Es ist ein Fehler aufgetreten.")
        }
        this.props.data.refetch()
    }

    async resync() {
        let kontaktId = this.props.data.kontakte[0].Id
        this.setState({resyncButtonDisabled: true})

        let message = new Kontakt_ActiveCampaign_Sync_anfordern(kontaktId)
        let result = await this.context.client.mutate({
            mutation: Kontakt_ActiveCampaign_Sync_anfordern_Mutation,
            variables: {message: JSON.stringify(message)},
        })
        const res = result.data[message.constructor.messageName]
        if (res !== "ok") {
            alert("Es ist ein Fehler aufgetreten.")
        }
        setTimeout(() => this.setState({resyncButtonDisabled: false}), 10000)
        this.props.data.refetch()
    }
}

const KontaktBuchungen = (props) => <div className="kontaktbuchungen-container">
    <h3>Buchungen</h3>
    <table className="w-full alternating-table">
        <thead className="text-left">
        <th className="text-left">Kurs</th>
        <th className="text-left">Teilnehmerzahl</th>
        </thead>
        <tbody>
        {props.buchungen.map(buchung => <tr>
            <td><Link to={"/kurstermine/" + buchung.kurstermin.Id}
                      className="text-dst-logo-gruen-hinten hover:text-dst-logo-gruen-mitte">{buchung.kurstermin.kursCode}</Link>
            </td>
            <td>{buchung.teilnehmerzahl}</td>
            <td><Link to={"/kurstermine/" + buchung.kurstermin.Id + "/buchung/" + buchung.Id}
                      className="text-dst-logo-gruen-hinten hover:text-dst-logo-gruen-mitte">zur Buchung</Link></td>
        </tr>)}
        </tbody>
    </table>
    <h3>Teilnahmen</h3>
    <table className="w-full alternating-table">
        <thead>
        <th className="text-left">Kurs</th>
        <th className="text-left">Verwaltungskontakt</th>
        </thead>
        <tbody>
        {props.teilnahmen.map(buchung => <tr>
            <td><Link to={"/kurstermine/" + buchung.kurstermin.Id}
                      className="text-dst-logo-gruen-hinten hover:text-dst-logo-gruen-mitte">{buchung.kurstermin.kursCode}</Link>
            </td>
            <td><Link to={"/kontakte/" + buchung.bucher.Id}
                      className="text-dst-logo-gruen-hinten hover:text-dst-logo-gruen-mitte">{buchung.bucher.vorname} {buchung.bucher.nachname}</Link> ({buchung.bucher.firma || "-"})
            </td>
            <td><Link to={"/kurstermine/" + buchung.kurstermin.Id + "/buchung/" + buchung.Id}
                      className="text-dst-logo-gruen-hinten hover:text-dst-logo-gruen-mitte">zur Buchung</Link></td>
        </tr>)}
        </tbody>
    </table>
</div>

const MöglicheDuplikate = (props) => <div className="duplikate-container">
    <h3>Mögliche Duplikate</h3>
    <table className="w-full alternating-table">
        <thead>
        <th className="text-left">Vorname</th>
        <th className="text-left">Nachname</th>
        <th className="text-left">Email</th>
        </thead>
        <tbody>
        {props.moeglicheDuplikate.map(kontakt => <tr>
            <td><Link to={"/kontakte/" + kontakt.Id}
                      className="text-dst-logo-gruen-hinten hover:text-dst-logo-gruen-mitte">{kontakt.vorname}</Link>
            </td>
            <td><Link to={"/kontakte/" + kontakt.Id}
                      className="text-dst-logo-gruen-hinten hover:text-dst-logo-gruen-mitte">{kontakt.nachname}</Link>
            </td>
            <td>{kontakt.email}</td>
            <td><LinkButton>Kontakte zusammenführen</LinkButton></td>
        </tr>)}
        </tbody>
    </table>
</div>

export default graphql(gql`query Kontakt($id: String!){kontakte(Id: $id){
    anrede{text,code},
    titel,
    vorname,
    nachname,
    firma,
    telefon,
    email,
    newsletter,
    buchungen { Id kurstermin{ Id kursCode } teilnehmerzahl },
    teilnahmen { Id kurstermin{ Id kursCode } teilnehmerzahl bucher { vorname nachname firma Id } },
    moeglicheDuplikate { Id vorname nachname email },
    activeCampaignId,
    Id
}}`, {
    options: ownProps => {
        return {
            fetchPolicy: "network-only",
            variables: {
                id: ownProps.match.params.id,
            },
            // pollInterval: 500,
        }
    },
})(KontaktDetails)