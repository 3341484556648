import {gql} from "apollo-boost"
import * as React from "react"
import PropTypes from "prop-types"
import {wrapGraphQL} from "../Infrastruktur/GraphQLQuery";


export const TrainerInfoDisplay = ({trainer}) => <a className="text-black no-underline" href={`/data/trainer/${trainer.Id}`}>{trainer.text}</a>;

TrainerInfoDisplay.propTypes =
    {
        trainer: PropTypes.shape(
            {
                Id: PropTypes.string.isRequired,
                text: PropTypes.string.isRequired
            }).isRequired
    };



export const TrainerDisplay = ({Id}) => wrapGraphQL(TrainerInfoDisplay, gql(`query trainer($Id:String) {trainer(Id:$Id){Id,text}}`), {Id});

TrainerDisplay.propTypes =
    {
        Id: PropTypes.string.isRequired
    };
