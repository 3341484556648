import * as React from "react"
import PropTypes from "prop-types"
import {gql} from "apollo-boost";


class GraphQLTest extends React.Component {
    constructor(props, context){
        super(props, context)
        this.state = {query:"", response:""}
    }

    run(e){
        this.setState({response:"loading..."})
        try{
            const query = gql(e.target.value)
            this.context.client.query({query, fetchPolicy:"network-only"}).then(({data}) => {
                this.setState({response:JSON.stringify(data, null, 2)})
            } ).catch(({error}) => {
                    this.setState({response:JSON.stringify(error, null, 2)})
                } )
        }
        catch(e){
            this.setState({response:"error: "+e})
        }

    }

    render(){
        return (<div>
            <div><textarea className="w-full h-32" onChange={ e=>this.run(e)} ></textarea></div>
            <div><pre>{this.state.response}</pre></div>
        </div>)
    }
}

GraphQLTest.contextTypes = {
    client: PropTypes.any
}

export default GraphQLTest