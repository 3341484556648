import * as React from "react"
import {graphql} from "react-apollo/index"
import gql from "graphql-tag";
import * as Reactable from "reactable"
import {Link} from "react-router-dom"
import {LinkButton, Loading, ResetButtonItem} from "./components"

import * as queryString from "query-string"


const Table = Reactable.Table
const Tr = Reactable.Tr
const Td = Reactable.Td
const Thead = Reactable.Thead
const Th = Reactable.Th

class KontaktListe extends React.Component {
    state = {
        filter: "",
    }

    constructor(props, context) {
        super(props, context)

        // this.state = this.getStateForQuery()

        this.filterTable = this.filterTable.bind(this)
    }

    // componentDidUpdate(prevProps) {
    //     if (prevProps.location !== this.props.location) {
    //         // neuen Querystring parsen
    //         this.setState(this.getStateForQuery, this.refetch)
    //     }
    // }

    // getStateForQuery() {
    //     let state = {...this.state}
    //     let query = queryString.parse(this.props.location.hash)
    //     if (query.filter) {
    //         state = {...state, filter: query.filter}
    //     }
    //     return state
    // }

    render() {
        return <div className="kontakt-container">
            <h2 className="heading">Kontakte
                <LinkButton title="Neu laden" onClick={() => this.props.data.refetch()}>
                    <span role="img" aria-label="Reload">🔄</span>
                </LinkButton>
            </h2>
            <div className="filter-container w-full relative mt-2">
                <input type="text" className="w-full border h-8 p-2" value={this.state.filter} autoFocus={true}
                       placeholder="Zum Filtern tippen..." onChange={this.filterTable}/>
                <button onClick={() => this.filterTable({target: {value: ""}})} className="absolute pin-r mr-2 h-8">
                    <ResetButtonItem/>
                </button>
            </div>
            <br/>
            {(this.props.data.loading || this.filterTimer !== null) &&
            <div className="absolute w-full h-full"><Loading/></div>}
            <Table className="w-full alternating-table" sortable={[
                'Vorname', 'Nachname', 'Firma',
            ]} filterable={['Vorname', 'Nachname', 'Firma']}
                   hideFilterInput
                   defaultSort={{column: 'Nachname', direction: 'asc'}}>
                <Thead className="text-left">
                <Th className="text-left" column="Vorname">Vorname</Th>
                <Th className="text-left" column="Nachname">Nachname</Th>
                <Th className="text-left" column="Firma">Firma</Th>
                <Th className="text-left w-32" column="Aktionen"/>
                </Thead>

                {this.props.data.kontaktepages && this.props.data.kontaktepages.page && this.props.data.kontaktepages.page.items && this.props.data.kontaktepages.page.items.map((kontakt, index) => KontaktListe.kontaktzeile(kontakt, index))}
            </Table>
            <br/>
            {this.props.data.kontaktepages && this.props.data.kontaktepages.page &&
            <TablePaginator for="Kontakte" pages={this.props.data.kontaktepages.pages}
                            page={this.props.data.kontaktepages.page.pageNumber}
                            prevPage={this.props.data.kontaktepages.page.previousPageAfterCursor}
                            nextPage={this.props.data.kontaktepages.page.nextPageAfterCursor}
                            change={after => this.props.data.refetch({
                                after,
                            })}/>}
        </div>
    }

    static kontaktzeile(kontakt) {
        return <Tr className={"border-0 leading-normal"}
                   key={JSON.stringify(kontakt)}>
            <Td column="Vorname" value={kontakt.vorname}><Link
                className="text-dst-logo-gruen-hinten hover:text-dst-logo-gruen-mitte"
                to={"/kontakte/" + kontakt.Id}>{kontakt.vorname}</Link></Td>
            <Td column="Nachname" value={kontakt.nachname}><Link
                className="text-dst-logo-gruen-hinten hover:text-dst-logo-gruen-mitte"
                to={"/kontakte/" + kontakt.Id}>{kontakt.nachname}</Link></Td>
            <Td column="Firma" value={kontakt.firma}>{kontakt.firma}</Td>
            <Td column="Aktionen" value="">
                {/*Mögliche Duplikate: {kontakt.moeglicheDuplikate.length}*/}
                {/*<div>*/}
                {/*<Link to={"/kurstermine/" + termin.Id + "/buchungen"}*/}
                {/*className="text-dst-logo-gruen-hinten hover:text-dst-logo-gruen-mitte">{termin.buchungen.count} Buchungen</Link><br/>*/}
                {/*<Link to={"/kurstermine/" + termin.Id + "/teilnehmer"}*/}
                {/*className="text-dst-logo-gruen-hinten hover:text-dst-logo-gruen-mitte">{termin.teilnehmer.count} Teilnehmer</Link>*/}
                {/*</div>*/}
            </Td>
        </Tr>
    }

    filterTimer = null

    filterTable(event) {
        const target = event.target;
        let value = target.value;

        if (this.filterTimer !== null) {
            window.clearTimeout(this.filterTimer)
            this.filterTimer = null
        }

        this.filterTimer = window.setTimeout(() => {
            this.props.data.refetch({
                forceFetch: true,
                filter: value,
            })
            this.filterTimer = null
        }, 500)

        this.setState({
            filter: value,
        })

        // this.setState({
        //     filter: value,
        // }, () => {
        //     let query = queryString.parse(this.props.location.hash)
        //     query.filter = value
        //     this.props.history.push("#" + queryString.stringify(query, {sort: false}))
        // });
    }
}

const TablePaginator = (props) => {
    const p = {className: "m-1"}
    const {pages, prevPage, nextPage, page, change} = props

    return <div className="my-0 mx-auto text-center">
        {prevPage && <span {...p}><LinkButton
            onClick={() => change(prevPage)}>&lt;&lt;</LinkButton></span>}
        {pages.map(curPage => {
                if (curPage.number === page) {
                    return <span {...p}>{curPage.number}</span>
                }
                if (pages.length > 10) {
                    if (Math.abs(curPage.number - page) === 5) {
                        return <span {...p}>...</span>
                    }
                    if (Math.abs(curPage.number - page) > 5
                        && curPage.number !== 1 && curPage.number !== pages[pages.length - 1].number) {
                        return null
                    }
                }
                return <span {...p}><LinkButton
                    onClick={() => change(curPage.after)}>{curPage.number}</LinkButton></span>
            },
        )}
        {nextPage && <span {...p}><LinkButton
            onClick={() => change(nextPage)}>&gt;&gt;</LinkButton></span>}
    </div>
};

export default graphql(gql`query Kontakte($filter: String, $sort: String, $after: String, $limit: Int){kontaktepages(filter: $filter, sort: $sort, after: $after, limit: $limit){
    totalItems
    pages {
        number
        after
    }
    page {
        pageNumber
        nextPageAfterCursor
        previousPageAfterCursor
        items {
            vorname,
            nachname,
            firma,
            Id,
            #    moeglicheDuplikate { Id }
        }
    }
}}`, {
    options: {
        fetchPolicy: "network-only",
        variables: {
            filter: null,
            sort: "nachname",
            after: null,
            limit: 100,
        },
    },
})(KontaktListe)