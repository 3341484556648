import PropTypes from "prop-types";
import * as React from "react";
import {UnicodeButton} from "./UnicodeButton";

export class InlineEditorControls extends React.Component{
    static propTypes = {
        save: PropTypes.func.isRequired,
        revert: PropTypes.func.isRequired,
        changed: PropTypes.bool.isRequired,
        valid: PropTypes.bool.isRequired,
        error: PropTypes.any,
    };

    render() {
        return <span style={{width:44}} tabIndex={-1}>
            { (this.props.changed)
            && <span>{!this.props.error
            && <span>{this.props.valid?<UnicodeButton onClick={this.props.save} value="☑️" />:<UnicodeButton onClick={()=>{}} disabled={true} value="◻️" />}<UnicodeButton onClick={this.props.revert} value="↩️" />
               </span>}
                {this.props.error && <UnicodeButton onClick={()=>{alert(this.props.error)}} value="‼️" />}
                </span>}
        </span>
    }
}
