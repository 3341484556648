import * as React from "react"
import PropTypes from "prop-types"


export class GraphQLQuery extends React.Component {
    static contextTypes = {client: PropTypes.any};

    constructor(props, context) {
        super(props, context);

        this.state={
            loading: true,
            data: null
        };

        this.expectStateChange=true;
    }

    componentDidMount(){
        this.runQuery();
    }

    componentDidUpdate(prevProps){
        if (this.expectStateChange) {
            this.expectStateChange=false;
            return;
        }
        this.runQuery();
    }

    runQuery(){
        this.expectStateChange=true;
        this.setState({ loading: true });

        this.context.client
            .query({
                query: this.props.query,
                variables: this.props.variables,
                fetchPolicy:"network-only"
            })
            .then(args => {
                const { data } = args;
                if (args.errors) {
                    const errors = JSON.stringify(args.errors, null, 2);
                    console.error("Fehler bei der Ausführung einer Query: \n" + errors);
                }
                if (!data) {
                    console.error(
                        "Eine Query hat keine Daten zurückgeliefert. Wahrscheinlich ein Syntaxfehler oder die Abfrage noch nicht implementierter Daten!"
                    );
                    console.log(this.props.query.loc.source.body);
                } else {
                    this.expectStateChange=true;
                    this.setState({data, loading: false});
                }
            })
            .catch(err => {
                console.error(err);
            });
    }

    render(){
        const data = Object.assign({refetch:()=>this.runQuery()}, (this.props.data || {}), { loading: this.state.loading }, this.state.data);

        if (data.loading && !this.props.enable_loading && this.state.data === null) {
            if (typeof this.props.loading === "function") return this.props.loading();
            return this.props.loading || null;
        }

        return React.createElement(this.props.component, data);
    }
}


export const wrapGraphQL = (component, query, variables) => <GraphQLQuery component={component} query={query} variables={variables} />;

