import * as React from "react"
import {graphql} from "react-apollo/index"
import gql from "graphql-tag";
import {LinkButton, Loading} from "../components"
import {Kontakt_ActiveCampaign_Email_soll_ausgeschlossen_werden} from "../Daten/CheckinMessages.gen"
import PropTypes from "prop-types"

const mutation = gql`mutation x($message: String!){Kontakt_ActiveCampaign_Email_soll_ausgeschlossen_werden(message: $message)}`

class KontaktAcEmailVerwaltung extends React.Component {
    static contextTypes = {
        client: PropTypes.any,
    }

    state = {
        selectedAddress: [],
        selectedExcludedAddress: "",
    }

    render() {
        if (this.props.data.loading) {
            return <Loading/>
        }
        if (!this.props.data.kontakte || this.props.data.kontakte.length === 0) {
            return null
        }
        const {alleEmailAdressen, activeCampaignAusgeschlosseneAdressen} = this.props.data.kontakte[0];
        return <table className="w-full">
            <tr>
                <td className="w-1/3">
                    <select className="w-full" size={5} multiple={true} value={this.state.selectedAddress}
                            onChange={val => this.setState({selectedAddress: [val.target.value]})}>
                        {/*<option disabled={true} selected={true}>--Bitte auswählen--</option>*/}
                        {alleEmailAdressen.map(adresse => <option value={adresse} key={adresse}>{adresse}</option>)}
                    </select>
                </td>
                <td className="w-1/3 text-center">
                    <LinkButton onClick={() => this.moveRight()}>&gt;&gt;</LinkButton>
                    <br/>
                    {/*<LinkButton onClick={() => this.moveLeft()}>&gt;&gt;</LinkButton>*/}
                    {/*<br/>*/}
                </td>
                <td className="w-1/3">
                    <select className="w-full" size={5} disabled={true} value={this.state.selectedExcludedAddress}
                            onChange={val => this.setState({selectedExcluded: val.target.value})}>
                        {activeCampaignAusgeschlosseneAdressen.map(adresse => <option selected={false}
                                                                                      key={adresse}>{adresse}</option>)}
                    </select>
                </td>
            </tr>
        </table>
    }

    moveLeft() {

    }

    async moveRight() {
        let adresse = this.state.selectedAddress[0]
        let kontaktId = this.props.Id

        if (adresse === null) {
            alert("Bitte eine Adresse auswählen")
            return
        }

        let message = new Kontakt_ActiveCampaign_Email_soll_ausgeschlossen_werden(kontaktId, adresse)
        let result = await this.context.client.mutate({
            mutation,
            variables: {message: JSON.stringify(message)},
        })
        const res = result.data[message.constructor.messageName]
        if (res !== "ok") {
            alert("Es ist ein Fehler aufgetreten.")
        }
        this.props.data.refetch()
    }
}

export default graphql(gql`query Kontakt($id: String!){kontakte(Id: $id){
    alleEmailAdressen, activeCampaignAusgeschlosseneAdressen, Id
}}`, {
    options: ownProps => {
        return {
            fetchPolicy: "network-only",
            variables: {
                id: ownProps.Id,
            },
            // pollInterval: 500,
        }
    },
})(KontaktAcEmailVerwaltung)