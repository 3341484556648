import PropTypes from "prop-types";
import * as React from "react";
import {graphql} from "react-apollo"
import gql from "graphql-tag"

export class Dropdown extends React.Component {

    static propTypes = {
        value: PropTypes.string,
        placeholder: PropTypes.string,
        enabled: PropTypes.bool,
        save: PropTypes.func,
        revert: PropTypes.func,
        onChange: PropTypes.func,
        className: PropTypes.string,
        highlight: PropTypes.string,
        tabIndex: PropTypes.number,
        type: PropTypes.string,
        options: PropTypes.arrayOf(PropTypes.shape({
            title: PropTypes.string.isRequired,
            value: PropTypes.string.isRequired,
        })).isRequired,
    };

    keyup(e) {
        if (e.key === "Enter") {
            this.props.save();
            e.target.blur();
            e.preventDefault();
        }
        if (e.key === "Escape") {
            this.props.revert();
            e.preventDefault();
        }
    }

    static highlight(reason) {
        if (reason === "updated") {
            return "bg-dst-logo-gruen-vorne";
        }
        if (reason === "error") {
            return "bg-red-lighter";
        }
        return "";
    }

    render() {
        return <select className={"mr-2 " + this.props.className + " " + (Dropdown.highlight(this.props.highlight))}
                       value={this.props.value || ""}
                       placeholder={this.props.placeholder}
                       onChange={e => this.props.onChange(e.target.value)}
                       onKeyUp={(e) => this.keyup(e)}
                       tabIndex={this.props.tabIndex}
        >
            {this.props.options.map(option => <option key={option.value.toString()}
                                                      value={option.value.toString()}>{option.title}</option>)}
        </select>
    }
}

const GAnredeDropdown = (props) => {
    if (props.data && !props.data.loading && !props.data.errors) {
        return <Dropdown {...props} options={props.data.anreden}/>
    }
    return null
}

export const AnredeDropdown = graphql(gql`{anreden{value: code,title: text}}`, {
    options: {
        fetchPolicy: "network-only",
    },
})(GAnredeDropdown)