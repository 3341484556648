import PropTypes from "prop-types";
import * as React from "react";
import LocationField from "../../LocationField"

export class LocationFieldWrapper extends React.Component {

    static propTypes = {
        value: PropTypes.string,
        placeholder: PropTypes.string,
        enabled: PropTypes.bool,
        save: PropTypes.func,
        revert: PropTypes.func,
        onChange: PropTypes.func,
        className: PropTypes.string,
        highlight: PropTypes.string,
        tabIndex: PropTypes.number,
        type: PropTypes.string,
    };

    keyup(e) {
        if (e.key === "Enter") {
            this.props.save();
            e.target.blur();
            e.preventDefault();
        }
        if (e.key === "Escape") {
            this.props.revert();
            e.preventDefault();
        }
    }

    static highlight(reason) {
        if (reason === "updated") {
            return "bg-dst-logo-gruen-vorne";
        }
        if (reason === "error") {
            return "bg-red-lighter";
        }
        return "";
    }

    render() {
        let mr = "mr-2 "
        if (this.props.className.includes("mx-") || this.props.className.includes("mr-")) {
            mr = ""
        }
        let {className} = this.props
        let classNames = className.split(" ")
        let newClassNames = []
        let width = ""
        for (let i = 0; i < classNames.length; i++) {
            const cn = classNames[i];
            if (/^w-\d\/\d\b/.test(cn)) {
                width = cn
            } else {
                newClassNames.push(cn)
            }
        }
        let regionName = null
        for (let i = 0; i < this.props.options.length; i++) {
            const option = this.props.options[i];
            if (option.title === "region") {
                regionName = option.value
            }
        }
        let regionFieldClasses = newClassNames.concat(LocationFieldWrapper.highlight(this.props.highlight)).reduce((prev, cur) => prev + " " + cur, "").trim()
        return <div
            className={mr + " inline-block " + width}>
            <LocationField
                regionName={regionName}
                className={regionFieldClasses}
                value={this.props.value ? this.props.value.Id : ""}
                textValue={this.props.value ? this.props.value.name : ""}
                onChange={e => this.props.onChange({Id: e.location, name: e.locationName})}
            />
        </div>
    }
}
