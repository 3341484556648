import PropTypes from "prop-types";
import {gql} from "apollo-boost/lib/index";
import * as React from "react";

export class Editor extends React.Component{

    static contextTypes = {
        client: PropTypes.any, // Apollo Client
    };

    static childContextTypes = {
        editor: PropTypes.any,
    };

    // ACHTUNG: BEI Änderung auch den Konstruktor anpassen
    static propTypes = {
        save: PropTypes.func.isRequired,
        validate: PropTypes.func,
        refetch: PropTypes.func,
        resultHandler: PropTypes.func,
    };

    getChildContext(){
        return {editor:this};
    }

    constructor(props, context){
        super(props, context);
        this.state = {};

        // TODO: folgende Zeile fixen und wiederverwenden
        // const propkeys = Object.keys(Editor.propTypes);
        const propkeys = ['save', 'validate', 'refetch', 'resultHandler']
        const keys = Object.keys(props).filter(key => propkeys.indexOf(key)===-1);

        let values = []
        for(const key of keys) {
            this.state[key] = this.props[key];
            values[key] = this.props[key]
        }
        if (this.props.validate) {
            this.state.valid = this.props.validate(values)
        } else {
            this.state.valid = true
        }
        this.state.changed = false;
        this.state.keys = keys;

        this.get = this.get.bind(this);
        this.set = this.set.bind(this);
        this.reset = this.reset.bind(this);
        this.save = this.save.bind(this);
        this.revert = this.revert.bind(this);
        this.fail = this.fail.bind(this);
        this.registerField = this.registerField.bind(this);
        this.unRegisterField = this.unRegisterField.bind(this);
        this.registerControls = this.registerControls.bind(this);
        this.unRegisterControls = this.unRegisterControls.bind(this);
    }

    fields = {};

    registerField(id, field) {
        this.fields[id] = field;
    }

    unRegisterField(id) {
        delete this.fields[id];
    }

    controls = [];

    registerControls(control) {
        this.controls.push(control);
    }

    unRegisterControls(control) {
        delete this.controls[this.controls.indexOf(control)];
    }

    componentDidUpdate(prevProps, prevState){
        const state = {}
        let update = false
        for (const k of this.state.keys){
            if (this.props[k] !== prevProps[k]){
                update = true
                state[k] = this.props[k]
                if (this.fields[k]) this.fields[k].highlight("updated")
            }
        }


        if (update) {
            this.setState(state);
        }

        if (this.state.changed!==prevState.changed) this.controls.forEach(ctrl => ctrl.setChanged(this.state.changed));
        if (this.state.error!==prevState.error) this.controls.forEach(ctrl => ctrl.setError(this.state.error));
        if (this.state.valid!==prevState.valid) this.controls.forEach(ctrl => ctrl.setValid(this.state.valid));
    }

    get(key) {
        return this.state[key]
    }

    values() {
        let result = {};
        for (const k of this.state.keys) result[k] = this.state[k]
        return result
    }

    set(key,value){
        let changed = this.props[key] !== value;
        for (const k of this.state.keys) changed = changed || ((key!==k) && (this.props[k] !== this.state[k]))

        if (changed && this.state.error){
            this.setState({error:null});
        }

        let valid = true;

        if (this.props.validate) {
            let validation = this.values();
            validation[key]=value;
            valid = this.props.validate(validation);
        }

        this.setState({
            [key]: value,
            changed,
            valid
        })
    }

    reset(key){
        this.set(key, this.props[key]);
    }

    save(){
        if (!this.state.valid) return;

        const value = this.values();

        const refetch = this.props.refetch;
        this.setState({saving:true});
        const message = this.props.save(value);
        const mutation = gql(`mutation test($message: String!){${message.constructor.messageName}(message:$message)}`)
        this.context.client.mutate({ mutation, variables: {message: JSON.stringify(message)}}).then(
            result=>{
                this.setState({saving:false});
                if ((this.props.resultHandler && this.props.resultHandler(result)) ||
                    (!this.props.resultHandler && result.data && result.data[message.constructor.messageName]==="ok")) {
                    this.setState({changed:false});
                    if (refetch) {
                        let data = refetch();
                        if (data) {
                            this.revert()
                        }
                    }
                } else this.fail(result);
            }
        ).catch(this.fail)
    }

    revert(){
        const state = {saving:false, changed:false};
        for(const key of this.state.keys) state[key] = this.props[key];
        this.setState(state);
    }

    fail(error) {
        console.log(error);
        this.setState({saving:false, error: error});
        for (const k of this.state.keys) {
            if (this.fields[k]) this.fields[k].highlight("error");
        }
    }

    render(){
        return this.props.children;
    }
}