import React from "react"
import PropTypes from "prop-types";
import {Bemerkung} from "./Bemerkung"
import {BemerkungEditor} from "./BemerkungEditor"

export const BemerkungListe = ({bemerkungen, hasEditor, save, refetch, hideTitle}) => {
    bemerkungen = bemerkungen.slice(0)
    bemerkungen.sort((a, b) => {
        let adate = Date.parse(a.erstellt.isoString)
        let bdate = Date.parse(b.erstellt.isoString)
        return adate < bdate ? 1 : (adate > bdate ? -1 : 0)
    })
    return <div className="mt-4">
        {hideTitle || <h2 className="heading">Bemerkungen</h2>}
        {bemerkungen.map((bemerkung) => <Bemerkung bemerkung={bemerkung} key={bemerkung.Id}/>)}
        {hasEditor && <BemerkungEditor startValue={""} save={save} refetch={refetch}/>}
    </div>
}

BemerkungListe.propTypes = {
    bemerkungen: PropTypes.arrayOf(PropTypes.shape({
        Id: PropTypes.string.isRequired,
        text: PropTypes.string.isRequired,
        autor: PropTypes.shape({
            name: PropTypes.string.isRequired,
        }).isRequired,
        erstellt: PropTypes.shape({
            humanReadable: PropTypes.string.isRequired,
        }).isRequired,
    })).isRequired,
    hasEditor: PropTypes.bool,
    save: PropTypes.func,
    refetch: PropTypes.func,
    hideTitle: PropTypes.bool,
}

BemerkungListe.defaultProps = {
    hasEditor: false,
    hideTitle: false,
}