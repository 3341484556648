import {gql} from "apollo-boost"
import * as React from "react"
import Autosuggest from "react-autosuggest"
import PropTypes from "prop-types"
import {AutosuggestTheme} from "../App"

const spracheQuery = gql`{sprachen{code,name}}`

const getSuggestionValue = suggestion => suggestion;
const renderSuggestion = (suggestion) => (
    <div>{suggestion.name}</div>
);

class SpracheField extends React.Component {
    static contextTypes = {client: PropTypes.any};
    static propTypes = {
        value: PropTypes.string.isRequired,
        textValue: PropTypes.string,
        onChange: PropTypes.func,
        onKeyUp: PropTypes.func,
    }

    constructor(props, context) {
        super(props, context);

        this.state = {
            sprache: props.value,
            locationSuggestions: [],
            spracheName: props.textValue || "",
            eventHandler: props.onChange || undefined,
        }

        this.handleSuggestionChange = this.handleSuggestionChange.bind(this);
        this.handleSuggestionFetchRequest = this.handleSuggestionFetchRequest.bind(this);
        this.handleSuggestionClearRequest = this.handleSuggestionClearRequest.bind(this);
        this.handleKeyUp = this.handleKeyUp.bind(this)
    }

    handleKeyPress(event) {
        if (event.key === 'Enter') {
            event.preventDefault();
        }
    }

    handleKeyUp(event) {
        if (event.key === 'Enter') {
            event.preventDefault();
        } else {
            this.props.onKeyUp && this.props.onKeyUp(event)
        }
    }

    componentWillMount() {
        try {
            // noinspection JSIgnoredPromiseFromCall
            this.handleSuggestionFetchRequest({value: this.state.spracheName})
        } catch (error) {
            // ignore
        }
    }

    render() {
        const inputProps = {
            placeholder: "Zum Suchen tippen...",
            value: this.state.spracheName,
            onChange: this.handleSuggestionChange,
            onKeyPress: this.handleKeyPress,
            onKeyUp: this.handleKeyUp,
            className: "leading-normal border w-full",
            name: "3accbd9a", /* heißt so, weil Chrome sonst Autocompletion macht 🖕 */
            autoComplete: "off",
            "aria-autocomplete": "none",
        }

        return <Autosuggest suggestions={this.state.locationSuggestions}
                            onSuggestionsFetchRequested={this.handleSuggestionFetchRequest}
                            onSuggestionsClearRequested={this.handleSuggestionClearRequest}
                            shouldRenderSuggestions={() => true}
                            getSuggestionValue={getSuggestionValue} theme={AutosuggestTheme}
                            renderSuggestion={renderSuggestion} inputProps={inputProps}/>
    }

    async handleSuggestionFetchRequest({value}) {
        const inputValue = value.trim().toLowerCase();

        try {
            let result = await this.context.client.query({
                query: spracheQuery,
            })

            const sprachen = result.data.sprachen;

            const suggestions = sprachen.filter(sprache =>
                inputValue.split(' ').every(input =>
                    sprache.name.toLowerCase().includes(input),
                ),
            );

            this.setState({
                locationSuggestions: suggestions,
            })
        } catch (error) {
            alert("couldn't query: " + error);
        }
    }

    handleSuggestionClearRequest() {
        this.setState({
            locationSuggestions: [],
        })
    }

    handleSuggestionChange(event, {newValue}) {
        if (typeof newValue === 'string' || newValue instanceof String) {
            this.setState({
                spracheName: newValue,
                sprache: null,
            })
            this.state.eventHandler && this.state.eventHandler({
                spracheName: newValue.name,
                sprache: null,
            })
        } else {
            this.setState({
                spracheName: newValue.name,
                sprache: newValue.code,
            })
            this.state.eventHandler && this.state.eventHandler({
                spracheName: newValue.name,
                sprache: newValue.code,
            })
        }
    }
}

export default SpracheField