import * as React from "react"
import PropTypes from "prop-types"

export class PreisstaffelCreator extends React.Component {
    static contextTypes = {client: PropTypes.any};

    static propTypes = {
        onSave: PropTypes.func.isRequired,
        onCancel: PropTypes.func.isRequired,
    }

    state = {
        mindestteilnehmer: 0,
        rabatt: 0,
        kostenfreieTeilnehmer: 0,
        kombinierbar: true,
    }

    handleKeyUp(event) {
        if (event.key === 'Escape') {
            this.props.onCancel();
            event.preventDefault();
        }
    }

    handleChange(event) {
        this.setState({
            [event.target.name]: parseInt(event.target.value, 10),
        });
    }

    handleSaveClick() {
        let {mindestteilnehmer, rabatt, kostenfreieTeilnehmer, kombinierbar} = this.state

        if (mindestteilnehmer < 0 || rabatt < 0) {
            alert("Bitte positive Werte angeben")
            return
        }

        if (rabatt > 100) {
            alert("Rabatt kann nur ein Prozentbetrag sein")
            return
        }

        if (rabatt > 0 && kostenfreieTeilnehmer > 0) {
            alert("Es kann nur entweder ein prozentualer Rabatt oder eine Anzahl kostenloser Teilnehmer" +
                " angegeben werden.")
            return
        }

        rabatt = rabatt > 0 ? rabatt : null
        kostenfreieTeilnehmer = kostenfreieTeilnehmer > 0 ? kostenfreieTeilnehmer : null

        this.props.onSave({mindestteilnehmer, rabatt, kostenfreieTeilnehmer, kombinierbar})
    }

    render() {
        let {mindestteilnehmer, rabatt, kostenfreieTeilnehmer, kombinierbar} = this.state
        return <div>
            Teilnehmer >= <input type="number" min="0" max="999" step="1" name="mindestteilnehmer"
                                 value={mindestteilnehmer} onKeyUp={(e) => this.handleKeyUp(e)}
                                 onChange={(event) => this.handleChange(event)}/>&nbsp;
            Rabatt:
            <input type="number" min="0" max="100" step="1" name="rabatt" value={rabatt}
                   onKeyUp={(e) => this.handleKeyUp(e)}
                   onChange={(event) => this.handleChange(event)}/>&nbsp;%
            Kostenfreie Teilnehmer:
            <input type="number" min="0" max="999" step="1" name="kostenfreieTeilnehmer" value={kostenfreieTeilnehmer}
                   onKeyUp={(e) => this.handleKeyUp(e)}
                   onChange={(event) => this.handleChange(event)}/>&nbsp;
            Kombinierbar mit Frühbucher:
            <input type="checkbox" onChange={e => this.setState({kombinierbar: !kombinierbar})} checked={kombinierbar}
                   name="kombinierbar"/>&nbsp;
            <button className="text-dst-logo-gruen-mitte hover:text-dst-logo-gruen-hinten underline"
                    onClick={() => this.handleSaveClick()}>Hinzufügen
            </button>
        </div>;
    }
}
