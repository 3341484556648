import * as React from "react";
import {Kurstermin_soll_geplant_werden} from "./Daten/FlugplanMessages.gen";
import {gql} from "apollo-boost";
import PropTypes from "prop-types"
import {InputField, Label, Loading, StatusButton, SubmitButton} from "./components"
import {Redirect} from "react-router-dom"
import KurstypSelect from "./KurstypSelect"
import RegionField from "./RegionField"
import moment from "moment"
import 'moment/locale/de'
import 'react-dates/initialize'
import 'react-dates/lib/css/_datepicker.css';
import {SingleDatePicker} from 'react-dates'
import SpracheField from "./Fields/SpracheField"


const mutation = gql`mutation terminAnlegen($message: String!){Kurstermin_soll_geplant_werden(message: $message, type: "Kurstermin_soll_angelegt_werden")}`;

class KursterminAnlegen extends React.Component {
    static contextTypes = {client: PropTypes.any};

    constructor(props, context) {
        super(props, context);

        moment.locale('de')

        this.state = {
            beginn: moment(),
            dauer: 3,
            typ: null,
            region: null,
            locationSuggestions: [],
            regionName: "",
            teilnehmerKreis: "Öffentlich",
            sprache: {
                code: "DE", name: "Deutsch",
            },
            success: false,
            id: null,
            dateFocused: false,
            saving: false,
            saveButtonEnabled: false,
        }

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handlePreisChange = this.handlePreisChange.bind(this);
        this.handleWaehrungChange = this.handleWaehrungChange.bind(this);
        this.handleRegionChange = this.handleRegionChange.bind(this);
        this.handleSpracheChange = this.handleSpracheChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    render() {
        return <div className="kurstermin-anlegen-container">
            <h2 className="heading">Kurstermin anlegen</h2>
            <form onSubmit={this.handleSubmit} className="termineingabe">
                <div className="flex justify-between">
                    {/*<InputField name="beginn" type="date" title="Beginn" value={this.state.beginn}*/}
                    {/*onChange={this.handleInputChange} divClassName="w-2/5"/>*/}
                    <div className="w-2/5">
                        <Label name="a9b6815c-82e7-48c4-8337-f396e4a9b0dd" title="Beginn"/><br/>
                        <SingleDatePicker
                            date={this.state.beginn} required={true}
                            onDateChange={date => this.setState({beginn: date})}
                            focused={this.state.dateFocused}
                            onFocusChange={({focused}) => this.setState({dateFocused: focused})}
                            id="a9b6815c-82e7-48c4-8337-f396e4a9b0dd"
                        />
                    </div>
                    <InputField name="dauer" type="number" title="Dauer in Tagen" value={this.state.dauer}
                                onChange={this.handleInputChange} divClassName="w-2/5"/>
                </div>
                <div className="flex justify-between">
                    <div className="w-2/5">
                        <KurstypSelect name="typ" title="Kurstyp" value={this.state.typ}
                                       onChange={this.handleInputChange}/>
                    </div>
                    <div className="w-2/5">
                        <Label name="teilnehmerKreis" title="Teilnehmerkreis"/><br/>
                        <StatusButton status={{text: "Öffentlich"}} active={this.state.teilnehmerKreis === "Öffentlich"}
                                      onClick={() => this.setState({teilnehmerKreis: "Öffentlich"})} className="mr-2"/>
                        <StatusButton status={{text: "Inhouse"}} active={this.state.teilnehmerKreis === "Inhouse"}
                                      onClick={() => this.setState({teilnehmerKreis: "Inhouse"})}/>
                    </div>
                </div>
                <Label name="3accbd9a" title="Region"/><br/>
                <RegionField onChange={this.handleRegionChange} callOnChangeOnNoRegion={true}/>
                <Label name="84c39b0e" title="Sprache"/><br/>
                <SpracheField value={this.state.sprache.code} textValue={this.state.sprache.name}
                              onChange={this.handleSpracheChange}/>
                {this.state.saving ? <Loading/> :
                    <SubmitButton disabled={!this.state.saveButtonEnabled} title="Anlegen"/>}
            </form>
            {
                this.state.success &&
                <Redirect to={"/kurstermine" + (this.state.id !== null ? "/" + this.state.id : "")} push/>
            }
        </div>;
    }

    handleRegionChange(event) {
        const {region, regionName} = event
        this.setState({
            region,
            regionName,
        }, () => this.updateSaveButton())
    }

    handleSpracheChange(event) {
        const {sprache, spracheName} = event
        this.setState({
            sprache: {
                code: sprache,
                name: spracheName,
            },
        }, () => this.updateSaveButton())
    }

    updateSaveButton() {
        this.setState({
            saveButtonEnabled: this.state.sprache.code !== null
                && this.state.region !== null
                && this.state.typ !== null,
        })
    }

    handleInputChange(event) {
        const target = event.target;
        let value = target.type === 'checkbox' ? target.checked : target.value;
        value = target.type === 'number' ? parseInt(value, 10) : value;
        const name = target.name;

        this.setState({
            [name]: value,
        }, () => this.updateSaveButton());
    }

    handlePreisChange(event) {
        const waehrung = this.state.preis.waehrung;
        this.setState({preis: {wert: event.target.value, waehrung: waehrung}});
    }

    handleWaehrungChange(event) {
        const wert = this.state.preis.wert;
        this.setState({
            preis: {
                wert: wert, waehrung: event.target.value,
            },
        });
    }

    async handleSubmit(event) {
        event.preventDefault();
        this.setState({saving: true})
        if (this.state.titel === "" || this.state.beginn === "") {
            alert("Bitte einen Titel und Beginn angeben."); // todo: alle Felder abfragen
            return;
        }
        let id = null
        try {
            let result = await this.context.client.query({
                query: gql`{id}`,
                fetchPolicy: "network-only",
            })
            if (result.data.id) {
                id = result.data.id
            }
        } catch (error) {
            console.error(error)
        }
        const kurstermin = new Kurstermin_soll_geplant_werden(
            this.state.beginn.format("YYYY-MM-DD"),
            this.state.dauer,
            this.state.typ,
            this.state.region,
            this.state.sprache.code,
            this.state.teilnehmerKreis,
            id,
        );
        const message = JSON.stringify(kurstermin);
        try {
            let result = await this.context.client.mutate({
                mutation,
                variables: {message: message},
                refetchQueries: () => [{query: gql`{kurstermine{titel,beginn,Id}}`}],
            })
            this.setState({
                success: result.data.Kurstermin_soll_geplant_werden === "ok",
                saving: false,
                id,
            })
            if (!this.state.success) {
                alert("Es ist ein Fehler aufgetreten: " + result.data.Kurstermin_soll_geplant_werden)
            }
        } catch (error) {
            alert("submitting failed: " + error);
        }
    }
}

export default KursterminAnlegen;