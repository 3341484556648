/*gen*/class Kurstermin_Status_soll_Geplant_werden {
/*gen*/    static messageName = "Kurstermin_Status_soll_Geplant_werden"
/*gen*/	kurstermin_Id
/*gen*/	constructor(kurstermin_Id) {
/*gen*/		
/*gen*/			
/*gen*/			this.kurstermin_Id = kurstermin_Id
/*gen*/		
/*gen*/	}
/*gen*/}
/*gen*/class Kurstermin_Status_soll_Veroeffentlicht_werden {
/*gen*/    static messageName = "Kurstermin_Status_soll_Veroeffentlicht_werden"
/*gen*/	kurstermin_Id
/*gen*/	constructor(kurstermin_Id) {
/*gen*/		
/*gen*/			
/*gen*/			this.kurstermin_Id = kurstermin_Id
/*gen*/		
/*gen*/	}
/*gen*/}
/*gen*/class Kurstermin_Status_soll_Wenige_Restplaetze_werden {
/*gen*/    static messageName = "Kurstermin_Status_soll_Wenige_Restplaetze_werden"
/*gen*/	kurstermin_Id
/*gen*/	constructor(kurstermin_Id) {
/*gen*/		
/*gen*/			
/*gen*/			this.kurstermin_Id = kurstermin_Id
/*gen*/		
/*gen*/	}
/*gen*/}
/*gen*/class Kurstermin_Status_soll_Ausverkauft_werden {
/*gen*/    static messageName = "Kurstermin_Status_soll_Ausverkauft_werden"
/*gen*/	kurstermin_Id
/*gen*/	constructor(kurstermin_Id) {
/*gen*/		
/*gen*/			
/*gen*/			this.kurstermin_Id = kurstermin_Id
/*gen*/		
/*gen*/	}
/*gen*/}
/*gen*/class Kurstermin_Status_soll_Wird_durchgefuehrt_werden {
/*gen*/    static messageName = "Kurstermin_Status_soll_Wird_durchgefuehrt_werden"
/*gen*/	kurstermin_Id
/*gen*/	constructor(kurstermin_Id) {
/*gen*/		
/*gen*/			
/*gen*/			this.kurstermin_Id = kurstermin_Id
/*gen*/		
/*gen*/	}
/*gen*/}
/*gen*/class Kurstermin_Status_soll_Durchgefuehrt_werden {
/*gen*/    static messageName = "Kurstermin_Status_soll_Durchgefuehrt_werden"
/*gen*/	kurstermin_Id
/*gen*/	constructor(kurstermin_Id) {
/*gen*/		
/*gen*/			
/*gen*/			this.kurstermin_Id = kurstermin_Id
/*gen*/		
/*gen*/	}
/*gen*/}
/*gen*/class Kurstermin_Status_soll_Abgeschlossen_werden {
/*gen*/    static messageName = "Kurstermin_Status_soll_Abgeschlossen_werden"
/*gen*/	kurstermin_Id
/*gen*/	constructor(kurstermin_Id) {
/*gen*/		
/*gen*/			
/*gen*/			this.kurstermin_Id = kurstermin_Id
/*gen*/		
/*gen*/	}
/*gen*/}
/*gen*/class Kurstermin_Status_soll_Abgebrochen_werden {
/*gen*/    static messageName = "Kurstermin_Status_soll_Abgebrochen_werden"
/*gen*/	kurstermin_Id
/*gen*/	constructor(kurstermin_Id) {
/*gen*/		
/*gen*/			
/*gen*/			this.kurstermin_Id = kurstermin_Id
/*gen*/		
/*gen*/	}
/*gen*/}
/*gen*/class Trainer_soll_aufgenommen_werden {
/*gen*/    static messageName = "Trainer_soll_aufgenommen_werden"
/*gen*/	titel
/*gen*/	nachname
/*gen*/	vornamen
/*gen*/	constructor(titel, nachname, vornamen) {
/*gen*/		
/*gen*/			
/*gen*/			this.titel = titel
/*gen*/		
/*gen*/			
/*gen*/			this.nachname = nachname
/*gen*/		
/*gen*/			
/*gen*/			this.vornamen = vornamen
/*gen*/		
/*gen*/	}
/*gen*/}
/*gen*/class Trainer_Name_soll_geaendert_werden {
/*gen*/    static messageName = "Trainer_Name_soll_geaendert_werden"
/*gen*/	trainer_Id
/*gen*/	titel
/*gen*/	nachname
/*gen*/	vornamen
/*gen*/	constructor(trainer_Id, titel, nachname, vornamen) {
/*gen*/		
/*gen*/			
/*gen*/			this.trainer_Id = trainer_Id
/*gen*/		
/*gen*/			
/*gen*/			this.titel = titel
/*gen*/		
/*gen*/			
/*gen*/			this.nachname = nachname
/*gen*/		
/*gen*/			
/*gen*/			this.vornamen = vornamen
/*gen*/		
/*gen*/	}
/*gen*/}
/*gen*/class Trainer_CMS_Code_soll_gesetzt_werden {
/*gen*/    static messageName = "Trainer_CMS_Code_soll_gesetzt_werden"
/*gen*/	trainer_Id
/*gen*/	cMS_Code
/*gen*/	constructor(trainer_Id, cMS_Code) {
/*gen*/		
/*gen*/			
/*gen*/			this.trainer_Id = trainer_Id
/*gen*/		
/*gen*/			
/*gen*/			this.cMS_Code = cMS_Code
/*gen*/		
/*gen*/	}
/*gen*/}
/*gen*/class Trainer_Email_soll_eingegeben_werden {
/*gen*/    static messageName = "Trainer_Email_soll_eingegeben_werden"
/*gen*/	trainer_Id
/*gen*/	email
/*gen*/	constructor(trainer_Id, email) {
/*gen*/		
/*gen*/			
/*gen*/			this.trainer_Id = trainer_Id
/*gen*/		
/*gen*/			
/*gen*/			this.email = email
/*gen*/		
/*gen*/	}
/*gen*/}
/*gen*/class Trainer_Mobiltelefon_soll_eingegeben_werden {
/*gen*/    static messageName = "Trainer_Mobiltelefon_soll_eingegeben_werden"
/*gen*/	trainer_Id
/*gen*/	telefonnummer
/*gen*/	constructor(trainer_Id, telefonnummer) {
/*gen*/		
/*gen*/			
/*gen*/			this.trainer_Id = trainer_Id
/*gen*/		
/*gen*/			
/*gen*/			this.telefonnummer = telefonnummer
/*gen*/		
/*gen*/	}
/*gen*/}
/*gen*/class Trainer_Slackname_soll_eingegeben_werden {
/*gen*/    static messageName = "Trainer_Slackname_soll_eingegeben_werden"
/*gen*/	trainer_Id
/*gen*/	slackname
/*gen*/	constructor(trainer_Id, slackname) {
/*gen*/		
/*gen*/			
/*gen*/			this.trainer_Id = trainer_Id
/*gen*/		
/*gen*/			
/*gen*/			this.slackname = slackname
/*gen*/		
/*gen*/	}
/*gen*/}
/*gen*/class Kurstermin_Kurstag_soll_hinzugefuegt_werden {
/*gen*/    static messageName = "Kurstermin_Kurstag_soll_hinzugefuegt_werden"
/*gen*/	kurstermin_Id
/*gen*/	offset
/*gen*/	beginn
/*gen*/	ende
/*gen*/	constructor(kurstermin_Id, offset, beginn, ende) {
/*gen*/		
/*gen*/			
/*gen*/			this.kurstermin_Id = kurstermin_Id
/*gen*/		
/*gen*/			
/*gen*/			this.offset = offset
/*gen*/		
/*gen*/			
/*gen*/			this.beginn = beginn
/*gen*/		
/*gen*/			
/*gen*/			this.ende = ende
/*gen*/		
/*gen*/	}
/*gen*/}
/*gen*/class Kurstermin_Kurstag_soll_entfernt_werden {
/*gen*/    static messageName = "Kurstermin_Kurstag_soll_entfernt_werden"
/*gen*/	kurstermin_Id
/*gen*/	kurstag_Id
/*gen*/	constructor(kurstermin_Id, kurstag_Id) {
/*gen*/		
/*gen*/			
/*gen*/			this.kurstermin_Id = kurstermin_Id
/*gen*/		
/*gen*/			
/*gen*/			this.kurstag_Id = kurstag_Id
/*gen*/		
/*gen*/	}
/*gen*/}
/*gen*/class Kurstermin_Teilnahmezahl_soll_begrenzt_werden {
/*gen*/    static messageName = "Kurstermin_Teilnahmezahl_soll_begrenzt_werden"
/*gen*/	kurstermin_Id
/*gen*/	teilnahmezahl
/*gen*/	wenige_Restplaetze_ab
/*gen*/	constructor(kurstermin_Id, teilnahmezahl, wenige_Restplaetze_ab) {
/*gen*/		
/*gen*/			
/*gen*/			this.kurstermin_Id = kurstermin_Id
/*gen*/		
/*gen*/			
/*gen*/			this.teilnahmezahl = teilnahmezahl
/*gen*/		
/*gen*/			
/*gen*/			this.wenige_Restplaetze_ab = wenige_Restplaetze_ab
/*gen*/		
/*gen*/	}
/*gen*/}
/*gen*/class Kurstermin_soll_geplant_werden {
/*gen*/    static messageName = "Kurstermin_soll_geplant_werden"
/*gen*/	beginn
/*gen*/	dauer_in_Tagen
/*gen*/	typ
/*gen*/	region
/*gen*/	sprache
/*gen*/	teilnehmerKreis
/*gen*/	kurstermin_Id
/*gen*/	constructor(beginn, dauer_in_Tagen, typ, region, sprache, teilnehmerKreis, kurstermin_Id) {
/*gen*/		
/*gen*/			
/*gen*/			this.beginn = beginn
/*gen*/		
/*gen*/			
/*gen*/			this.dauer_in_Tagen = dauer_in_Tagen
/*gen*/		
/*gen*/			
/*gen*/			this.typ = typ
/*gen*/		
/*gen*/			
/*gen*/			this.region = region
/*gen*/		
/*gen*/			
/*gen*/			this.sprache = sprache
/*gen*/		
/*gen*/			
/*gen*/			this.teilnehmerKreis = teilnehmerKreis
/*gen*/		
/*gen*/			
/*gen*/			this.kurstermin_Id = kurstermin_Id
/*gen*/		
/*gen*/	}
/*gen*/}
/*gen*/class Kurstermin_soll_abgesagt_werden {
/*gen*/    static messageName = "Kurstermin_soll_abgesagt_werden"
/*gen*/	kurstermin_Id
/*gen*/	grund
/*gen*/	constructor(kurstermin_Id, grund) {
/*gen*/		
/*gen*/			
/*gen*/			this.kurstermin_Id = kurstermin_Id
/*gen*/		
/*gen*/			
/*gen*/			this.grund = grund
/*gen*/		
/*gen*/	}
/*gen*/}
/*gen*/class Kurstermin_soll_gestrichen_werden {
/*gen*/    static messageName = "Kurstermin_soll_gestrichen_werden"
/*gen*/	kurstermin_Id
/*gen*/	constructor(kurstermin_Id) {
/*gen*/		
/*gen*/			
/*gen*/			this.kurstermin_Id = kurstermin_Id
/*gen*/		
/*gen*/	}
/*gen*/}
/*gen*/class Kurstermin_Titel_soll_vergeben_werden {
/*gen*/    static messageName = "Kurstermin_Titel_soll_vergeben_werden"
/*gen*/	kurstermin_Id
/*gen*/	sprache
/*gen*/	bezeichnung
/*gen*/	constructor(kurstermin_Id, sprache, bezeichnung) {
/*gen*/		
/*gen*/			
/*gen*/			this.kurstermin_Id = kurstermin_Id
/*gen*/		
/*gen*/			
/*gen*/			this.sprache = sprache
/*gen*/		
/*gen*/			
/*gen*/			this.bezeichnung = bezeichnung
/*gen*/		
/*gen*/	}
/*gen*/}
/*gen*/class Kurstermin_Titel_soll_entfernt_werden {
/*gen*/    static messageName = "Kurstermin_Titel_soll_entfernt_werden"
/*gen*/	kurstermin_Id
/*gen*/	sprache
/*gen*/	constructor(kurstermin_Id, sprache) {
/*gen*/		
/*gen*/			
/*gen*/			this.kurstermin_Id = kurstermin_Id
/*gen*/		
/*gen*/			
/*gen*/			this.sprache = sprache
/*gen*/		
/*gen*/	}
/*gen*/}
/*gen*/class Kurstermin_Datum_soll_geaendert_werden {
/*gen*/    static messageName = "Kurstermin_Datum_soll_geaendert_werden"
/*gen*/	kurstermin_Id
/*gen*/	datum
/*gen*/	constructor(kurstermin_Id, datum) {
/*gen*/		
/*gen*/			
/*gen*/			this.kurstermin_Id = kurstermin_Id
/*gen*/		
/*gen*/			
/*gen*/			this.datum = datum
/*gen*/		
/*gen*/	}
/*gen*/}
/*gen*/class Kurstermin_Dauer_soll_geaendert_werden {
/*gen*/    static messageName = "Kurstermin_Dauer_soll_geaendert_werden"
/*gen*/	kurstermin_Id
/*gen*/	dauer_in_Tagen
/*gen*/	constructor(kurstermin_Id, dauer_in_Tagen) {
/*gen*/		
/*gen*/			
/*gen*/			this.kurstermin_Id = kurstermin_Id
/*gen*/		
/*gen*/			
/*gen*/			this.dauer_in_Tagen = dauer_in_Tagen
/*gen*/		
/*gen*/	}
/*gen*/}
/*gen*/class Kurstermin_Region_soll_geaendert_werden {
/*gen*/    static messageName = "Kurstermin_Region_soll_geaendert_werden"
/*gen*/	kurstermin_Id
/*gen*/	region
/*gen*/	constructor(kurstermin_Id, region) {
/*gen*/		
/*gen*/			
/*gen*/			this.kurstermin_Id = kurstermin_Id
/*gen*/		
/*gen*/			
/*gen*/			this.region = region
/*gen*/		
/*gen*/	}
/*gen*/}
/*gen*/class Kurstermin_Sprache_soll_geaendert_werden {
/*gen*/    static messageName = "Kurstermin_Sprache_soll_geaendert_werden"
/*gen*/	kurstermin_Id
/*gen*/	sprache
/*gen*/	constructor(kurstermin_Id, sprache) {
/*gen*/		
/*gen*/			
/*gen*/			this.kurstermin_Id = kurstermin_Id
/*gen*/		
/*gen*/			
/*gen*/			this.sprache = sprache
/*gen*/		
/*gen*/	}
/*gen*/}
/*gen*/class Kurstermin_Freitext_soll_gesetzt_werden {
/*gen*/    static messageName = "Kurstermin_Freitext_soll_gesetzt_werden"
/*gen*/	kurstermin_Id
/*gen*/	sprache
/*gen*/	text
/*gen*/	constructor(kurstermin_Id, sprache, text) {
/*gen*/		
/*gen*/			
/*gen*/			this.kurstermin_Id = kurstermin_Id
/*gen*/		
/*gen*/			
/*gen*/			this.sprache = sprache
/*gen*/		
/*gen*/			
/*gen*/			this.text = text
/*gen*/		
/*gen*/	}
/*gen*/}
/*gen*/class Kurstermin_Freitext_soll_entfernt_werden {
/*gen*/    static messageName = "Kurstermin_Freitext_soll_entfernt_werden"
/*gen*/	kurstermin_Id
/*gen*/	sprache
/*gen*/	constructor(kurstermin_Id, sprache) {
/*gen*/		
/*gen*/			
/*gen*/			this.kurstermin_Id = kurstermin_Id
/*gen*/		
/*gen*/			
/*gen*/			this.sprache = sprache
/*gen*/		
/*gen*/	}
/*gen*/}
/*gen*/class Kurstermin_Preis_soll_gesetzt_werden {
/*gen*/    static messageName = "Kurstermin_Preis_soll_gesetzt_werden"
/*gen*/	kurstermin_Id
/*gen*/	preis
/*gen*/	constructor(kurstermin_Id, preis) {
/*gen*/		
/*gen*/			
/*gen*/			this.kurstermin_Id = kurstermin_Id
/*gen*/		
/*gen*/			
/*gen*/			this.preis = preis
/*gen*/		
/*gen*/	}
/*gen*/}
/*gen*/class Kurstermin_Fruehbucher_Preis_soll_gesetzt_werden {
/*gen*/    static messageName = "Kurstermin_Fruehbucher_Preis_soll_gesetzt_werden"
/*gen*/	kurstermin_Id
/*gen*/	preis
/*gen*/	gueltig_bis_Tage_vor_dem_Kurs
/*gen*/	constructor(kurstermin_Id, preis, gueltig_bis_Tage_vor_dem_Kurs) {
/*gen*/		
/*gen*/			
/*gen*/			this.kurstermin_Id = kurstermin_Id
/*gen*/		
/*gen*/			
/*gen*/			this.preis = preis
/*gen*/		
/*gen*/			
/*gen*/			this.gueltig_bis_Tage_vor_dem_Kurs = gueltig_bis_Tage_vor_dem_Kurs
/*gen*/		
/*gen*/	}
/*gen*/}
/*gen*/class Kurstermin_Trainer_soll_zugeordnet_werden {
/*gen*/    static messageName = "Kurstermin_Trainer_soll_zugeordnet_werden"
/*gen*/	kurstermin_Id
/*gen*/	trainer_Id
/*gen*/	constructor(kurstermin_Id, trainer_Id) {
/*gen*/		
/*gen*/			
/*gen*/			this.kurstermin_Id = kurstermin_Id
/*gen*/		
/*gen*/			
/*gen*/			this.trainer_Id = trainer_Id
/*gen*/		
/*gen*/	}
/*gen*/}
/*gen*/class Kurstermin_Trainer_soll_entfernt_werden {
/*gen*/    static messageName = "Kurstermin_Trainer_soll_entfernt_werden"
/*gen*/	kurstermin_Id
/*gen*/	trainer_Id
/*gen*/	constructor(kurstermin_Id, trainer_Id) {
/*gen*/		
/*gen*/			
/*gen*/			this.kurstermin_Id = kurstermin_Id
/*gen*/		
/*gen*/			
/*gen*/			this.trainer_Id = trainer_Id
/*gen*/		
/*gen*/	}
/*gen*/}
/*gen*/class Region_soll_angelegt_werden {
/*gen*/    static messageName = "Region_soll_angelegt_werden"
/*gen*/	region_ID
/*gen*/	intern_Name
/*gen*/	name
/*gen*/	englischer_Name
/*gen*/	land
/*gen*/	zeitzone
/*gen*/	constructor(region_ID, intern_Name, name, englischer_Name, land, zeitzone) {
/*gen*/		
/*gen*/			
/*gen*/			this.region_ID = region_ID
/*gen*/		
/*gen*/			
/*gen*/			this.intern_Name = intern_Name
/*gen*/		
/*gen*/			
/*gen*/			this.name = name
/*gen*/		
/*gen*/			
/*gen*/			this.englischer_Name = englischer_Name
/*gen*/		
/*gen*/			
/*gen*/			this.land = land
/*gen*/		
/*gen*/			
/*gen*/			this.zeitzone = zeitzone
/*gen*/		
/*gen*/	}
/*gen*/}
/*gen*/class Region_Zeitzone_soll_zugeordnet_werden {
/*gen*/    static messageName = "Region_Zeitzone_soll_zugeordnet_werden"
/*gen*/	region_ID
/*gen*/	zeitzone
/*gen*/	constructor(region_ID, zeitzone) {
/*gen*/		
/*gen*/			
/*gen*/			this.region_ID = region_ID
/*gen*/		
/*gen*/			
/*gen*/			this.zeitzone = zeitzone
/*gen*/		
/*gen*/	}
/*gen*/}
/*gen*/class Kursort_soll_angelegt_werden {
/*gen*/    static messageName = "Kursort_soll_angelegt_werden"
/*gen*/	name
/*gen*/	vollstaendige_Adresse
/*gen*/	region
/*gen*/	constructor(name, vollstaendige_Adresse, region) {
/*gen*/		
/*gen*/			
/*gen*/			this.name = name
/*gen*/		
/*gen*/			
/*gen*/			this.vollstaendige_Adresse = vollstaendige_Adresse
/*gen*/		
/*gen*/			
/*gen*/			this.region = region
/*gen*/		
/*gen*/	}
/*gen*/}
/*gen*/class Kursort_Name_soll_geaendert_werden {
/*gen*/    static messageName = "Kursort_Name_soll_geaendert_werden"
/*gen*/	kursort_Id
/*gen*/	sprache
/*gen*/	name
/*gen*/	constructor(kursort_Id, sprache, name) {
/*gen*/		
/*gen*/			
/*gen*/			this.kursort_Id = kursort_Id
/*gen*/		
/*gen*/			
/*gen*/			this.sprache = sprache
/*gen*/		
/*gen*/			
/*gen*/			this.name = name
/*gen*/		
/*gen*/	}
/*gen*/}
/*gen*/class Kursort_Adresse_soll_geaendert_werden {
/*gen*/    static messageName = "Kursort_Adresse_soll_geaendert_werden"
/*gen*/	kursort_Id
/*gen*/	vollstaendige_Adresse
/*gen*/	constructor(kursort_Id, vollstaendige_Adresse) {
/*gen*/		
/*gen*/			
/*gen*/			this.kursort_Id = kursort_Id
/*gen*/		
/*gen*/			
/*gen*/			this.vollstaendige_Adresse = vollstaendige_Adresse
/*gen*/		
/*gen*/	}
/*gen*/}
/*gen*/class Kursort_Google_Maps_URL_soll_gesetzt_werden {
/*gen*/    static messageName = "Kursort_Google_Maps_URL_soll_gesetzt_werden"
/*gen*/	kursort_Id
/*gen*/	google_Maps_URL
/*gen*/	constructor(kursort_Id, google_Maps_URL) {
/*gen*/		
/*gen*/			
/*gen*/			this.kursort_Id = kursort_Id
/*gen*/		
/*gen*/			
/*gen*/			this.google_Maps_URL = google_Maps_URL
/*gen*/		
/*gen*/	}
/*gen*/}
/*gen*/class Kursort_soll_zugeordnet_werden {
/*gen*/    static messageName = "Kursort_soll_zugeordnet_werden"
/*gen*/	kurstermin_Id
/*gen*/	kursort
/*gen*/	constructor(kurstermin_Id, kursort) {
/*gen*/		
/*gen*/			
/*gen*/			this.kurstermin_Id = kurstermin_Id
/*gen*/		
/*gen*/			
/*gen*/			this.kursort = kursort
/*gen*/		
/*gen*/	}
/*gen*/}
/*gen*/class Kursort_Zuordnung_soll_entfernt_werden {
/*gen*/    static messageName = "Kursort_Zuordnung_soll_entfernt_werden"
/*gen*/	kurstermin_Id
/*gen*/	constructor(kurstermin_Id) {
/*gen*/		
/*gen*/			
/*gen*/			this.kurstermin_Id = kurstermin_Id
/*gen*/		
/*gen*/	}
/*gen*/}
/*gen*/class Kurstermin_SmallInvoice_Produkt_soll_zugeordnet_werden {
/*gen*/    static messageName = "Kurstermin_SmallInvoice_Produkt_soll_zugeordnet_werden"
/*gen*/	kurstermin_Id
/*gen*/	produkt_Id
/*gen*/	produkt_Name
/*gen*/	constructor(kurstermin_Id, produkt_Id, produkt_Name) {
/*gen*/		
/*gen*/			
/*gen*/			this.kurstermin_Id = kurstermin_Id
/*gen*/		
/*gen*/			
/*gen*/			this.produkt_Id = produkt_Id
/*gen*/		
/*gen*/			
/*gen*/			this.produkt_Name = produkt_Name
/*gen*/		
/*gen*/	}
/*gen*/}
/*gen*/class Preisstaffel_soll_angelegt_werden {
/*gen*/    static messageName = "Preisstaffel_soll_angelegt_werden"
/*gen*/	name
/*gen*/	constructor(name) {
/*gen*/		
/*gen*/			
/*gen*/			this.name = name
/*gen*/		
/*gen*/	}
/*gen*/}
/*gen*/class Preisstaffel_Eintrag_soll_hinzugefuegt_werden {
/*gen*/    static messageName = "Preisstaffel_Eintrag_soll_hinzugefuegt_werden"
/*gen*/	preisstaffel_Id
/*gen*/	mindestteilnehmer
/*gen*/	rabatt
/*gen*/	kostenfreie_Teilnehmer
/*gen*/	kombinierbar
/*gen*/	constructor(preisstaffel_Id, mindestteilnehmer, rabatt, kostenfreie_Teilnehmer, kombinierbar) {
/*gen*/		
/*gen*/			
/*gen*/			this.preisstaffel_Id = preisstaffel_Id
/*gen*/		
/*gen*/			
/*gen*/			this.mindestteilnehmer = mindestteilnehmer
/*gen*/		
/*gen*/			
/*gen*/			this.rabatt = rabatt
/*gen*/		
/*gen*/			
/*gen*/			this.kostenfreie_Teilnehmer = kostenfreie_Teilnehmer
/*gen*/		
/*gen*/			
/*gen*/			this.kombinierbar = kombinierbar
/*gen*/		
/*gen*/	}
/*gen*/}
/*gen*/class Preisstaffel_Eintrag_soll_entfernt_werden {
/*gen*/    static messageName = "Preisstaffel_Eintrag_soll_entfernt_werden"
/*gen*/	preisstaffel_Id
/*gen*/	mindestteilnehmer
/*gen*/	constructor(preisstaffel_Id, mindestteilnehmer) {
/*gen*/		
/*gen*/			
/*gen*/			this.preisstaffel_Id = preisstaffel_Id
/*gen*/		
/*gen*/			
/*gen*/			this.mindestteilnehmer = mindestteilnehmer
/*gen*/		
/*gen*/	}
/*gen*/}
/*gen*/class Preisstaffel_Kurstyp_soll_zugeordnet_werden {
/*gen*/    static messageName = "Preisstaffel_Kurstyp_soll_zugeordnet_werden"
/*gen*/	preisstaffel_Id
/*gen*/	kurstyp_Id
/*gen*/	constructor(preisstaffel_Id, kurstyp_Id) {
/*gen*/		
/*gen*/			
/*gen*/			this.preisstaffel_Id = preisstaffel_Id
/*gen*/		
/*gen*/			
/*gen*/			this.kurstyp_Id = kurstyp_Id
/*gen*/		
/*gen*/	}
/*gen*/}
/*gen*/class Preisstaffel_Kurstyp_Zuordnung_soll_entfernt_werden {
/*gen*/    static messageName = "Preisstaffel_Kurstyp_Zuordnung_soll_entfernt_werden"
/*gen*/	preisstaffel_Id
/*gen*/	kurstyp_Id
/*gen*/	constructor(preisstaffel_Id, kurstyp_Id) {
/*gen*/		
/*gen*/			
/*gen*/			this.preisstaffel_Id = preisstaffel_Id
/*gen*/		
/*gen*/			
/*gen*/			this.kurstyp_Id = kurstyp_Id
/*gen*/		
/*gen*/	}
/*gen*/}
/*gen*/export {
/*gen*/    Kurstermin_Status_soll_Geplant_werden,
/*gen*/    Kurstermin_Status_soll_Veroeffentlicht_werden,
/*gen*/    Kurstermin_Status_soll_Wenige_Restplaetze_werden,
/*gen*/    Kurstermin_Status_soll_Ausverkauft_werden,
/*gen*/    Kurstermin_Status_soll_Wird_durchgefuehrt_werden,
/*gen*/    Kurstermin_Status_soll_Durchgefuehrt_werden,
/*gen*/    Kurstermin_Status_soll_Abgeschlossen_werden,
/*gen*/    Kurstermin_Status_soll_Abgebrochen_werden,
/*gen*/    Trainer_soll_aufgenommen_werden,
/*gen*/    Trainer_Name_soll_geaendert_werden,
/*gen*/    Trainer_CMS_Code_soll_gesetzt_werden,
/*gen*/    Trainer_Email_soll_eingegeben_werden,
/*gen*/    Trainer_Mobiltelefon_soll_eingegeben_werden,
/*gen*/    Trainer_Slackname_soll_eingegeben_werden,
/*gen*/    Kurstermin_Kurstag_soll_hinzugefuegt_werden,
/*gen*/    Kurstermin_Kurstag_soll_entfernt_werden,
/*gen*/    Kurstermin_Teilnahmezahl_soll_begrenzt_werden,
/*gen*/    Kurstermin_soll_geplant_werden,
/*gen*/    Kurstermin_soll_abgesagt_werden,
/*gen*/    Kurstermin_soll_gestrichen_werden,
/*gen*/    Kurstermin_Titel_soll_vergeben_werden,
/*gen*/    Kurstermin_Titel_soll_entfernt_werden,
/*gen*/    Kurstermin_Datum_soll_geaendert_werden,
/*gen*/    Kurstermin_Dauer_soll_geaendert_werden,
/*gen*/    Kurstermin_Region_soll_geaendert_werden,
/*gen*/    Kurstermin_Sprache_soll_geaendert_werden,
/*gen*/    Kurstermin_Freitext_soll_gesetzt_werden,
/*gen*/    Kurstermin_Freitext_soll_entfernt_werden,
/*gen*/    Kurstermin_Preis_soll_gesetzt_werden,
/*gen*/    Kurstermin_Fruehbucher_Preis_soll_gesetzt_werden,
/*gen*/    Kurstermin_Trainer_soll_zugeordnet_werden,
/*gen*/    Kurstermin_Trainer_soll_entfernt_werden,
/*gen*/    Region_soll_angelegt_werden,
/*gen*/    Region_Zeitzone_soll_zugeordnet_werden,
/*gen*/    Kursort_soll_angelegt_werden,
/*gen*/    Kursort_Name_soll_geaendert_werden,
/*gen*/    Kursort_Adresse_soll_geaendert_werden,
/*gen*/    Kursort_Google_Maps_URL_soll_gesetzt_werden,
/*gen*/    Kursort_soll_zugeordnet_werden,
/*gen*/    Kursort_Zuordnung_soll_entfernt_werden,
/*gen*/    Kurstermin_SmallInvoice_Produkt_soll_zugeordnet_werden,
/*gen*/    Preisstaffel_soll_angelegt_werden,
/*gen*/    Preisstaffel_Eintrag_soll_hinzugefuegt_werden,
/*gen*/    Preisstaffel_Eintrag_soll_entfernt_werden,
/*gen*/    Preisstaffel_Kurstyp_soll_zugeordnet_werden,
/*gen*/    Preisstaffel_Kurstyp_Zuordnung_soll_entfernt_werden,
/*gen*/}