import * as React from "react"
import PropTypes from "prop-types"
import {LinkButton} from "../components"
import {Kursbuchung_Teilnahme_soll_bestaetigt_werden} from "../Daten/CheckinMessages.gen"
import {gql} from "apollo-boost/lib/index";

export class TeilnahmeBestätigen extends React.Component {
    static contextTypes = {
        client: PropTypes.any, // Apollo Client
    };

    static propTypes = {
        bestaetigt: PropTypes.bool.isRequired,
        teilnahmeId: PropTypes.string.isRequired,
        buchungId: PropTypes.string.isRequired,
        kursterminId: PropTypes.string.isRequired,
        refetch: PropTypes.func,
    }

    timer = null

    constructor(props, context) {
        super(props, context)

        this.state = {
            bestaetigt: props.bestaetigt,
            waiting: false,
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.bestaetigt !== prevProps.bestaetigt) {
            this.setState({bestaetigt: this.props.bestaetigt})
        }
        if (this.props.teilnahmeId !== prevProps.teilnahmeId) {
            this.handleCancel()
        }
    }

    async handleTimeout() {
        this.setState({bestaetigt: true, waiting: false})
        const {teilnahmeId, buchungId, kursterminId, refetch} = this.props
        const message = new Kursbuchung_Teilnahme_soll_bestaetigt_werden(buchungId, teilnahmeId, kursterminId)
        const mutation = gql(`mutation x($message: String!){${message.constructor.messageName}(message:$message)}`)
        this.context.client.mutate({mutation, variables: {message: JSON.stringify(message)}}).then(
            result => {
                if (result.data && result.data[message.constructor.messageName] === "ok") {
                    if (refetch) {
                        refetch();
                    }
                } else {
                    this.setState({bestaetigt: this.props.bestaetigt});
                }
            },
        ).catch(this.setState({bestaetigt: this.props.bestaetigt}))

    }

    handleCancel() {
        window.clearTimeout(this.timer)
        this.timer = null
        this.setState({waiting: false})
    }

    handleButtonClick() {
        this.timer = window.setTimeout(() => this.handleTimeout(), 10000)
        this.setState({waiting: true})
    }

    render() {
        const {bestaetigt} = this.props
        const {waiting} = this.state

        if (bestaetigt) {
            return <span>bestätigt</span>
        }
        if (waiting) {
            return <LinkButton className="no-underline" onClick={() => this.handleCancel()}>
                ↩️ Rückgängig
            </LinkButton>
        }
        return <LinkButton className="no-underline" onClick={() => this.handleButtonClick()}>
            Teilnahme bestätigen
        </LinkButton>
    }
}