import * as React from "react"
import PropTypes from "prop-types"
import KurstypSelect from "../KurstypSelect"

export class KurstypSelector extends React.Component {
    static contextTypes = {client: PropTypes.any};

    static propTypes = {
        onSave: PropTypes.func.isRequired,
        onCancel: PropTypes.func.isRequired,
    }

    state = {
        kurstyp_Id: null,
    }

    handleKeyUp(event) {
        if (event.key === 'Escape') {
            this.props.onCancel();
            event.preventDefault();
        }
    }

    handleChange(event) {
        this.setState({
            [event.target.name]: event.target.value,
        });
    }

    handleSaveClick() {
        let {kurstyp_Id} = this.state

        if (kurstyp_Id === null || kurstyp_Id === undefined) {
            alert("Bitte Kurstyp auswählen")
            return
        }

        this.props.onSave(kurstyp_Id)
    }

    render() {
        const {kurstyp_Id} = this.state
        return <div>
            <KurstypSelect name="kurstyp_Id" value={kurstyp_Id} onChange={e => this.handleChange(e)}/>
            <button className="text-dst-logo-gruen-mitte hover:text-dst-logo-gruen-hinten underline"
                    onClick={() => this.handleSaveClick()}>Hinzufügen
            </button>
        </div>;
    }
}
