import {gql} from "apollo-boost"
import * as React from "react"
import Autosuggest from "react-autosuggest"
import PropTypes from "prop-types"
import {AutosuggestTheme} from "./App"

const locationQuery = gql`{kursorte{Id,name,region{Id,name,land{name,iso}}}}`

const getSuggestionValue = suggestion => suggestion;
const renderSuggestion = (suggestion) => (
    <div>{suggestion.name}</div>
);

class LocationField extends React.Component {
    static contextTypes = {client: PropTypes.any};

    constructor(props, context) {
        super(props, context);

        let locationName = props.textValue || ""
        if (locationName === "TBD") {
            locationName = ""
        }

        this.state = {
            location: props.value,
            locationSuggestions: [],
            locationName: locationName,
            locationRegion: null,
            eventHandler: props.onChange || undefined,
        }

        this.handleSuggestionChange = this.handleSuggestionChange.bind(this);
        this.handleSuggestionFetchRequest = this.handleSuggestionFetchRequest.bind(this);
        this.handleSuggestionClearRequest = this.handleSuggestionClearRequest.bind(this);
        this.handleKeyUp = this.handleKeyUp.bind(this)
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps !== this.props) {
            let locationName = this.props.textValue || ""
            if (locationName === "TBD") {
                locationName = ""
            }

            this.setState({
                location: this.props.value,
                locationSuggestions: [],
                locationName: locationName,
                locationRegion: null,
                eventHandler: this.props.onChange || undefined,
            })
        }
    }


    handleKeyUp(event) {
        if (event.key === 'Enter') {
            event.preventDefault();
        } else {
            this.props.onKeyUp && this.props.onKeyUp(event)
        }
    }

    componentWillMount() {
        try {
            // noinspection JSIgnoredPromiseFromCall
            this.handleSuggestionFetchRequest({value: this.state.locationName})
        } catch (error) {
            // ignore
        }
    }

    render() {
        const inputProps = {
            placeholder: "Zum Suchen tippen...",
            value: this.state.locationName,
            onChange: (_, {newValue}) => this.setState({locationName: newValue.name ? newValue.name : newValue}),
            onKeyUp: this.handleKeyUp,
            className: "leading-normal border w-full",
            name: "41a620ba", /* heißt so, weil Chrome sonst Autocompletion macht 🖕 */
            autoComplete: "off",
            "aria-autocomplete": "none",
        }

        return <Autosuggest suggestions={this.state.locationSuggestions}
                            onSuggestionsFetchRequested={this.handleSuggestionFetchRequest}
                            onSuggestionsClearRequested={this.handleSuggestionClearRequest}
                            shouldRenderSuggestions={() => true}
                            getSuggestionValue={getSuggestionValue} theme={AutosuggestTheme}
                            onSuggestionSelected={(...args) => this.handleSuggestionChange(...args)}
                            renderSuggestion={renderSuggestion} inputProps={inputProps}/>
    }

    async handleSuggestionFetchRequest({value}) {
        const inputValue = value.trim().toLowerCase();

        try {
            let result = await this.context.client.query({
                query: locationQuery,
            })

            const kursorte = result.data.kursorte;

            let array = []
            if (inputValue !== "") {
                array = inputValue.split(' ')
            }
            array.push(this.props.regionName.toLowerCase())

            const suggestions = kursorte.filter(loc =>
                array.every(input =>
                    (loc.name.toLowerCase() + " " + loc.region.name.toLowerCase()).includes(input),
                ),
            );

            this.setState({
                locationSuggestions: suggestions,
            })
        } catch (error) {
            console.warn(error)
        }
    }

    handleSuggestionClearRequest() {
        this.setState({
            locationSuggestions: [],
        })
    }

    handleSuggestionChange(event, {suggestion}) {
        this.setState({
            location: suggestion.Id,
            locationName: suggestion.name,
            locationRegion: suggestion.region,
        })
        this.state.eventHandler && this.state.eventHandler({
            location: suggestion.Id,
            locationName: suggestion.name,
            locationRegion: suggestion.region,
        })
    }
}

export default LocationField