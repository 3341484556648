import * as React from "react"
import gql from "graphql-tag";
import PropTypes from "prop-types"
import {graphql} from "react-apollo/index"
import {Label, Loading, StatusButton, StatusButtonField, Vier04} from "./components"
import {
    Kursort_soll_zugeordnet_werden,
    Kursort_Zuordnung_soll_entfernt_werden,
    Kurstermin_Datum_soll_geaendert_werden,
    Kurstermin_Dauer_soll_geaendert_werden,
    Kurstermin_Freitext_soll_entfernt_werden,
    Kurstermin_Freitext_soll_gesetzt_werden,
    Kurstermin_Fruehbucher_Preis_soll_gesetzt_werden,
    Kurstermin_Kurstag_soll_entfernt_werden,
    Kurstermin_Kurstag_soll_hinzugefuegt_werden,
    Kurstermin_Preis_soll_gesetzt_werden,
    Kurstermin_Region_soll_geaendert_werden, Kurstermin_SmallInvoice_Produkt_soll_zugeordnet_werden,
    Kurstermin_soll_abgesagt_werden, Kurstermin_soll_gestrichen_werden,
    Kurstermin_Sprache_soll_geaendert_werden,
    Kurstermin_Status_soll_Abgebrochen_werden,
    Kurstermin_Status_soll_Abgeschlossen_werden,
    Kurstermin_Status_soll_Ausverkauft_werden,
    Kurstermin_Status_soll_Durchgefuehrt_werden, Kurstermin_Status_soll_Geplant_werden,
    Kurstermin_Status_soll_Veroeffentlicht_werden, Kurstermin_Status_soll_Wenige_Restplaetze_werden,
    Kurstermin_Status_soll_Wird_durchgefuehrt_werden, Kurstermin_Teilnahmezahl_soll_begrenzt_werden,
    Kurstermin_Titel_soll_entfernt_werden,
    Kurstermin_Titel_soll_vergeben_werden,
    Kurstermin_Trainer_soll_entfernt_werden,
    Kurstermin_Trainer_soll_zugeordnet_werden,
} from "./Daten/FlugplanMessages.gen"
import {Redirect} from "react-router-dom"
import Buchungsliste from "./Buchungsliste"
import Teilnehmerliste from "./Teilnehmerliste"
import moment from "moment"
import 'moment/locale/de'
import 'react-dates/initialize'
import 'react-dates/lib/css/_datepicker.css';
import {TrainerListField} from "./Trainer/TrainerListField";
import {KurstagListField} from "./Kurstag/KurstagListField"
import confirmation from "./UI/Confirm"
import {UnicodeButton} from "./UI/Editors/UnicodeButton"
import {BemerkungListe} from "./Bemerkungen/BemerkungListe"
import {Kurstermin_Bemerkung_soll_erstellt_werden} from "./Daten/BemerkungenMessages.gen"
import {SpracheFieldWrapper} from "./UI/Editors/SpracheFieldWrapper"
import {InlineEditor} from "./UI/Editors/InlineEditor"
import {IntegerField, PreisBetragField, TextField} from "./UI/Editors/TextField"
import {Editor} from "./UI/Editors/Editor"
import {EditorField} from "./UI/Editors/EditorField"
import {WaehrungFieldWrapper} from "./Fields/WaehrungField"
import {EditorControls} from "./UI/Editors/EditorControls"
import {MultilineTextField} from "./UI/Editors/MultilineTextField"
import {SingleDateField} from "./UI/Editors/DateFields"
import {RegionFieldWrapper} from "./UI/Editors/RegionFieldWrapper"
import {LocationFieldWrapper} from "./UI/Editors/LocationFieldWrapper"
import {SmallInvoiceProduktField} from "./UI/Editors/SmallInvoiceProduktField"
import {SmallInvoiceLogin} from "./Rechnung/SmallInvoiceLogin"

class KursterminDetails extends React.Component {
    static contextTypes = {client: PropTypes.any};

    constructor(props, context) {
        super(props, context);
        moment.locale('de')

        this.state = {
            termin: null,
            startTermin: null,
            changed: {},
            redirect: false,
            showBuchungen: !!props.match.params.buchungen,
            showTeilnehmer: !!props.match.params.teilnehmer,
            fruehbucherGueltigkeit: "n/a",
            statusHighlighted: false,
            dateFocused: true,
            showStatusMenu: false,
        }

        this.handleLocationDeleteClick = this.handleLocationDeleteClick.bind(this)
        this.handleStateClick = this.handleStateClick.bind(this)
        this.statusButton = this.statusButton.bind(this)
        this.statusMenu = this.statusMenu.bind(this)
        this.updateFruehbucherGueltigkeitDatum = this.updateFruehbucherGueltigkeitDatum.bind(this)
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.data.kurstermine && nextProps.data.kurstermine[0]) {
            this.setState({
                termin: {
                    ...nextProps.data.kurstermine[0],
                    beginnMoment: moment(nextProps.data.kurstermine[0].beginn.isoString),
                },
                startTermin: {
                    ...nextProps.data.kurstermine[0],
                    beginnMoment: moment(nextProps.data.kurstermine[0].beginn.isoString),
                },
            }, () => nextProps.data.kurstermine[0].fruehbucherPreis && this.updateFruehbucherGueltigkeitDatum(nextProps.data.kurstermine[0].fruehbucherPreis.gueltig_bis_Tage_vor_dem_Kurs))
        }
        if (nextProps.match.params.buchungen) {
            this.setState({showBuchungen: true})
        }
        if (nextProps.match.params.teilnehmer) {
            this.setState({showTeilnehmer: true})
        }
    }

    componentWillUpdate(nextProps, nextState) {
        if (nextState.termin && this.state.termin &&
            nextState.termin.status !== this.state.termin.status) {
            this.setState({statusHighlighted: true})
            setTimeout(() => this.setState({statusHighlighted: false}), 2000)
        }
    }

    updateFruehbucherGueltigkeitDatum(tage) {
        if (tage === null || (typeof tage === "string" && tage.trim() === "")) {
            this.setState({fruehbucherGueltigkeit: "n/a"})
        } else {
            let beginn = this.state.termin.beginnMoment.clone()
            this.setState({fruehbucherGueltigkeit: beginn.subtract(tage, 'days').format('L')})
        }
    }

    async handleStateClick(event) {
        const target = event.target;
        const name = target.name;
        let message
        switch (name) {
            case "Geplant":
                try {
                    await confirmation("Wirklich auf geplant setzen?", {
                        description: "Soll der Termin wirklich auf geplant gesetzt werden? Er verschwindet so von der öffentlichen Website" +
                            " und es können dadurch Links kaputt gehen.",
                        confirmLabel: "Auf geplant setzen",
                        abortLabel: "Abbrechen",
                    })
                } catch (error) {
                    // noinspection UnnecessaryReturnStatementJS
                    return;
                }
                message = new Kurstermin_Status_soll_Geplant_werden(this.state.termin.Id)
                break;
            case "Veröffentlicht":
                message = new Kurstermin_Status_soll_Veroeffentlicht_werden(this.state.termin.Id)
                break;
            case "Wenige Restplätze":
                message = new Kurstermin_Status_soll_Wenige_Restplaetze_werden(this.state.termin.Id)
                break;
            case "Ausverkauft":
                message = new Kurstermin_Status_soll_Ausverkauft_werden(this.state.termin.Id)
                break;
            case "Wird durchgeführt":
                try {
                    await confirmation("Wirklich auf „Wird durchgeführt“ setzen?", {
                        description: "Soll der Termin wirklich auf „Wird durchgeführt“ gesetzt werden? Dieser Status " +
                            "wird automatisch vom Backend zum Kurs-Beginn gesetzt und sollte nur in Ausnahmefällen " +
                            "manuell gesetzt werden. Die Bedeutung dieses Status ist nicht die garantierte " +
                            "Durchführung, sondern dass dieser Kurs zum aktuellen Zeitpunkt gerade stattfindet.",
                        confirmLabel: "Auf „Wird durchgeführt“ setzen",
                        abortLabel: "Abbrechen",
                    })
                } catch (error) {
                    // noinspection UnnecessaryReturnStatementJS
                    return;
                }
                message = new Kurstermin_Status_soll_Wird_durchgefuehrt_werden(this.state.termin.Id)
                break;
            case "Durchgeführt":
                try {
                    await confirmation("Wirklich auf „Durchgeführt“ setzen?", {
                        description: "Soll der Termin wirklich auf „Durchgeführt“ gesetzt werden? Dieser Status " +
                            "wird automatisch vom Backend zum Kurs-Ende gesetzt und sollte nur in Ausnahmefällen " +
                            "manuell gesetzt werden. Die Bedeutung dieses Status ist nicht die garantierte " +
                            "Durchführung, sondern dass dieser Kurs zum aktuellen Zeitpunkt bereits beendet ist.",
                        confirmLabel: "Auf „Durchgeführt“ setzen",
                        abortLabel: "Abbrechen",
                    })
                } catch (error) {
                    // noinspection UnnecessaryReturnStatementJS
                    return;
                }
                message = new Kurstermin_Status_soll_Durchgefuehrt_werden(this.state.termin.Id)
                break;
            case "Abgeschlossen":
                message = new Kurstermin_Status_soll_Abgeschlossen_werden(this.state.termin.Id)
                break;
            case "Abgebrochen":
                message = new Kurstermin_Status_soll_Abgebrochen_werden(this.state.termin.Id)
                break;
            case "Abgesagt":
                let grund = prompt("Absage-Grund")
                if (grund === null) {
                    return
                }
                grund = grund !== "" ? grund : null
                message = new Kurstermin_soll_abgesagt_werden(this.state.termin.Id, grund)
                break;
            case "Gestrichen":
                if (this.state.termin.buchungen.count > 0) {
                    alert("Dieser Termin hat bereits Buchungen und kann nicht mehr gestrichen werden.")
                    return;
                }
                try {
                    await confirmation("Wirklich „streichen“?", {
                        description: "Soll der Termin wirklich „gestrichen“ werden? Damit verschwindet er von der " +
                            "Website und aus der reisebüro-Übersicht. Eine Wiederherstellung ist nicht möglich. " +
                            "Wenn der termin abgesagt werden soll, kann der Status auf „Abgesagt“ gesetzt werden.",
                        confirmLabel: "Kurstermin streichen",
                        abortLabel: "Abbrechen",
                    })
                } catch (error) {
                    // noinspection UnnecessaryReturnStatementJS
                    return;
                }
                message = new Kurstermin_soll_gestrichen_werden(this.state.termin.Id)
                break;
            default:
                return
        }
        let status = await this.nextStatusFor(name)
        let termin = {...this.state.termin, status}
        this.setState({termin, showStatusMenu: false})
        await this.send(message, name)
        if (name === "absagen") {
            this.setState({redirect: true})
        }
    }

    async nextStatusFor(status) {
        try {
            let result = await this.context.client.query({query: gql`{kursstatus{text,transitions{text}}}`})
            for (let i = 0; i < result.data.kursstatus.length; i++) {
                const kursstatus = result.data.kursstatus[i];
                if (kursstatus.text === status) {
                    return kursstatus
                }
            }
            return {
                text: "Fehler",
                transitions: [],
            }
        } catch (error) {
            console.error(error)
            return {
                text: "Fehler",
                transitions: [],
            }
        }
    }

    async send(message, name) {
        try {
            let id = this.state.termin.Id
            const mutation = gql(`mutation mut($message: String!){${message.constructor.messageName}(message:$message)}`)
            let result = await this.context.client.mutate({
                mutation,
                variables: {message: JSON.stringify(message)},
                refetchQueries: [{query: query, variables: {id}}],
            })
            const res = result.data[message.constructor.messageName]
            if (res !== "ok") {
                alert("Beim Speichern ist ein Fehler aufgetreten:\n: " + res)
                this.resetField(name)
            }
        } catch (error) {
            alert("Beim Speichern ist ein Fehler aufgetreten:\n: " + error);
            this.resetField(name)
        }
    }

    resetField(name) {
        let termin;
        switch (name) {
            case "veroeffentlichen":
            case "wenigeRestplaetze":
            case "absagen":
                termin = {...this.state.termin, status: this.state.startTermin.status}
                break
            default:
                termin = {...this.state.termin, [name]: this.state.startTermin[name]}
        }
        this.setState({termin})
    }

    async handleLocationDeleteClick(event) {
        event.preventDefault()
        const message = new Kursort_Zuordnung_soll_entfernt_werden(this.state.termin.Id)
        const mutationName = "Kursort_Zuordnung_soll_entfernt_werden"
        await this.send(message, mutationName)
        this.props.data.refetch()
    }

    render() {
        if (!this.props.data.loading && !this.props.data.kurstermine[0]) {
            return <Vier04/>
        }

        const classesWithoutBorder = ["bg-transparent"]
        const classes = classesWithoutBorder.concat(["border", "p-1", "my-1"])
        const width = ["min-w-64", "w-4/5"]
        const editorClasses = classes.concat(width).reduce((prev, cur) => prev + " " + cur, "").trim()
        const autoselectClasses = classesWithoutBorder.concat(width).reduce((prev, cur) => prev + " " + cur, "").trim()

        if (this.state.termin) {

            if (this.state.redirect) {
                return <Redirect to="/kurstermine"/>
            }

            const {Id, sprache, titel, titelEn, dauer, preis, fruehbucherPreis, location, region, freitext, freitextEn, beginnMoment, maxTeilnahmeZahl, wenigeRestplaetzeAb} = this.state.termin
            const {refetch} = this.props.data

            // eslint-disable-next-line
            return (

                <div className="termindetails" key={this.state.termin.Id}>
                    <h1 className="mb-4 heading w-full">
                        <InlineEditor component={TextField} value={titel} className={editorClasses}
                                      refetch={refetch}
                                      save={val => val.trim() === "" ?
                                          new Kurstermin_Titel_soll_entfernt_werden(Id, "DE") :
                                          new Kurstermin_Titel_soll_vergeben_werden(Id, "DE", val)}/>
                    </h1>
                    <StatusInfo inListe={this.state.termin.inListe} oeffentlich={this.state.termin.oeffentlich}
                                url={this.state.termin.drupalUrl}/>
                    <table className="termindetail-details w-full alternating-table">
                        <tbody>
                        <tr className="h-6">
                            <Th>Sprache</Th>
                            <TD colSpan={3}>
                                <InlineEditor component={SpracheFieldWrapper} value={sprache.code}
                                              className={autoselectClasses} refetch={refetch}
                                              save={val => new Kurstermin_Sprache_soll_geaendert_werden(Id, val)}/>
                                {sprache.name}
                            </TD>
                            {/* TODO Flagge */}
                        </tr>
                        <tr className="h-6">
                            <Th>Titel (Englisch)</Th>
                            <TD colSpan={3}>
                                <InlineEditor component={TextField} value={titelEn} className={editorClasses}
                                              refetch={refetch}
                                              save={val => val.trim() === "" ?
                                                  new Kurstermin_Titel_soll_entfernt_werden(Id, "EN") :
                                                  new Kurstermin_Titel_soll_vergeben_werden(Id, "EN", val)}/>
                            </TD>
                        </tr>
                        <tr className="h-6">
                            <Th>Kurscode</Th>
                            <TD>{this.state.termin.kursCode}</TD>
                            <Th>Typ</Th>
                            <TD>{this.state.termin.typ.name}</TD>
                        </tr>
                        <tr className="h-6">
                            <Th>Teilnahmezahlbegrenzung</Th>
                            <TD className="p-1 align-middle">
                                <InlineEditor refetch={refetch} className={editorClasses} component={IntegerField}
                                              value={maxTeilnahmeZahl}
                                              save={val => new Kurstermin_Teilnahmezahl_soll_begrenzt_werden(Id, +val, wenigeRestplaetzeAb)}/>
                            </TD>
                            <Th>Wenige Restplätze ab</Th>
                            <TD className="p-1 align-middle">
                                {maxTeilnahmeZahl ?
                                    <Editor wenigeRestplaetzeBegrenzt={wenigeRestplaetzeAb != null}
                                            wenigeRestplaetzeAb={wenigeRestplaetzeAb}
                                            save={val => new Kurstermin_Teilnahmezahl_soll_begrenzt_werden(Id, maxTeilnahmeZahl, val.wenigeRestplaetzeBegrenzt ? +val.wenigeRestplaetzeAb : null)}
                                            refetch={refetch}>
                                        <EditorField component={StatusButtonField}
                                                     placeholder="Wenige Restplätze setzen"
                                                     valuekey={"wenigeRestplaetzeBegrenzt"}/>
                                        <EditorField component={IntegerField} className={editorClasses}
                                                     valuekey={"wenigeRestplaetzeAb"}/>
                                        <EditorControls type="inline"/>
                                    </Editor> :
                                    <span>Bitte erst maximale Teilnahmezahl setzen</span>
                                }
                            </TD>
                        </tr>
                        <tr className="h-6">
                            <Th>Beginn</Th>
                            <TD className="p-1 align-middle">
                                <InlineEditor refetch={refetch} className={editorClasses} component={SingleDateField}
                                              value={beginnMoment}
                                              save={val => new Kurstermin_Datum_soll_geaendert_werden(Id, val.format("YYYY-MM-DD"))}/>
                            </TD>
                            <Th>Dauer</Th>
                            <TD>
                                {this.state.termin.kurstage.length === 0 ?
                                    <InlineEditor refetch={refetch} className={editorClasses} component={IntegerField}
                                                  value={dauer}
                                                  save={val => new Kurstermin_Dauer_soll_geaendert_werden(Id, parseInt(val, 10))}/>
                                    :
                                    <span>{dauer} Tage</span>}
                                <KurstagListField kurstage={this.state.termin.kurstage}
                                                  addKurstag={async (kurstag) => {
                                                      const message = new Kurstermin_Kurstag_soll_hinzugefuegt_werden(this.state.termin.Id, kurstag.offset, kurstag.beginn, kurstag.ende);
                                                      return this.send(message, "kurstage").then(() => {
                                                          this.props.data.refetch()
                                                      });
                                                  }}
                                                  removeKurstag={async (id) => {
                                                      const message = new Kurstermin_Kurstag_soll_entfernt_werden(this.state.termin.Id, id);
                                                      return this.send(message, "kurstage").then(() => {
                                                          this.props.data.refetch()
                                                      });
                                                  }}/>
                            </TD>
                        </tr>
                        <tr className="h-6">
                            <Th>Preis</Th>
                            <Editor
                                save={val => new Kurstermin_Preis_soll_gesetzt_werden(Id, {
                                    wert: val.wert,
                                    waehrung: val.waehrung,
                                })} refetch={refetch}
                                validate={val => val.waehrung !== null && val.wert !== null && val.wert.trim() !== ""}
                                wert={preis ? String(preis.wert).replace('.', '').replace(',', '.') : null}
                                waehrung={preis ? preis.waehrung.code : null}>
                                <EditorField component={PreisBetragField} valuekey="wert" placeholder="Preis"
                                             className={classes.concat("w-2/5").reduce((prev, cur) => prev + " " + cur, "").trim()}/>
                                <EditorField component={WaehrungFieldWrapper} valuekey="waehrung"
                                             options={[{title: "textValue", value: preis ? preis.waehrung.name : ""}]}
                                             className={"mr-2 my-1 max-w-full w-2/5 bg-transparent leading-normal border"}/>
                                <EditorControls/>
                            </Editor>
                            <Th>Frühbucherpreis</Th>
                            {preis ?
                                <Editor save={val => new Kurstermin_Fruehbucher_Preis_soll_gesetzt_werden(Id, {
                                    wert: val.wert,
                                    waehrung: preis.waehrung.code,
                                }, parseInt(val.gueltigBis, 10))} refetch={refetch} validate={val => {
                                    if (val.gueltigBis === null
                                        || (typeof val === "string" && val.gueltigBis.trim() === "")) {
                                        return false
                                    }
                                    let intWert = parseInt(val.gueltigBis, 10)
                                    if (isNaN(intWert)) {
                                        return false
                                    }
                                    this.updateFruehbucherGueltigkeitDatum(intWert)
                                    if (val.wert === null || val.wert.trim() === "") {
                                        return false
                                    }
                                    let floatWert = parseFloat(val.wert)
                                    return !isNaN(floatWert)
                                }}
                                        wert={fruehbucherPreis ? String(fruehbucherPreis.preis.wert).replace('.', '').replace(',', '.') : null}
                                        gueltigBis={fruehbucherPreis ? fruehbucherPreis.gueltig_bis_Tage_vor_dem_Kurs : 42}>
                                    <EditorField component={PreisBetragField} valuekey="wert" placeholder="Preis"
                                                 className={classes.concat("w-1/4").reduce((prev, cur) => prev + " " + cur, "").trim()}/>
                                    <span
                                        className="w-1/4 leading-normal mr-2">{fruehbucherPreis ? fruehbucherPreis.preis.waehrung.name : preis.waehrung.name} bis</span>
                                    <EditorField component={IntegerField} valuekey="gueltigBis" placeholder="Gültig bis"
                                                 className={classes.concat("w-1/4").reduce((prev, cur) => prev + " " + cur, "").trim()}/>
                                    <span className="mr-2">Tage vorher ({this.state.fruehbucherGueltigkeit})</span>
                                    <EditorControls/>
                                </Editor>
                                :
                                <td>Bitte zunächst einen Preis festlegen</td>}
                        </tr>
                        <tr className="h-6">
                            <Th>SmallInvoice-Produkt</Th>
                            <TD colSpan={4}>
                                <SmallInvoiceLogin>
                                    <InlineEditor component={SmallInvoiceProduktField}
                                                  value={{
                                                      produkt: this.state.termin.smallInvoiceProduktId,
                                                      produktName: this.state.termin.smallInvoiceProduktName,
                                                  }}
                                                  save={value => new Kurstermin_SmallInvoice_Produkt_soll_zugeordnet_werden(this.state.termin.Id, value.produkt, value.produktName)}/>
                                </SmallInvoiceLogin>
                            </TD>
                        </tr>
                        <tr className="h-6">
                            <Th>Status</Th>
                            <TD>
                                <span className={this.state.statusHighlighted ?
                                    "transition transition-property-bg transition-timing-ease-out bg-dst-logo-gruen-mitte" :
                                    "transition transition-property-bg transition-timing-ease-in"}>{this.state.termin.status.text}</span>
                            </TD>
                            <Th>Teilnehmerkreis</Th>
                            <TD>
                                {this.state.termin.teilnehmerKreis}
                            </TD>
                        </tr>
                        <tr className="h-6">
                            <Th>
                                Location
                            </Th>
                            <TD>
                                <Editor refetch={refetch}
                                        save={val => new Kursort_soll_zugeordnet_werden(Id, val.location.Id)}
                                        location={location}
                                        validate={val => val.location !== null && val.location.Id !== null}>
                                    <EditorField valuekey="location" className={editorClasses}
                                                 options={[{title: "region", value: region.name}]}
                                                 component={LocationFieldWrapper}/>
                                    <EditorControls/>
                                    {location && <UnicodeButton onClick={this.handleLocationDeleteClick} value="🚮"/>}
                                </Editor>
                            </TD>
                            <Th>Region</Th>
                            <TD>
                                {this.state.termin.location ?
                                    <span>{region.name}, {region.land.name}</span> :
                                    <Editor refetch={refetch}
                                            save={val => new Kurstermin_Region_soll_geaendert_werden(Id, val.region.Id)}
                                            region={region}
                                            validate={val => val.region !== null && val.region.Id !== null}>
                                        <EditorField valuekey="region" className={editorClasses}
                                                     component={RegionFieldWrapper}/>
                                        <span className="ml-neg-2 mr-2">, {region.land.name}</span>
                                        <EditorControls/>
                                    </Editor>}
                            </TD>
                            {/* TODO Flagge */}
                        </tr>
                        <tr className="h-6">
                            <Th>Trainer</Th>
                            <TD colSpan="3">
                                <TrainerListField
                                    trainer={this.state.termin.trainer.map(t => t.Id)}
                                    addTrainer={id => {
                                        const message = new Kurstermin_Trainer_soll_zugeordnet_werden(this.state.termin.Id, id);
                                        return this.send(message, "trainer").then(() => {
                                            this.props.data.refetch()
                                        });
                                    }}
                                    removeTrainer={id => {
                                        const message = new Kurstermin_Trainer_soll_entfernt_werden(this.state.termin.Id, id);
                                        return this.send(message, "trainer").then(() => {
                                            this.props.data.refetch()
                                        });
                                    }}
                                />
                            </TD>
                        </tr>
                        </tbody>
                    </table>
                    <div className="w-full">
                        <Label name="freitext" title="Freitext"/> (<a
                        className="text-dst-logo-gruen-hinten hover:text-dst-logo-gruen-mitte" rel="noopener noreferrer"
                        href="https://texwelt.de/wissen/markdown_help/#heading" target="_blank">Markdown</a> wird
                        unterstützt)<br/>
                        <InlineEditor component={MultilineTextField} value={freitext} refetch={refetch}
                                      className={classes.concat("w-full").reduce((prev, cur) => prev + " " + cur, "").trim()}
                                      save={val => val.trim() === "" ?
                                          new Kurstermin_Freitext_soll_entfernt_werden(Id, "DE") :
                                          new Kurstermin_Freitext_soll_gesetzt_werden(Id, "DE", val.trim())}/>
                    </div>
                    <div className="w-full bg-dst-hintergrund-hellblau">
                        <Label name="freitextEn" title="Freitext (Englisch)"/> (<a
                        className="text-dst-logo-gruen-hinten hover:text-dst-logo-gruen-mitte" rel="noopener noreferrer"
                        href="https://texwelt.de/wissen/markdown_help/#heading" target="_blank">Markdown</a> wird
                        unterstützt)<br/>
                        <InlineEditor component={MultilineTextField} value={freitextEn} refetch={refetch}
                                      className={classes.concat("w-full").reduce((prev, cur) => prev + " " + cur, "").trim()}
                                      save={val => val.trim() === "" ?
                                          new Kurstermin_Freitext_soll_entfernt_werden(Id, "EN") :
                                          new Kurstermin_Freitext_soll_gesetzt_werden(Id, "EN", val.trim())}/>
                    </div>
                    <ul className="termin-status-actions list-reset flex">
                        {this.state.termin.status.text === "Abgeschlossen" || <li className="h-8 ml-auto">
                            <div className="relative mb-24">
                                <button className="btn btn-gruen w-64"
                                        onClick={() => this.setState({showStatusMenu: !this.state.showStatusMenu})}>
                                    <span>Status-Aktionen</span>

                                    <svg className="h-4 absolute pin-r pr-4" version="1.1"
                                         xmlns="http://www.w3.org/2000/svg"
                                         viewBox="0 0 129 129" xmlnsXlink="http://www.w3.org/1999/xlink"
                                         enableBackground="new 0 0 129 129">
                                        <g>
                                            <path className="fill-white"
                                                  d="m121.3,34.6c-1.6-1.6-4.2-1.6-5.8,0l-51,51.1-51.1-51.1c-1.6-1.6-4.2-1.6-5.8,0-1.6,1.6-1.6,4.2 0,5.8l53.9,53.9c0.8,0.8 1.8,1.2 2.9,1.2 1,0 2.1-0.4 2.9-1.2l53.9-53.9c1.7-1.6 1.7-4.2 0.1-5.8z"/>
                                        </g>
                                    </svg>
                                </button>
                                {this.statusMenu()}
                            </div>
                        </li>}
                    </ul>
                    {this.state.showBuchungen ?
                        <Buchungsliste kurs={this.state.termin.Id}
                                       url={"/kurstermine/" + this.state.termin.Id + "/buchungen"}/>
                        :
                        <button className="btn btn-gruen w-64 block mt-2 mb-2"
                                onClick={() => this.setState({showBuchungen: true})}>Buchungen anzeigen</button>
                    }
                    {this.state.showTeilnehmer ?
                        <Teilnehmerliste kursId={this.state.termin.Id} kurs={this.state.termin}
                                         url={"/kurstermine/" + this.state.termin.Id + "/buchungen"}/>
                        :
                        <button className="btn btn-gruen w-64 block mt-2 mb-2"
                                onClick={() => this.setState({showTeilnehmer: true})}>Teilnehmer anzeigen</button>
                    }
                    <BemerkungListe bemerkungen={this.state.termin.bemerkungen} hasEditor={true}
                                    save={value => new Kurstermin_Bemerkung_soll_erstellt_werden(this.state.termin.Id, value)}
                                    refetch={this.props.data.refetch}/>

                </div>
            );
        } else {
            return <div>
                <Loading/>
            </div>
        }
    }

    statusMenu() {
        if (!this.state.showStatusMenu) {
            return
        }
        let transitions = this.state.termin.status.transitions
        let abgesagt = false
        let streichen = false
        transitions.forEach(i => {
            if (i.text === 'Abgesagt') {
                abgesagt = true
            }
            if (i.text === 'Gestrichen') {
                streichen = true
            }
        })
        if (this.state.termin.buchungen.count > 0) {
            streichen = false
        }
        transitions = transitions.filter(i => i.text !== 'Abgesagt' && i.text !== 'Gestrichen')
        return <div className="rounded shadow-md absolute mt-6 pin-t pin-l min-w-full bg-white">
            <ul className="list-reset">
                {transitions.map(this.statusButton)}
                {abgesagt &&
                <li>
                    <hr className="border-t mx-2 border-grey-light"/>
                </li>}
                {abgesagt && this.statusButton({text: "Abgesagt"})}
                {streichen && this.statusButton({text: "Gestrichen"})}
            </ul>
        </div>
    }

    statusButton(item) {
        let text = item.text
        return <li>
            <button
                className={"px-4 py-2 block hover:bg-grey-light w-full text-left " + ((text === "Abgesagt" || text === "Gestrichen") ? "text-dst-stift-orange hover:text-orange-dark" : "text-dst-logo-gruen-mitte hover:text-dst-logo-gruen-hinten")}
                name={text}
                onClick={(event) => this.handleStateClick(event)}>Status „{text}“ setzen
            </button>
        </li>
    }
}

const TD = (props) =>
    <td {...props} className={(props.className || "") + " w-1/4 h-6 border border-white leading-normal align-top"}/>

const Th = (props) =>
    <th className="text-left align-top leading-normal">{props.children}</th>

const StatusInfo = ({inListe, oeffentlich, url}) => <div className="mb-2">
    <Info state={!!oeffentlich} negator="nicht" text="verlinkbar"/>
    <Info state={!!inListe} negator="nicht" text="online sichtbar"/>
    {url && <a href={url} target="_blank"
               className="text-dst-logo-gruen-hinten hover:text-dst-logo-gruen-mitte">Zum Kurs auf der Website</a>}
</div>

const Info = ({state, negator, text}) => <span className="mr-2">
    {state ? "✅ " : "❌ " + negator} {text}
</span>

const query = gql`query kurstermin($id: String){kurstermine(Id: $id){
    titel,
    titelEn,
    beginn{
        humanReadable,
        isoString},
    dauer,
    preis{
        wert,
        waehrung: waehrungTyp{
            code,
            name
        }
    },
    fruehbucherPreis{
        preis{
            wert,
            waehrung: waehrungTyp{
                code,
                name
            }
        },
        gueltigBis{
            isoString,
            humanReadable
        },
        gueltig_bis_Tage_vor_dem_Kurs
    },
    smallInvoiceProduktId,
    smallInvoiceProduktName,
    typ{
        Id,
        name
    },
    status{
        text,
        transitions{text}
    },
    teilnehmerKreis,
    wenigeRestplaetzeAb,
    maxTeilnahmeZahl,
    location{
        Id,
        name,
        vollstaendigeAdresse,
        googleMapsUrl
    },
    region{
        Id,
        name,
        land{
            iso,
            name
        }
    },
    sprache: spracheTyp {
        name,
        code
    },
    kursCode,
    trainer{
        Id,
        nachname,
        vornamen
    },
    freitext,
    freitextEn,
    kurstage{
        beginn,
        ende,
        offset,
        Id
    },
    kontaktstunden,
    bemerkungen{
        Id,
        text,
        autor{name},
        erstellt{
            isoString,
            humanReadable
        }
    },
    buchungen { count },
    inListe,
    oeffentlich,
    drupalUrl,
    Id
}}`
export default graphql(query, {
    options: ownProps => {
        return {
            fetchPolicy: "network-only",
            variables: {
                id: ownProps.match.params.id,
            },
            // pollInterval: 500,
        }
    },
})(KursterminDetails);