/*gen*/class SmallInvoice_Rechnung_soll_angelegt_werden {
/*gen*/    static messageName = "SmallInvoice_Rechnung_soll_angelegt_werden"
/*gen*/	kontakt_Id
/*gen*/	kontakt_Adresse_Id
/*gen*/	kontakt_Person_Id
/*gen*/	introduction_Text
/*gen*/	conditions_Text
/*gen*/	artikelnummer
/*gen*/	artikelname
/*gen*/	artikelbeschreibung
/*gen*/	buchung_Id
/*gen*/	constructor(kontakt_Id, kontakt_Adresse_Id, kontakt_Person_Id, introduction_Text, conditions_Text, artikelnummer, artikelname, artikelbeschreibung, buchung_Id) {
/*gen*/		
/*gen*/			
/*gen*/			this.kontakt_Id = kontakt_Id
/*gen*/		
/*gen*/			
/*gen*/			this.kontakt_Adresse_Id = kontakt_Adresse_Id
/*gen*/		
/*gen*/			
/*gen*/			this.kontakt_Person_Id = kontakt_Person_Id
/*gen*/		
/*gen*/			
/*gen*/			this.introduction_Text = introduction_Text
/*gen*/		
/*gen*/			
/*gen*/			this.conditions_Text = conditions_Text
/*gen*/		
/*gen*/			
/*gen*/			this.artikelnummer = artikelnummer
/*gen*/		
/*gen*/			
/*gen*/			this.artikelname = artikelname
/*gen*/		
/*gen*/			
/*gen*/			this.artikelbeschreibung = artikelbeschreibung
/*gen*/		
/*gen*/			
/*gen*/			this.buchung_Id = buchung_Id
/*gen*/		
/*gen*/	}
/*gen*/}
/*gen*/class SmallInvoice_Kontakt_soll_angelegt_werden {
/*gen*/    static messageName = "SmallInvoice_Kontakt_soll_angelegt_werden"
/*gen*/	firma
/*gen*/	abteilung
/*gen*/	kontaktvorname
/*gen*/	kontaktnachname
/*gen*/	email
/*gen*/	strasse
/*gen*/	hausnr
/*gen*/	pLZ
/*gen*/	ort
/*gen*/	land
/*gen*/	ustID
/*gen*/	constructor(firma, abteilung, kontaktvorname, kontaktnachname, email, strasse, hausnr, pLZ, ort, land, ustID) {
/*gen*/		
/*gen*/			
/*gen*/			this.firma = firma
/*gen*/		
/*gen*/			
/*gen*/			this.abteilung = abteilung
/*gen*/		
/*gen*/			
/*gen*/			this.kontaktvorname = kontaktvorname
/*gen*/		
/*gen*/			
/*gen*/			this.kontaktnachname = kontaktnachname
/*gen*/		
/*gen*/			
/*gen*/			this.email = email
/*gen*/		
/*gen*/			
/*gen*/			this.strasse = strasse
/*gen*/		
/*gen*/			
/*gen*/			this.hausnr = hausnr
/*gen*/		
/*gen*/			
/*gen*/			this.pLZ = pLZ
/*gen*/		
/*gen*/			
/*gen*/			this.ort = ort
/*gen*/		
/*gen*/			
/*gen*/			this.land = land
/*gen*/		
/*gen*/			
/*gen*/			this.ustID = ustID
/*gen*/		
/*gen*/	}
/*gen*/}
/*gen*/export {
/*gen*/    SmallInvoice_Rechnung_soll_angelegt_werden,
/*gen*/    SmallInvoice_Kontakt_soll_angelegt_werden,
/*gen*/}