import * as React from "react";
import {gql} from "apollo-boost";
import PropTypes from "prop-types"
import {InputField, Label, SubmitButton, TextareaField, Vier04} from "./components"
import {Redirect} from "react-router-dom"
import RegionField from "./RegionField"
import {
    Kursort_Adresse_soll_geaendert_werden, Kursort_Google_Maps_URL_soll_gesetzt_werden,
    Kursort_Name_soll_geaendert_werden,
    Kursort_soll_angelegt_werden,
} from "./Daten/FlugplanMessages.gen"


const mutation = gql`mutation kursortAnlegen($message: String!){Kursort_soll_angelegt_werden(message: $message, type: "Kursort_soll_angelegt_werden")}`;
const locationQuery = gql`query kursort($Id: String){kursorte(Id: $Id){Id,name,nameEn,vollstaendigeAdresse,region{Id,name,land{name}},googleMapsUrl}}`
const changeMutations = {
    "Kursort_Google_Maps_URL_soll_gesetzt_werden": gql`mutation Kursort_Google_Maps_URL_soll_gesetzt_werden($message: String!){Kursort_Google_Maps_URL_soll_gesetzt_werden(message: $message)}`,
    "Kursort_Name_soll_geaendert_werden": gql`mutation Kursort_Name_soll_geaendert_werden($message: String!){Kursort_Name_soll_geaendert_werden(message: $message)}`,
    "Kursort_Adresse_soll_geaendert_werden": gql`mutation Kursort_Adresse_soll_geaendert_werden($message: String!){Kursort_Adresse_soll_geaendert_werden(message: $message)}`,
}

class KursortAnlegen extends React.Component {
    static contextTypes = {client: PropTypes.any};
    initialState = {
        Id: null,
        name: "",
        nameEn: "",
        vollstaendigeAdresse: "",
        region: null,
        regionName: "",
        success: false,
        startState: null,
        change: false,
        vier04: false,
    }

    events = []
    eventResults = {}

    constructor(props, context) {
        super(props, context);

        this.state = {...this.initialState}

        this.componentWillReceiveProps(this.props)

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleRegionChange = this.handleRegionChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.match.params.id !== "neu") {
            let promise = this.context.client.query({
                query: locationQuery,
                variables: {Id: this.props.match.params.id},
                fetchPolicy: "network-only",
            })
            promise.then(this.promiseResolution.bind(this))
            promise.catch(function (error) {
                alert("couldn't query: " + error);
            })
        } else {
            // Die Warnung "Can't call setState on a component that is not yet mounted" ist hier kein Bug,
            // weil wir dann im contructor-Flow sind. Wenn das der Fall ist, haben wir den State schon so gesetzt
            // ==> es ist ok, dass setState eine no-op ist.
            this.setState({...this.initialState})
        }
    }

    promiseResolution(result) {
        const locations = result.data.kursorte

        if (locations.length === 0) {
            this.setState({vier04: true})
            return
        }
        const location = locations[0]
        let state = {
            change: true,
        }
        for (let key in location) {
            if (location.hasOwnProperty(key)) {
                if (key === "region") {
                    state["regionName"] = location[key]["name"]
                    state["region"] = location[key]["Id"]
                    state["land"] = location[key]["land"]["name"]
                }
                else if (location[key] == null) {
                    state[key] = ""
                } else {
                    state[key] = location[key]
                }
            }
        }
        state["startState"] = {...state, startState: undefined}
        state["change"] = true
        this.setState(state)
    }

    render() {
        if (this.state.vier04) {
            return <Vier04/>
        }
        return <div className="kursort-anlegen-container">
            <h2 className="heading">Kursort {this.state.change ? "ändern" : "anlegen"}</h2>
            <form onSubmit={this.handleSubmit} className="orteingabe">
                <InputField name="name" type="text" title="Name" value={this.state.name}
                            onChange={this.handleInputChange}/>
                {this.state.change &&
                <InputField name="nameEn" type="text" title="Name (Englisch)" value={this.state.nameEn}
                            onChange={this.handleInputChange}/>
                }
                <TextareaField name="vollstaendigeAdresse" title="Vollständige Adresse" placeholder="Straße Hausnummer&#10;PLZ Ort"
                               value={this.state.vollstaendigeAdresse} onChange={this.handleInputChange}/>
                <Label name="region" title="Region"/><br/>
                {!this.state.change ?
                    <RegionField onChange={this.handleRegionChange}/>
                    :
                    <p>{this.state.regionName}, {this.state.land}</p>
                }
                {this.state.change &&
                <InputField name="googleMapsUrl" type="url" title="Google Maps URL" value={this.state.googleMapsUrl}
                            onChange={this.handleInputChange} placeholder="https://google.de/maps/place/..."/>
                }
                <SubmitButton title={this.state.change ? "Ändern" : "Anlegen"}/>
            </form>
            {
                this.state.success && <Redirect to="/kursorte" push/>
            }
        </div>;
    }

    handleRegionChange(event) {
        this.setState({
            region: event.region,
            regionName: event.regionName,
        })
    }

    handleInputChange(event) {
        const target = event.target;
        let value = target.type === 'checkbox' ? target.checked : target.value;
        value = target.type === 'number' ? parseInt(value, 10) : value;
        const name = target.name;

        this.setState({
            [name]: value,
        });
    }

    async handleSubmit(event) {
        event.preventDefault();
        if (this.state.name === "" || this.state.vollstaendigeAdresse === "" || this.state.region === null
            || this.state.land === "") {
            alert("Bitte Namen, vollständige Rechnungsadresse, Region und Land angeben.");
            return;
        }
        if (this.state.change) {
            // der Kursort soll geändert werden, wir müssen die entsprechenden Events generieren
            let events = []
            if (this.state.name !== this.state.startState.name) {
                // wir brauchen ein Name-Geändert-Event
                const name = new Kursort_Name_soll_geaendert_werden(
                    this.state.Id,
                    "DE",
                    this.state.name,
                )
                events.push(name)
            }
            if (this.state.nameEn !== this.state.startState.nameEn) {
                // wir brauchen ein Name-Geändert-Event
                const name = new Kursort_Name_soll_geaendert_werden(
                    this.state.Id,
                    "EN",
                    this.state.nameEn,
                )
                events.push(name)
            }
            if (this.state.vollstaendigeAdresse !== this.state.startState.vollstaendigeAdresse) {
                const ort = new Kursort_Adresse_soll_geaendert_werden(
                    this.state.Id,
                    this.state.vollstaendigeAdresse,
                )
                events.push(ort)
            }
            if (this.state.googleMapsUrl !== this.state.startState.googleMapsUrl
                && this.state.googleMapsUrl !== "" && this.state.googleMapsUrl !== null) {
                const gMapsUrl = new Kursort_Google_Maps_URL_soll_gesetzt_werden(
                    this.state.Id,
                    this.state.googleMapsUrl,
                )
                events.push(gMapsUrl)
            }
            if (events.length === 0) {
                this.setState({success: true})
                return
            }
            this.eventResults = {}
            this.events = events
            this.sendChangeEvents()
        } else {
            const kursort = new Kursort_soll_angelegt_werden(
                this.state.name,
                this.state.vollstaendigeAdresse,
                this.state.region,
            );
            const message = JSON.stringify(kursort);
            try {
                let result = await this.context.client.mutate({
                    mutation,
                    variables: {message: message},
                    refetchQueries: () => [{query: gql`{kursorte{name,region,land{name},Id}}`}],
                })
                this.setState({
                    success: result.data.Kursort_soll_angelegt_werden === "ok",
                })
                if (!this.state.success) {
                    alert(result.data.Kursort_soll_angelegt_werden);
                }
            }
            catch (error) {
                alert("submitting failed: " + error);
            }
        }
    }

    async sendChangeEvents() {
        for (let evId in this.events) {
            let event = this.events[evId]
            let mut = changeMutations[event.constructor.messageName]
            try {
                let result = await this.context.client.mutate({
                    mutation: mut,
                    variables: {message: JSON.stringify(event)},
                    refetchQueries: () => [{query: locationQuery}, {query: gql`{kursorte{name,region,land{name},Id}}`}],
                })
                let eventResults = this.eventResults
                eventResults[event.constructor.messageName] = result.data[event.constructor.messageName] === "ok"
                this.eventResults = eventResults
                this.checkEventResults()
            }
            catch (error) {
                let eventResults = this.eventResults
                eventResults[event.constructor.name] = false
                this.eventResults = eventResults
                alert("submitting failed: " + error)
                this.checkEventResults()
            }
        }
    }

    checkEventResults() {
        if (Object.keys(this.eventResults).length === this.events.length) {
            let success = true
            for (let evRId in this.eventResults) {
                if (this.eventResults.hasOwnProperty(evRId)) {
                    let eventResult = this.eventResults[evRId]
                    if (eventResult === false) {
                        success = false
                    }
                }
            }
            this.setState({success: success})
            if (!this.state.success) {
                alert("Konnte nicht ändern")
            }
        }
    }
}

export default KursortAnlegen;