import * as React from "react"
import {Link} from "react-router-dom";
import {graphql} from "react-apollo/index"
import {gql} from "apollo-boost"
import PropTypes from "prop-types"
import {Badge} from "./UI/Badge";

class Navigation extends React.Component {
    static contextTypes = {
        router: PropTypes.shape({
            history: PropTypes.shape({
                push: PropTypes.func.isRequired,
                replace: PropTypes.func.isRequired,
            }).isRequired,
            staticContext: PropTypes.object,
        }).isRequired,
    };

    state = {
        currentLogVersion: {
            uuid: localStorage.getItem("currentLogVersionUUID"),
            version: localStorage.getItem("currentLogVersionVersion") ? parseInt(localStorage.getItem("currentLogVersionVersion"), 10) : 0,
        },
        logBadgeNumber: localStorage.getItem("currentLogVersionLogBadgeNumber") ? parseInt(localStorage.getItem("currentLogVersionLogBadgeNumber"), 10) : 0,
    }

    constructor(a, b) {
        super(a, b)

        this.handleClick = this.handleClick.bind(this)
    }

    componentDidMount() {
        this.props.data.subscribeToMore({
            document: gql`
                subscription logger {
                    logger
                }
            `,
            updateQuery: () => {
                this.props.data.refetch()
            },
        })
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.data.logger && nextProps.data.logger.significantVersion) {
            const version = nextProps.data.logger.significantVersion
            let newState = {
                currentLogVersion: this.state.currentLogVersion,
            }
            if (version.uuid !== this.state.currentLogVersion.uuid) {
                newState.currentLogVersion.uuid = version.uuid
                newState.currentLogVersion.version = version.version
                newState.logBadgeNumber = version.version
                localStorage.setItem("currentLogVersionUUID", version.uuid)
                localStorage.setItem("currentLogVersionVersion", version.version)
                localStorage.setItem("currentLogVersionLogBadgeNumber", newState.logBadgeNumber)
            } else if (version.version !== this.state.currentLogVersion.version) {
                newState.logBadgeNumber = this.state.logBadgeNumber + (version.version - this.state.currentLogVersion.version)
                newState.currentLogVersion.version = version.version
                localStorage.setItem("currentLogVersionVersion", version.version)
                localStorage.setItem("currentLogVersionLogBadgeNumber", newState.logBadgeNumber)
            }
            this.setState(newState)
        }
    }

    handleClick() {
        this.setState({logBadgeNumber: 0})
        localStorage.setItem("currentLogVersionLogBadgeNumber", 0)
        this.context.router.history.push("/log")
    }

    static handleLogoutClick() {
        localStorage.removeItem('Kute::LoginToken')
        window.location.reload(true)
    }

    render() {
        const may = this.props.data.berechtigungen &&
            (this.props.data.berechtigungen.includes('alle_Rechte') || this.props.data.berechtigungen.includes('administration'))
        return (
            <ul className="flex justify-around bg-dst-hintergrund-hellgruen border-t-4 border-b-4 border-dst-logo-gruen-mitte mb-4 pl-0">
                <Navlink to="/kurstermine" title="Kurstermine"/>
                <Navlink to="/kontakte" title="Kontakte"/>
                <Navlink to="/trainer" title="Trainer"/>
                <Navlink to="/kursorte" title="Kursorte"/>
                <Navlink to="/regionen" title="Regionen"/>
                <Navlink to="/preisstaffeln" title="Preisstaffeln"/>
                {may && <li className="list-reset">
                    <button onClick={this.handleClick}
                            className="text-dst-logo-gruen-hinten hover:text-dst-logo-gruen-mitte underline">Logs{this.state.logBadgeNumber > 0 &&
                    <Badge>{this.state.logBadgeNumber}</Badge>}</button>
                </li>}
                {may && <Navlink to="/admin" title="Eventlog"/>}
                <LogoutButton/>
            </ul>)
    }
}

const LogoutItem = (props) => <li className="list-reset">
    <button onClick={() => Navigation.handleLogoutClick()}
            className="text-dst-logo-gruen-hinten hover:text-dst-logo-gruen-mitte underline">
        {props.data.loginUser} abmelden
    </button>
</li>

const LogoutButton = graphql(gql`{ loginUser }`, {
    options: {
        fetchPolicy: "network-only",
    },
})(LogoutItem)


const Navlink = (props) => <li className="list-reset"><Link
    className="text-dst-logo-gruen-hinten hover:text-dst-logo-gruen-mitte"
    to={props.to}>{props.title}</Link></li>

export default graphql(gql`query logger {berechtigungen, logger {significantVersion{uuid, version}}}`, {
    options: {
        fetchPolicy: "network-only",
    },
})(Navigation)