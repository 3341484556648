import PropTypes from "prop-types";
import * as React from "react";

export class MultilineTextField extends React.Component {

    static propTypes = {
        value: PropTypes.string,
        placeholder: PropTypes.string,
        enabled: PropTypes.bool,
        save: PropTypes.func,
        revert: PropTypes.func,
        onChange: PropTypes.func,
        className: PropTypes.string,
        highlight: PropTypes.string,
        tabIndex: PropTypes.number,
        type: PropTypes.string,
    };

    keyup(e) {
        if (e.key === "Enter" && e.shiftKey) {
            this.props.save();
            e.preventDefault();
            e.target.blur();
        }
        if (e.key === "Escape") {
            this.props.revert();
            e.preventDefault();
        }
    }

    static highlight(reason) {
        if (reason === "updated") {
            return "bg-dst-logo-gruen-vorne";
        }
        if (reason === "error") {
            return "bg-red-lighter";
        }
        return "";
    }

    render() {
        let mr = "mr-2 "
        if (this.props.className.includes("mx-") || this.props.className.includes("mr-")) {
            mr = ""
        }
        return <textarea
            className={mr + this.props.className + " " + (MultilineTextField.highlight(this.props.highlight))}
            readOnly={!this.props.enabled}
            value={this.props.value || ""}
            placeholder={this.props.placeholder}
            onChange={e => this.props.onChange(e.target.value)}
            onKeyUp={(e) => this.keyup(e)}
            tabIndex={this.props.tabIndex}
        />
    }
}