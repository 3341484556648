import * as React from "react"
import gql from "graphql-tag";
import PropTypes from "prop-types"
import {toCSV} from "react-csv/lib/core"
import confirmation from "./UI/Confirm"

const query = gql`{
    buchungen {
        Id
        bestellNummer
        bucher {
            anrede {
                text
            }
            titel
            vorname
            nachname
            email
            telefon
            firma
        }
        bucherRechnungsAdresse {
            vorname
            nachname
            abteilung
            strasse
            plz
            ort
        }
        kurstermin {
            Id
            kursCode
            typ {
                name
                kuerzel
            }
        }
        preis {
            wert
            waehrungTyp {
                code
            }
        }
        gutscheinCode
        rechnungNummer
        ustId
        rechnungPerPost
        storniert
        teilnehmerzahl
        zeitpunkt {
            isoString
        }
    }
}`

export class BuchungExporter extends React.Component {
    static contextTypes = {client: PropTypes.any};

    state = {
        buttonEnabled: true,
    }

    async createCsvData() {
        try {
            await confirmation("Wirklich exportieren?", {
                description: "Sollen wirklich alle Buchungen exportiert werden? Dieser Export " +
                    "verursacht eine hohe Last auf dem Server und sollte daher nicht zu häufig und idealerweise nicht " +
                    "zur Peak-Zeit ausgeführt werden. Alternativ können auch in der Kurstermin-Detailansicht die " +
                    "Buchungen des jeweiligen Kurses exportiert werden.",
                confirmLabel: "Alle Buchungen exportieren",
                abortLabel: "Abbrechen",
            })
        } catch (error) {
            // noinspection UnnecessaryReturnStatementJS
            return;
        }

        this.setState({buttonEnabled: false})
        let buchungen = []
        try {
            let result = await this.context.client.query({query})
            if (result.data.buchungen) {
                buchungen = result.data.buchungen
            } else {
                alert("Konnte die Buchungsdaten nicht laden.")
                this.setState({buttonEnabled: true})
                return
            }
        } catch (error) {
            console.error(error)
            alert("Konnte die Buchungsdaten nicht laden.")
            this.setState({buttonEnabled: true})
            return
        }
        let objs = []
        let ids = []
        for (let buchung of buchungen) {
            if (ids.includes(buchung.Id)) {
                continue // Duplikatvermeidung
            }
            let {bucher, bucherRechnungsAdresse, kurstermin, preis} = buchung
            let anrede = ""
            if (bucher.anrede) {
                anrede = bucher.anrede.text
            }
            let obj = {
                Id: buchung.Id,
                "Bucher-Anrede": anrede,
                "Bucher-Titel": bucher.titel || "",
                "Bucher-Vorname": bucher.vorname,
                "Bucher-Nachname": bucher.nachname,
                "Bucher-Email": bucher.email,
                "Bucher-Telefon": bucher.telefon || "",
                "Firma": bucher.firma,
                "Rechnungsadresse-Vorname": bucherRechnungsAdresse.vorname || "",
                "Rechnungsadresse-Nachname": bucherRechnungsAdresse.nachname || "",
                "Rechnungsadresse-Abteilung": bucherRechnungsAdresse.abteilung || "",
                "Rechnungsadresse-Strasse": bucherRechnungsAdresse.strasse || "",
                "Rechnungsadresse-PLZ": bucherRechnungsAdresse.plz || "",
                "Rechnungsadresse-Ort": bucherRechnungsAdresse.ort || "",
                "Kurstermin-ID": kurstermin.Id,
                "Kurscode": kurstermin.kursCode,
                "Kurstyp": kurstermin.typ.name,
                "Kurs-Kürzel": kurstermin.typ.kuerzel,
                "Preis": preis ? preis.wert : "",
                "Währung": preis ? preis.waehrungTyp.code : "",
                "Rechnungsnummer": buchung.rechnungNummer,
                "Gutscheincode": buchung.gutscheinCode || "",
                "UstID": buchung.ustId,
                "Rechnungszustellung": !!buchung.rechnungPerPost ? "Post" : "Email",
                "storniert": !!buchung.storniert ? "storniert" : "",
                "Teilnehmerzahl": buchung.teilnehmerzahl,
                "Buchungszeitpunkt": buchung.zeitpunkt.isoString,
            }
            objs.push(obj)
            ids.push(buchung.Id)
        }
        let csv = toCSV(objs, null, ";", "\"")
        let element = document.createElement("a")
        const file = new Blob([csv], {type: 'text/csv'})
        element.href = URL.createObjectURL(file);
        element.download = "alle-buchungen.csv";
        element.click();
        this.setState({buttonEnabled: true})
    }

    render() {
        return <button
            className="ml-2 btn btn-gruen no-underline"
            onClick={() => this.createCsvData()} disabled={!this.state.buttonEnabled}>
            Alle Buchungen herunterladen
        </button>
    }
}